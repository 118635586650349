import socketChannelEcosystemService from './channels/socket.channel.ecosystem'
import socketChannelLogService from './channels/socket.channel.log'

const module = angular.module('ac.components.socketComm', [])

module
  .provider('socketChannelEcosystemService', socketChannelEcosystemService)
  .provider('socketChannelLogService', socketChannelLogService)

export default module.name
