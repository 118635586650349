import { includes, filter, chain, mapKeys, get, map, each, mapValues, keyBy, flatten } from 'lodash'
// todo: use lodash/fp

export const filterPim2An = (pim2an, productIdsToKeep, articleIdsToKeep) =>
  chain(pim2an)
    .pickBy((ans, pim) => includes(productIdsToKeep, pim))
    .mapValues(items => filter(items, an => includes(articleIdsToKeep, '' + an)))
    .value()

export const filterProducts = (products, productIdsToKeep) =>
  mapKeys(filter(products, (product, key) => includes(productIdsToKeep, key)), 'id')

export const preFilterCombinations = (products, articleIdsToKeep) => {
  return chain(products)
    .map((combination, an) => ({ ...combination, an }))
    .filter((product, key) => includes(articleIdsToKeep, '' + product.an))
    .mapKeys('an')
    .value()
}

export const mapIg2An = (multi, pim2an, pimIds, prefix = 'ig') => {
  let it = 1
  let ig2an = {}
  const itemGroupIdPrefix = `${prefix}_`
  each(pim2an, (anIds, pim) => {
    if (pimIds.includes(pim)) {
      if (multi) {
        const groupId = itemGroupIdPrefix + '' + it++
        ig2an[groupId] = {
          pim,
          articles: anIds,
          single: false
        }
      } else {
        each(anIds, an => {
          const groupId = itemGroupIdPrefix + '' + it++
          ig2an[groupId] = {
            pim: pim,
            articles: [an],
            single: true
          }
        })
      }
    }
  })

  return ig2an
}

export const filterFilters = (rawFilters, productIdsToKeep) =>
  mapValues(rawFilters, filtr => {
    let filters
    if (Array.isArray(filtr)) {
      filters = filter(filtr, f => productIdsToKeep.includes(f))
    } else {
      filters = filterFilters(filtr, productIdsToKeep)
    }
    return filters
  })

export const getProductGroupCategories = config => {
  return flatten(map(config, type => map(type.pim_categories, item => item.category_id)))
}
export const getProductsByCategoryIds = (products, categoryIds) =>
  keyBy(filter(products, product => categoryIds.includes(product.category_id)), 'id')

export const extendCombinationsWithCustomerData = (customerData, products, an2pim) =>
  keyBy(
    map(customerData, (customerProduct, an) => ({
      ...customerProduct,
      pim: an2pim[an]
    })),
    'an'
  )
export const getRawProducts = raw => get(raw, 'pim_products', [])

export const getProductGroupHierarchyConfig = (config, groupId) => {
  return {
    primary: filter(config.primary_product_groups, type => type.id === groupId),
    secondary: filter(config.secondary_product_groups, type => type.primary_product_groups.includes(groupId))
  }
}
