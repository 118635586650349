export default {
  DEFAULT_VOL: 64,
  VOLUME_MIN: 0,
  VOLUME_MAX: 128,
  PLAYER_STATES: {
    stopped: 'stopped',
    playing: 'playing',
    paused: 'paused'
  },
  DEFAULT_PLAYER_STATE: 'stopped',
  PLAYER_SOURCES: {
    lineIn: 'line-in',
    file: 'file'
  },
  DEFAULT_SOURCE: 'file'
}
