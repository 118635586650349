import controller from './details-specs.controller'
import horizontalTemplate from './templates/details-specs.horizontal.html'
import verticalTemplate from './templates/details-specs.vertical.html'

/* @ngInject */
const template = displayService =>
  displayService.getTemplate({
    component: 'detailsSpecs',
    horizontal: horizontalTemplate,
    vertical: verticalTemplate
  })

export default {
  bindings: {
    productDetails: '<',
    itemGroup: '<'
  },
  template,
  controller
}
