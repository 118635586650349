/* @ngInject */
export default function categorySelectorConfig($stateProvider, $stateParamsProvider, MPA_PAGES) {
  $stateProvider.state(MPA_PAGES.DETAILS_ACCESSORIES_CATEGORY_SELECTOR.name, {
    url: MPA_PAGES.DETAILS_ACCESSORIES_CATEGORY_SELECTOR.url,
    // custom props
    component: 'categorySelector',
    resolve: {
      /* @ngInject */
      breadcrumb: breadcrumbService => breadcrumbService.set('accessories', {})
    }
  })
}
