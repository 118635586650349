import template from './product-specification-table.html'
import controller from './product-specification-table.controller'

export default {
  bindings: {
    productDetails: '<'
  },
  controller,
  template
}
