/* @ngInject */
export default function swiperItemController(displayService, $rootScope, pinnedList, ConfigProvider, EVENT_KEYS) {
  const ctrl = this
  let eventListeners = []
  const maxPinnedItems = displayService.isHorizontal()
    ? ConfigProvider.SETTINGS.MAX_PINNED_ITEMS_TO_COMPARE_HORIZONTAL
    : ConfigProvider.SETTINGS.MAX_PINNED_ITEMS_TO_COMPARE_VERTICAL

  ctrl.$onInit = () => {
    eventListeners = eventListeners.concat([
      $rootScope.$on(EVENT_KEYS.LOGIC.PINNED_LIST_CHANGED, () => {
        ctrl.pinned = pinnedList.isIdPinned(ctrl.item)
      })
    ])
  }

  ctrl.$onDestroy = () => {
    eventListeners.map(listener => listener())
    eventListeners = []
  }

  const triggerItemSelect = item => {
    ctrl.onItemSelect({ item })
  }

  const togglePin = item => {
    ctrl.pinned = !ctrl.pinned
    ctrl.onItemPin({ item })
  }

  const showStar = () => ctrl.pinned || pinnedList.getPinnedIds().length < maxPinnedItems

  ctrl.controlFunctions = {
    togglePin,
    showStar,
    triggerItemSelect
  }
}
