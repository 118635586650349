/* @ngInject */
export default function mediaPlayerController($sce) {
  const ctrl = this
  ctrl.state = {}

  ctrl.$onInit = () => {
    if (ctrl.videoUrl) {
      ctrl.state.sanitizedUrl = $sce.trustAsResourceUrl(ctrl.videoUrl)
    } else {
      console.warn('mediaPlayer: no videoUrl provided')
    }
  }

  ctrl.$onDestroy = () => {}
}
