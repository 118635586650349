import component from './selector.component'
import config from './selector.config'
import itemGroupSelector from './components/item-group-selector-component/item-group-selector.component'
import swiperItemComponent from './components/swiper-item/swiper-item.component'
import selectorService from './selector.service'

const module = angular.module('mpa.pages.selector', [])

module
  .config(config)
  .component('selectorPageComponent', component)
  .component('itemGroupSelector', itemGroupSelector)
  .component('swiperItem', swiperItemComponent)
  .factory('selectorService', selectorService)

export default module.name
