/* @ngInject */
export default function InputHelper() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: link
  }

  function link(scope, element, attrs, ngModel) {
    let inputValue

    element.keypress(function() {
      // the input value at this point wont present in the $viewValue, but can be accessed with .val()
      // ensure the maxlength prop
      inputValue = element.val().substr(0, attrs.maxlength)
      // force the inputValue into the viewValue and render
      ngModel.$setViewValue(inputValue)
      ngModel.$render()
    })
  }
}
