import moment from 'moment'

/* @ngInject */
export default function localeDate($translate) {
  return function(value) {
    let currentLanguage = $translate.use()
    let localeDate = moment(value).locale(currentLanguage)
    return localeDate.calendar()
  }
}
