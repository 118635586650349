import { PUBLISHER_CHANNELS } from '../../../components/socket-comm/socket.comm.config'

/* @ngInject */
export default function ledService($rootScope, EVENT_KEYS, socketChannelEcosystemService) {
  $rootScope.$on(EVENT_KEYS.LOGIC.TURN_ON_LED_BY_ARTICLE_IDS, turnOnByArticleIdsChangedHandler)
  $rootScope.$on(EVENT_KEYS.LOGIC.TURN_OFF_LEDS, resetHandler)
  $rootScope.$on(EVENT_KEYS.LOGIC.GLOBAL_RESET, resetHandler)

  function resetHandler() {
    resetLeds()
  }

  function turnOnByArticleIdsChangedHandler(event, articleIds) {
    sendLedSetCommand(articleIds)
  }

  function resetLeds() {
    sendLedSetCommand([])
  }

  function sendLedSetCommand(ids = []) {
    console.groupCollapsed('%c ledService :: sendLedSetCommand ', 'background: #000; color: orange;')

    const data = {
      channel: PUBLISHER_CHANNELS.LED_SET,
      message: { ids }
    }
    console.debug(`ledService :: sendLedSetCommand: [${ids}]`)
    console.log(data)
    console.groupEnd()

    socketChannelEcosystemService.emit('publish', data)
  }

  return {}
}
