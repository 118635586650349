import qrcode from 'qrcode-generator/qrcode'
qrcode.stringToBytes = qrcode.stringToBytesFuncs['UTF-8']

/* @ngInject */
export default function controller() {
  let ctrl = this

  const config = {
    typeNumber: 12, // 1 ~ 40
    errorCorrectionLevel: 'L' // 'L', 'M', 'Q', 'H'
  }

  ctrl.$onInit = () => {
    const qr = qrcode(config.typeNumber, config.errorCorrectionLevel)
    qr.addData(ctrl.text)
    qr.make()
    ctrl.qrImageTag = qr.createImgTag(4)
  }
}
