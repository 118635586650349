import { get } from 'lodash'
import { PUBLISHER_CHANNELS } from '../../../components/socket-comm/socket.comm.config'

/* @ngInject */
export default function eslService($rootScope, EVENT_KEYS, socketChannelEcosystemService, ConfigProvider) {
  const eslType = get(ConfigProvider, 'SETTINGS.ESL.type', 'findbox')
  const eslBlinkAttr = get(ConfigProvider, 'SETTINGS.ESL.item_attr_to_send', 'ean')
  const blinkPattern = get(ConfigProvider, 'SETTINGS.ESL.findbox.blinkPattern', [[2, 'red'], [2, 'red'], [2, 'red']])

  $rootScope.$on(EVENT_KEYS.LOGIC.ESL_BLINK, ($event, itemId) => {
    blinkByEan(itemId)
  })

  return {
    blinkByEan: blinkByEan
  }

  function blinkByEan(itemId) {
    let msg
    if (eslType === 'findbox') {
      msg = {
        type: 'findbox',
        data: {
          command: 'blink',
          blinkPattern,
          blinkId: itemId,
          blinkAttr: eslBlinkAttr
        }
      }
    } else {
      msg = {
        type: eslType,
        data: {
          blinkId: itemId,
          blinkAttr: eslBlinkAttr
        }
      }
    }

    if (typeof window.COD === 'object') {
      const eslData = msg.data

      if (!(window.codData && window.codData.findbox && window.codData.findbox.apiBaseUrl)) {
        console.warn(`No ESL apiBaseUrl defined`)
        return
      }

      const { apiBaseUrl, username, password } = window.codData.findbox

      if (eslType === 'findbox') {
        const { command, blinkId, blinkPattern } = eslData
        const path = `1.0.0/index.php/smartesl.${command}/2.0.0/`
        const url = `${apiBaseUrl}/${path}`

        const reqData = [blinkId.toString(), '', 1, 1, blinkPattern]

        // fake  blinkId for test
        // const fakeBlinkId = '8801643344078'
        // const reqData = [fakeBlinkId.toString(), '', 1, 1, blinkPattern]

        fetch(url, {
          method: 'POST',
          headers: {
            Authorization: 'Basic ' + btoa(`${username}:${password}`),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(reqData)
        })
          .then(res =>
            res.text().then(text => {
              if (res.status === 200) {
                console.info(`ESL command succeed; resp: %j`, text)
              } else {
                console.info(`ESL command failed; resp: %j`, text)
              }
            })
          )
          .catch(() => {
            console.warn(`ESL command failed`)
          })
      } else if (eslType === 'omni-ean') {
        const { blinkId } = eslData

        const baseUrl = apiBaseUrl || 'http://192.168.1.15:9001'
        // const path = 'labels/contents/led/article'
        // const url = `${baseUrl}/${path}`
        const url = baseUrl // as requested by Omnisint @2022-03-03

        console.info(`${eslType} url: ${url}`)

        const reqData = [{ articleId: blinkId.toString(), color: 'RED', duration: '10', stationCode: '0002' }]

        fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(reqData)
        })
          .then(res =>
            res.text().then(text => {
              if (res.status === 200) {
                console.info(`ESL command succeed; resp: %j`, text)
              } else {
                console.info(`ESL command failed; resp: %j`, text)
              }
            })
          )
          .catch(() => {
            console.warn(`ESL command failed`)
          })
      } else if (eslType === 'omni-an') {
        const { blinkId } = eslData

        const baseUrl = apiBaseUrl || 'http://192.168.1.15:9001'
        // const path = 'customer/blink'
        // const url = `${baseUrl}/${path}`
        const url = baseUrl // as requested by Omnisint @2022-03-03

        console.info(`${eslType} url: ${url}`)

        const reqData = { article: blinkId.toString() }

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(reqData)
        })
          .then(res =>
            res.text().then(text => {
              if (res.status === 200) {
                console.info(`ESL command succeed; resp: %j`, text)
              } else {
                console.info(`ESL command failed; resp: %j`, text)
              }
            })
          )
          .catch(() => {
            console.warn(`ESL command failed`)
          })
      }
    } else {
      socketChannelEcosystemService.emit('publish', {
        channel: PUBLISHER_CHANNELS.FINDBOX_SET,
        message: msg
      })
    }
    console.info('eslService :: Blink ESL with the following id: %s!', itemId)
  }
}
