import moment from 'moment'

/* @ngInject */
export default function CheckoutPageController($translate, combinationComparerService, $filter) {
  let ctrl = this
  ctrl.$onInit = initComponent

  // ~~~~~~~~~~~~~~
  // Private
  // ~~~~~~~~~~~~~~
  function getQrCodeText(combination) {
    const article = combination.article
    const tariff = combination.tariff
    const calculatedArticlePrice = combination.price

    let txt = ''
    // Product part
    if (article.product) {
      txt += article.product.manufacturer
      txt += '\r\n'
      txt += article.product.name
    }

    txt += '\r\n'
    txt += '(' + $translate.instant('TXT_QR_ARTICLE_NUMBER') + ': ' + article.getIdentifiers().an + ')'
    txt += '\r\n'
    txt += '(' + $translate.instant('TXT_QR_EAN') + ': ' + article.getIdentifiers().ean + ')'
    txt += '\r\n'
    txt += '\r\n'
    // product price, even it's discounted by tariff-relation or its own list price, this is already calculated by someone else....
    txt += $translate.instant('TXT_QR_PRODUCT_PRICE') + ': ' + $filter('price')(calculatedArticlePrice)

    // tariff
    if (tariff) {
      txt += '\r\n'
      txt += '\r\n'
      txt += tariff.operator
      txt += '\r\n'
      txt += tariff.getName()
      txt += '\r\n'
      txt += '(' + $translate.instant('TXT_QR_ARTICLE_NUMBER') + ': ' + tariff.id + ')'
      txt += '\r\n'
      txt += '\r\n'
      txt += $translate.instant('TXT_QR_MONTHLY_FEE') + ': ' + $filter('price')(tariff.monthly_fee)
    }

    txt += '\r\n'
    txt += '\r\n'
    txt += $translate.instant('TXT_QR_CONTACT_FOR_MORE_DETAILS')
    txt += '\r\n'
    txt += '\r\n'
    txt += moment().format() // todo from config
    return txt
  }

  function initComponent() {
    ctrl.state = {
      qrCodeText: null
    }

    if (ctrl.combination) {
      ctrl.state.qrCodeText = getQrCodeText(ctrl.combination)
    }
  }
}
