import ProductFilterFactory from './product-filter.factory'
import ProductFilterService from './product-filter.service'
import ProductFilterGroupFactory from './product-filter-group.factory'
import ProductOtfFilterFactory from './product-otf-filter.factory'

const module = angular.module('ac.repositories.productfilter', [])

module
  .factory('ProductFilterFactory', ProductFilterFactory)
  .service('ProductFilterService', ProductFilterService)
  .factory('ProductFilterGroupFactory', ProductFilterGroupFactory)
  .factory('ProductOtfFilterFactory', ProductOtfFilterFactory)

export default module.name
