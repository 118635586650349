import controller from './checkout.controller'
import template from './checkout.html'

export default {
  bindings: {
    itemGroup: '<',
    combination: '<'
  },
  template,
  controller
}
