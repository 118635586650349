/* @ngInject */
export default function DetailsTariffsPageController(
  $scope,
  combinationComparerService,
  EVENT_KEYS,
  businessLogService
) {
  let ctrl = this

  ctrl.$onInit = initialize

  const onCombinationSelection = ({ combination }) => {
    businessLogService.generate({
      event: businessLogService.events.COMBINATION_SELECTION,
      data: combination.getUsageInfo()
    })

    if (!combinationComparerService.isSelected(combination)) {
      combinationComparerService.add(combination)
    }

    const combinations = combinationComparerService.get()
    ctrl.data.combinations = combinations
  }

  ctrl.data = {
    itemGroup: null,
    defaultCombination: null,
    hasTariffs: false,
    combinations: combinationComparerService.get()
  }

  function initialize() {
    ctrl.data.hasTariffs = ctrl.itemGroup.getCombinations().length > 0 && !ctrl.itemGroup.getPrimerCombination()

    console.log('Details - Tariffs page', ctrl.data)
  }

  ctrl.controlFunctions = {
    onCombinationSelection
  }
}
