export default function accessorySelectorController($state, MPA_PAGES) {
  const ctrl = this

  ctrl.$onInit = () => {}

  const handleSelect = accessory =>
    $state.go(MPA_PAGES.DETAILS_ACCESSORIES_ACCESSORY_DETAILS.name, { accessoryId: accessory.id })
  ctrl.controlFunctions = {
    handleSelect
  }
}
