import { get } from 'lodash'
import noUiSlider from 'nouislider'

/* @ngInject */
export default function($rootScope, $scope, $timeout, EVENT_KEYS) {
  let ctrl = this
  let min
  let max
  let slider

  ctrl.$onInit = () => {
    min = get(ctrl, 'selector.fromRange') || 0
    max = get(ctrl, 'selector.toRange') || Number.MAX_SAFE_INTEGER
    console.log(ctrl.selector, 'min, max', min, max)
    slider = angular.element('.slider')[0]

    noUiSlider.create(slider, {
      start: [min, max],
      connect: true,
      step: Math.floor((max - min) / 10),
      range: {
        min,
        max
      }
    })

    slider.noUiSlider.on('slide', function() {
      update(false)
    })

    slider.noUiSlider.on('change', function() {
      update(true)
    })

    function update(updateSelectors) {
      let range = slider.noUiSlider.get()
      $timeout(function() {
        if (ctrl.selector) {
          ctrl.selector.from = parseFloat(range[0])
          ctrl.selector.to = parseFloat(range[1])
          if (updateSelectors) onValuesChanged()
        }
      })
    }

    function onValuesChanged() {
      if (ctrl.selector.from === min && ctrl.selector.to === max) {
        ctrl.selector.reset()
      } else {
        ctrl.selector.setActive()
      }
      $rootScope.$broadcast(EVENT_KEYS.LOGIC.ACTIVE_FILTER_LIST_CHANGED)
    }

    $scope.$on(EVENT_KEYS.LOGIC.FILTER_RESET, () => {
      $timeout(() => {
        slider.noUiSlider.set([ctrl.selector.from, ctrl.selector.to])
      })
    })
  }
}
