import { each, some, remove } from 'lodash'
/* @ngInject */
export default function productFilterDescriptionController($scope, $rootScope, ProductFilterService, EVENT_KEYS) {
  $scope.data = null

  initialize()

  function initialize() {
    reset()
    onFilterListChanged()

    $rootScope.$on(EVENT_KEYS.LOGIC.ACTIVE_FILTER_LIST_CHANGED, onFilterListChanged)
  }

  function reset() {
    $scope.data = {
      features: []
    }
  }

  function onFilterListChanged() {
    let actives = ProductFilterService.getActiveFeatures()

    // new item gotta be last so we gotta use push/remove
    each(actives, function(item) {
      if (!some($scope.data.features, item)) {
        $scope.data.features.push(item)
      }
    })

    each($scope.data.features, function(item) {
      if (!some(actives, item)) {
        remove($scope.data.features, item)
      }
    })
  }
}
