/*
  A Combination contains an Article, extending it with sell related information (like price).
  Currently there are two types of Combinations
  - primer: basic combination with an Article and a price (list price of the Article, can be null)
  - tariff: contains an Article, a Tariff and a price ()
*/

/* @ngInject */
export default function CombinationFactory(TariffListService, ConfigProvider) {
  let localConfig = {
    operatorImageExtension: '.png'
  }

  function Combination({ article, tariff, combiData, id, type }) {
    this.article = article
    this.id = id
    this.type = type

    if (combiData && tariff) {
      this.combi_price_suffix_text = combiData.combi_price_suffix_text
      this.combi_price_suffix_comment = combiData.combi_price_suffix_comment
      this.combi_price_divider = combiData.combi_price_devider // yep, this is a known typo
      this.price = combiData.price
      this.tariff = tariff
    } else {
      this.price = article.price
    }
  }

  Combination.prototype.getOperatorImageUrl = function() {
    let _img = ''
    if (this.operator) {
      _img =
        ConfigProvider.getFrontendSettingByKey('OPERATOR_IMG_DIR') + this.operator + localConfig.operatorImageExtension
    }
    return _img
  }

  Combination.prototype.getUsageInfo = function() {
    const tariffInfo = this.tariff ? { tariff: this.tariff.getUsageInfo() } : {}
    return {
      product: this.article.getUsageInfo(true),
      ...tariffInfo
    }
  }

  /**
   * If this is a combination with tariff then return the combi price,
   * otherwise the basic list price.
   * If this return value is a null, that means this combination is not saleable
   * @returns {*}
   */
  Combination.prototype.getPrice = function() {
    let divider = 1

    let price = this.price

    if (angular.isNumber(this.combi_price_divider)) {
      divider = this.combi_price_divider

      price = calculateDividedPrice(price, divider)
    }

    return price
  }

  // ~~~~~~~~~~~~~~
  // Builder
  // ~~~~~~~~~~~~~~
  Combination.build = data => new Combination(data)

  function calculateDividedPrice(price, divider) {
    let calculatedPrice = price

    if (price && divider) {
      calculatedPrice = price / divider
    } else {
      console.warn('dividePrice: no price or divider', price, divider)
    }
    return calculatedPrice
  }

  /**
   * Return the constructor function
   */
  return Combination
}
