/* @ngInject */
export default function oosiController($translate, ConfigProvider) {
  const $ctrl = this

  $ctrl.$onInit = () => {
    console.log($ctrl)
    const { indicatorTXT, stockLessThenLimit } = ConfigProvider.SETTINGS.OUT_OF_STOCK_INDICATOR
    $ctrl.indicatorTXT = indicatorTXT
    $ctrl.visible = $ctrl.primerArticle.stock !== undefined && $ctrl.primerArticle.stock < stockLessThenLimit
  }
}
