import article from './article/article.module'
import combination from './combination/combination.module'
import itemgroup from './item-group/item-group.module'
import tariffModule from './tariff/tariff.module'
import product from './product/product.module'
import productfilter from './product-filter/product-filter.module'

const module = angular.module('mpa.repositories', [
  product,
  article,
  tariffModule,
  itemgroup,
  productfilter,
  combination
])

export default module.name
