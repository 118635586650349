import { size, each } from 'lodash'

/* @ngInject */
export default function ProductGroupService() {
  let localData = {}

  // Service object
  return {
    init,
    getServiceData,
    reset: init,
    //
    // selectCategory: selectCategory,
    getCategoryFeatureGroupsById,
    getDefaultFeatureGroup,
    hasOnlySingleGroup,
    getDefaultFeatureGroupCategory
  }

  // ~~~~~~~~~~~~~~
  // Public API
  // ~~~~~~~~~~~~~~
  function getServiceData() {
    return localData
  }

  function getDefaultFeatureGroupCategory() {
    return localData.groups[0].categories[0]
  }

  function getCategoryFeatureGroupsById(categoryId) {
    let category = null
    if (categoryId) {
      category = localData.categories[categoryId]
    }
    return category
  }

  function hasOnlySingleGroup() {
    return size(localData.groups) === 1
  }

  function getDefaultFeatureGroup() {
    return localData.groups[0]
  }

  // ~~~~~~~~~~~~~~
  // Private API
  // ~~~~~~~~~~~~~~
  function init(productGroupsData) {
    console.groupCollapsed('%c ProductGroupService :: initializing ', 'background: #000; color: orange;')
    localData = initLocalData()
    each(productGroupsData, function(productGroup, key) {
      localData.groups[productGroup.id] = productGroup
      each(productGroup.categories, function(productGroupCategory, key2) {
        localData.categories[productGroupCategory.id] = productGroupCategory
        localData.categories[productGroupCategory.id].productGroupId = productGroup.id
      })
    })
    console.info(
      'ProductGroupService :: product groups [%s] and categories [%s] initialized ',
      size(localData.groups),
      size(localData.categories)
    )
    console.groupEnd()
  }

  function initLocalData() {
    return {
      groups: {},
      categories: {}
    }
  }
}
