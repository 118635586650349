import { each, find } from 'lodash'

/* @ngInject */
export default function stateEngineService($rootScope, $interval) {
  StateTransition.prototype = {
    fromState: null,
    toState: null,
    transitionEvent: null
  }

  function StateTransition(from, event, to, id) {
    this.fromState = from
    this.transitionEvent = event
    this.toState = to
    this.id = id || from + '2' + to
  }

  let Engine = function() {
    this.config = {
      'auto-broadcast': false,
      'init-state-array-position': 0,
      'verbose-log': false,
      'application-state-transition-event-name': 'ApplicationStateTransition'
    }
    this.current = ''
    this.states = []
    this.events = []
    this.transitions = []
    this.async = {
      callable: null,
      promise: null
    }
  }

  Engine.prototype = {
    start: function() {
      // Set the state
      if (this.states.length > 0) {
        this.current = this.states[this.config['init-state-array-position']]
      }
      // Create a hashmap for faster event transition finding (this lookup will be triggered many time, find is slow)
      this.transitionMap = {}
      let that = this
      each(this.transitions, function(value, key) {
        if (!that.transitionMap[value.fromState]) {
          that.transitionMap[value.fromState] = {}
        }
        that.transitionMap[value.fromState][value.transitionEvent] = value
      })
      return this
    },
    setConfig: function(key, value) {
      this.config[key] = value
      return this
    },
    setStates: function(states) {
      this.states = states
      return this
    },
    setAsyncAutoTransition: function(delay, toState, callback) {
      let that = this
      this.async.callable = function() {
        return $interval(
          function() {
            that.forceState(toState)
            callback()
          },
          delay,
          1
        )
      }
      return this
    },
    startAsyncAutoTransition: function() {
      $interval.cancel(this.async.promise)
      this.async.promise = this.async.callable()
    },
    setEvents: function(events) {
      this.events = events
      return this
    },
    addTransition: function(from, event, to, id) {
      this.transitions.push(new StateTransition(from, event, to, id))
      return this
    },
    forceState: function(state) {
      if (isStateValid(this, state)) {
        let _prevState = this.current
        this.current = state
        let enforcedStateTransition = new StateTransition(_prevState, 'FORCED', state)
        afterStateTransition(this, enforcedStateTransition)
      } else {
        console.warn('The forced state transition: ' + state + ' is not valid, no action was made!')
      }
    },
    evalTransitionEvent: function(event) {
      let that = this
      let transition = null
      // Look up in the hashmap if it is presented (fast) or with find (slow)
      if (this.transitionMap) {
        if (this.transitionMap[this.current] && this.transitionMap[this.current][event]) {
          transition = this.transitionMap[this.current][event]
        }
      } else {
        transition = find(
          this.transitions,
          function(_transition) {
            return _transition.fromState === this.current && _transition.transitionEvent === event
          },
          this
        )
      }

      if (transition) {
        $interval(
          function() {
            that.current = transition.toState
          },
          0,
          1
        )
        afterStateTransition(this, transition)
      } else {
        if (this.config['verbose-log']) {
          console.warn('No transition from state ' + this.current + ' via ' + event + ' event')
        }
        if (!isEventValid(this, event)) {
          console.warn('No event found in this engine with the name: ' + event)
        }
      }
      return transition
    }
  }

  function isEventValid(engine, event) {
    return engine.events.indexOf(event) > -1
  }

  function isStateValid(engine, state) {
    return engine.states.indexOf(state) > -1
  }

  function afterStateTransition(engine, transition) {
    if (engine.config['auto-broadcast'] && engine.config['application-state-transition-event-name']) {
      if (engine.config['verbose-log']) {
        console.info(
          'State Engine application state transition with event name: %s, with payload: %j',
          engine.config['application-state-transition-event-name'],
          transition
        )
      }
      $rootScope.$broadcast(engine.config['application-state-transition-event-name'], transition)
    } else {
      console.warn('Application state change was NOT broadcast! The feature is turned off or the event name is invalid')
    }
  }

  Engine.build = function() {
    return new Engine()
  }

  return Engine
}
