export default {
  'specs-feature-icon-list': {
    default: 'feature-video',
    'feature-video': {
      type: 'DIRECTIVE',
      name: 'feature-video'
    },
    'feature-tooltip': {
      type: 'DIRECTIVE',
      name: 'feature-tooltip'
    }
  },
  'feature-filter-list': {
    default: 'icon-only',
    'icon-only': {
      type: 'DIRECTIVE',
      name: 'feature-filter-icon'
    },
    'icon-with-label': {
      type: 'DIRECTIVE',
      name: 'feature-filter-labeled'
    },
    'icon-with-tag': {
      type: 'DIRECTIVE',
      name: 'feature-filter-tagged'
    }
  },
  'product-filter': {
    default: 'popup',
    icon: {
      type: 'DIRECTIVE',
      name: 'product-filter-icon'
    },
    accordion: {
      type: 'DIRECTIVE',
      name: 'product-filter-accordion'
    },
    popup: {
      type: 'DIRECTIVE',
      name: 'product-filter-popup'
    }
  }
}
