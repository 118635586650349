import { get, find } from 'lodash'
import { PUBLISHER_CHANNELS, SUBSCRIPTION_CHANNELS } from '../socket-comm/socket.comm.config'

/* @ngInject */
export default function audioService(
  $timeout,
  $rootScope,
  EVENT_KEYS,
  DataProvider,
  ConfigProvider,
  ComponentMatrixProvider,
  socketChannelEcosystemService
) {
  const sources = {
    fileSystem: 'filesystem',
    lineIn: 'line-in'
  }

  // instead of using 'num_of_ports' value, we set an exact value as 16 for this device
  // const numOfAudioPorts = get(ConfigProvider.getNodeSettings(), 'admin_settings.section_config.hw_mapping.num_of_ports')
  const numOfAudioPorts = 16

  const audioConfig = get(ConfigProvider.getNodeSettings(), 'app_settings.audio_config')
  const isAllPortsEnabled = get(ConfigProvider.SETTINGS.COMPONENT.ALLOWANCE, 'audioAllPorts')
  const tracksDir = get(audioConfig, 'dir', '/var/www/aiss/media/audio/')
  const rawPlaylist = get(audioConfig, 'playlist', [])
  const playlist = rawPlaylist.map((track, idx) => ({ ...track, id: idx + 1, fileName: tracksDir + track.fileName }))

  const data = {
    isEnabled: false,
    isLineIn: false,
    state: 'stopped',
    isPlaying: false,
    selectedVolume: 64
  }

  return {
    initialize,
    isEnabled,
    isLineIn,
    getPlaylist,
    play,
    pause,
    resume,
    stop,
    setVolume,
    requestPlayingState
  }

  // Public methods
  function isLineIn() {
    return data.isLineIn
  }

  function getPlaylist() {
    return playlist
  }

  function play(audioId) {
    const message = {
      source: sources.lineIn
    }

    if (audioId !== sources.lineIn) {
      message.source = sources.fileSystem
      message.filename = audioPath(audioId)
    }
    socketChannelEcosystemService.emit('publish', {
      channel: PUBLISHER_CHANNELS.SOUND_PLAY,
      message
    })
  }

  function pause() {
    data.isPlaying = false
    socketChannelEcosystemService.emit('publish', {
      channel: PUBLISHER_CHANNELS.SOUND_PAUSE,
      message: {}
    })
    // requestPlayingState()
  }

  function resume() {
    data.isPlaying = true
    socketChannelEcosystemService.emit('publish', {
      channel: PUBLISHER_CHANNELS.SOUND_RESUME,
      message: {}
    })
    // requestPlayingState()
  }

  function stop() {
    data.isPlaying = false
    socketChannelEcosystemService.emit('publish', {
      channel: PUBLISHER_CHANNELS.SOUND_STOP,
      message: {}
    })
  }

  function setVolume(volume) {
    socketChannelEcosystemService.emit('publish', {
      channel: PUBLISHER_CHANNELS.SOUND_SET_VOLUME,
      message: { volume }
    })
  }

  function requestPlayingState() {
    socketChannelEcosystemService.emit('publish', {
      channel: PUBLISHER_CHANNELS.SOUND_GET_STATUS,
      message: {}
    })
  }

  function isEnabled() {
    return data.isEnabled
  }

  function initialize() {
    return new Promise((resolve, reject) => {
      ComponentMatrixProvider.isServiceReady().then(
        function() {
          if (ComponentMatrixProvider.isComponentAllowed('audioExtension')) {
            $rootScope.$on(SUBSCRIPTION_CHANNELS.SOUND_STATE, stateReceived)

            // requestPlayingState()

            Promise.all([hwInfoReceived(), DataProvider.isServiceReady()]).then(
              () => {
                initAudioPortSetting()

                if (!isAllPortsEnabled) {
                  $rootScope.$on(EVENT_KEYS.LOGIC.TURN_ON_AUDIO_PORT_BY_ARTICLE_IDS, setAudioPortSettingByArticleIds)
                  $rootScope.$on(EVENT_KEYS.LOGIC.TURN_OFF_AUDIO_PORTS, setAudioPortSettingByArticleIds)
                  $rootScope.$on(EVENT_KEYS.LOGIC.GLOBAL_RESET, setAudioPortSettingByArticleIds)
                }

                resolve()
              },
              err => {
                reject(err)
              }
            )
          } else {
            reject(Error('Audio component is not enabled'))
          }
        },
        err => {
          reject(err)
        }
      )
    })
  }

  // Private methods
  function hwInfoReceived(e, state) {
    return new Promise((resolve, reject) => {
      $rootScope.$on(SUBSCRIPTION_CHANNELS.HW_INFO_STATE, function(e, state) {
        if (find(state.devices, { driver: 'sound' })) {
          data.isEnabled = true
          resolve()
        }
      })

      // set timeout for reject
      $timeout(() => {
        reject(Error('No hardware info received for audio component'))
      }, 5000)
      socketChannelEcosystemService.emit('publish', {
        channel: PUBLISHER_CHANNELS.HW_INFO_GET,
        message: {}
      })
    })
  }

  function initAudioPortSetting() {
    // initialize the port status array and publish on raw(!) channel

    const portInitValue = isAllPortsEnabled ? 1 : 0
    const portSetting = new Array(numOfAudioPorts).fill(portInitValue)
    socketChannelEcosystemService.emit('publish', {
      channel: PUBLISHER_CHANNELS.SOUND_SET_RAW,
      message: { activePorts: portSetting }
    })
  }

  function setAudioPortSettingByArticleIds(event, articleIds = []) {
    socketChannelEcosystemService.emit('publish', {
      channel: PUBLISHER_CHANNELS.SOUND_SET_BY_ARTICLE_IDS,
      message: { ids: articleIds }
    })
  }

  function stateReceived(e, state) {
    data.selectedVolume = state.volume
    data.isLineIn = state.source === 'line-in'
    switch (state.replay) {
      case 'stopped':
        data.isPlaying = false
        data.state = 'stopped'
        break
      case 'paused':
        data.isPlaying = false
        data.state = 'paused'
        break
      case 'playing':
        data.isPlaying = true
        data.state = 'playing'
        break
    }

    $rootScope.$broadcast('on-audio-player-changed', {
      selectedVolume: data.selectedVolume,
      isPlaying: data.isPlaying,
      state: data.state
    })
  }

  function audioPath(audioId) {
    let audio = find(getPlaylist(), { id: audioId })
    return audio.fileName
  }
}
