/* @ngInject */
export default function headerController(
  navigationService,
  $state,
  $stateParams,
  MPA_PAGES,
  $transitions,
  ConfigProvider,
  groupSelectorService
) {
  const ctrl = this

  const setHeaderButtonStates = () => {
    ctrl.state.selectedProductGroup = groupSelectorService.getProductGroupData($stateParams.groupId)
    ctrl.state.showNavigation = $state.$current.name !== navigationService.getDefaultPage().name

    if (
      navigationService.getDefaultPage().name === MPA_PAGES.SELECTOR_LIST.name &&
      $state.$current.name === MPA_PAGES.GROUP_SELECTOR_LIST.name
    ) {
      ctrl.state.showNavigation = false // todo: this a workaround
    }
    ctrl.state.activeSelectorButton = $state.$current.name !== MPA_PAGES.SELECTOR_LIST.name
  }

  ctrl.$onInit = () => {
    ctrl.state = {
      selectedProductGroup: groupSelectorService.getProductGroupData($stateParams.groupId),
      showCategorySelectorButton: groupSelectorService.getProductGroupData().length > 1,
      showNavigation: false,
      activeSelectorButton: $state.$current.name !== MPA_PAGES.SELECTOR_LIST.name,
      logoStyle: {}
    }

    if (ConfigProvider.getOverrideBrandLogoUrl()) {
      ctrl.state.logoStyle = { backgroundImage: 'url(' + ConfigProvider.getOverrideBrandLogoUrl() + ')' }
    }

    setHeaderButtonStates()
    $transitions.onSuccess({}, setHeaderButtonStates)
  }

  const triggerBrandLogo = () => {
    navigationService.goToDefaultPage()
  }
  const triggerBackButton = () => {
    navigationService.goToPreviousState()
  }
  const triggerSelectorButton = () => {
    if (ctrl.state.activeSelectorButton) {
      navigationService.goToSelectorPage(ctrl.state.selectedProductGroup.id)
    }
  }
  const triggerCategorySelectorButton = () => {
    navigationService.goToDefaultPage()
  }

  ctrl.controlFunctions = {
    triggerBrandLogo,
    triggerBackButton,
    triggerSelectorButton,
    triggerCategorySelectorButton
  }
}
