/* @ngInject */
export default function groupSelectorPageController(
  navigationService,
  ConfigProvider,
  businessLogService,
  groupSelectorService,
  $scope,
  $compile,
  $timeout,
  displayService
) {
  const ctrl = this

  ctrl.isUpdating = groupSelectorService.isUpdating

  ctrl.$onInit = () => {
    ctrl.state = {
      highlightedItem: groupSelectorService.getHighlightedGroup(),
      groups: ConfigProvider.getPrimaryProductGroupConfig(),
      swiperConfig: {
        slidesPerView: displayService.isHorizontal() ? 5 : 3,
        direction: 'horizontal',
        coverflowEffect: {
          rotate: 0,
          depth: 200,
          slideShadows: false
        }
      },
      renderSlide: renderSlideFunction
    }
    groupSelectorService.isUpdating(false)
  }

  const renderSlideFunction = group => {
    const itemScope = $scope.$new(true)
    itemScope.group = group
    itemScope.handleGroupSelect = handleGroupSelect
    const template = `<group-item 
                        class="swiper-slide"
                        ng-click="handleGroupSelect(group)"
                        group="group"
                    </group-item>`
    return $compile(template)(itemScope)
  }

  const handleGroupSelect = group => {
    groupSelectorService.setActiveProductGroup(group.id)
    navigationService.goToSelectorPage(group.id)

    businessLogService.generate({
      event: businessLogService.events.PRODUCT_GROUP_SELECTION,
      info: {
        name: group.id
      }
    })
  }

  const handleHighlightedItemChange = ({ item }) => {
    if (item) {
      ctrl.state.highlightedItem = item
      groupSelectorService.setHighlightedGroup(item)
    }
  }

  ctrl.controlFunctions = {
    handleHighlightedItemChange
  }
}
