import localeDateFilter from './locale-date/locale-date.filter'
import trustUrl from './trust-url/trust-url.filter'
import capitalize from './capitalize.filter'
import countryArticleNumber from './country-article-number.filter'
import nl2br from './nl2br.filter'
import price from './price.filter'

const module = angular.module('mpa.commons.filters', [])

module
  .filter('capitalize', capitalize)
  .filter('countryArticleNumber', countryArticleNumber)
  .filter('nl2br', nl2br)
  .filter('price', price)
  .filter('localeDateFilter', localeDateFilter)
  .filter('trustUrl', trustUrl)

export default module.name
