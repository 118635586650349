/* @ngInject */
export default function detailsAccessoriesConfig($stateProvider, MPA_PAGES) {
  $stateProvider.state(MPA_PAGES.DETAILS_ACCESSORIES.name, {
    url: MPA_PAGES.DETAILS_ACCESSORIES.url,
    redirectTo: MPA_PAGES.DETAILS_ACCESSORIES_CATEGORY_SELECTOR.name,
    // abstract: true,
    title: 'MENU_ACCESSORIES',
    weight: 5,
    visible: true,
    componentAllowanceId: 'accessoriesExtension',
    // custom props end
    component: 'accessoriesPageComponent',
    resolve: {
      /* @ngInject */
      services: accessoryService => accessoryService.init(),
      /* @ngInject */
      categories: (accessoryService, itemGroup) => accessoryService.getCategoriesForItemGroup(itemGroup),
      /* @ngInject */
      interaction: (businessLogService, itemGroup) =>
        businessLogService.generate({
          event: businessLogService.events.DETAILS_ACCESSORIES,
          data: {
            product: itemGroup.getUsageInfo()
          }
        })
    }
  })
}
