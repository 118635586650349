/* @ngInject */
export default function itemGroupSelectorController(
  $scope,
  $stateParams,
  pinnedList,
  navigationService,
  ConfigProvider,
  EVENT_KEYS,
  ProductListService,
  displayService,
  businessLogService
) {
  let ctrl = this

  ctrl.$onInit = initialize

  $scope.$on(EVENT_KEYS.LOGIC.PINNED_LIST_CHANGED, pinnedListChangeHandler)

  ctrl.data = {}
  const key = displayService.isHorizontal()
    ? 'MAX_PINNED_ITEMS_TO_COMPARE_HORIZONTAL'
    : 'MAX_PINNED_ITEMS_TO_COMPARE_VERTICAL'
  ctrl.state = {
    pinnedNum: 0,
    isComparable: false,
    selectedItemId: null,
    neededNumToCompare: ConfigProvider.getFrontendSettingByKey('MIN_PINNED_ITEMS_TO_COMPARE'),
    maxPinnedItems: ConfigProvider.getFrontendSettingByKey(key)
  }

  function initialize() {
    refreshData()
  }

  function pinnedListChangeHandler(event, ids) {
    refreshData()
  }

  function triggerItemsCompare() {
    if (isComparable()) {
      navigationService.goToComparisonPage()
    }
  }

  function triggerItemSelect() {
    if (ctrl.itemGroup) {
      navigationService.goToDetailsPage($stateParams.groupId, ctrl.itemGroup.id)

      businessLogService.generate({
        event: businessLogService.events.PRODUCT_SELECTION,
        data: { product: ctrl.itemGroup.getUsageInfo() },
        info: {
          from: 'selector-page:select-button',
          source: 'screen'
        }
      })
    }
  }

  const resetPinnedList = () => pinnedList.clear()

  function isComparable() {
    return ctrl.state.pinnedNum >= ctrl.state.neededNumToCompare
  }

  function refreshData() {
    ctrl.data.pinnedIds = pinnedList.getPinnedIds()
    ctrl.state.pinnedNum = ctrl.data.pinnedIds.length
    ctrl.state.isComparable = isComparable()
  }

  ctrl.controlFunctions = {
    triggerItemSelect,
    triggerItemsCompare,
    resetPinnedList
  }
}
