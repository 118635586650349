import template from './pick-up-notification.template.html'

/* @ngInject */
export default function pickUpNotification($q) {
  // Component related letiables
  let componentReadyPromise = $q.defer()

  return {
    restrict: 'E',
    template,
    scope: {},
    controller
  }

  function controller(
    $scope,
    $stateParams,
    ArticleListService,
    ItemGroupListService,
    $interval,
    ConfigProvider,
    EVENT_KEYS,
    navigationService
  ) {
    // Attach event listeners
    $scope.$on(EVENT_KEYS.LOGIC.GLOBAL_RESET, resetComponentHandler)
    $scope.$on(EVENT_KEYS.LOGIC.GLOBAL_INIT, initComponentHandler)
    $scope.$on(EVENT_KEYS.LOGIC.SHOW_PICK_UP_NOTIFICATION, pickUpNavigationIntentionHandler)
    $scope.$on(EVENT_KEYS.LOGIC.STATE_CHANGE, stateChangeHandler)

    $scope.controlFunctions = {
      navigateToDetailsPage: navigateToDetailsPage
    }

    initComponent()

    // ~~~~~~~~~~~~~~
    // Scope functions
    // ~~~~~~~~~~~~~~
    function navigateToDetailsPage() {
      $scope.state.opened = false
      if ($scope.data.itemGroup) {
        navigationService.goToDetailsPage($stateParams.groupId, $scope.data.itemGroup.id)
      }
    }

    // ~~~~~~~~~~~~~~
    // Event handlers
    // ~~~~~~~~~~~~~~
    function pickUpNavigationIntentionHandler(event, pickedUpArticleId) {
      componentReadyPromise.promise.then(
        function() {
          if (pickedUpArticleId) {
            $scope.data.article = ArticleListService.getArticleById(pickedUpArticleId)
            $scope.data.itemGroup = ItemGroupListService.getItemGroupById($scope.data.article.itemGroupId)
            $scope.data.product = $scope.data.itemGroup.product
            $scope.state.opened = true

            cancelTimeOut()

            $scope.state.openedTimeout = $interval(
              function() {
                $scope.state.opened = false
              },
              ConfigProvider.getFrontendSettingByKey('SHOW_PICK_UP_NAVIGATION_NOTIFICATION_TIMEOUT_MS'),
              1
            )
          }
        },
        // component not ready
        function() {}
      )
    }

    function initComponentHandler() {
      initComponent()
    }

    function resetComponentHandler() {
      resetComponent()
    }

    function stateChangeHandler() {
      $scope.state.opened = false
      cancelTimeOut()
    }

    // ~~~~~~~~~~~~~~
    // Private
    // ~~~~~~~~~~~~~~
    function cancelTimeOut() {
      if ($scope.state.openedTimeout) {
        $interval.cancel($scope.state.openedTimeout)
      }
    }

    // ~~~~~~~~~~~~~~
    // Component lifecycle
    // ~~~~~~~~~~~~~~
    function resetComponent() {
      componentReadyPromise = $q.defer()
      $scope.data = {
        product: {}
      }

      $scope.state = {
        opened: false,
        openedTimeout: null
      }
    }

    function initComponent() {
      resetComponent()
      console.info('pickUpNotification :: component is ready')
      componentReadyPromise.resolve()
    }
  }
}
