import createEventEmitter from 'tower2/common/utils/event-emitter'
import { role, ROLES } from '../../../commons/services/identity'
import { getFrontendSettings } from '../../../app.data'
import { ALLOWED_REMOTE_PUBLISHER_CHANNELS } from '../socket.comm.config'

const socketChannel = createEventEmitter({
  name: 'ecosystem'
})

const filterPayload = payload => {
  const forcedRole = getFrontendSettings().ROLE
  let filteredPayload = Array.isArray(payload) ? payload : [payload]
  if (role === ROLES.REMOTE || (forcedRole && forcedRole !== ROLES.MAIN)) {
    filteredPayload = filteredPayload.filter(pl => ALLOWED_REMOTE_PUBLISHER_CHANNELS.includes(pl.channel))
  }
  return filteredPayload
}

socketChannel.on('publish', payload => {
  const filteredPayload = filterPayload(payload)
  if (filteredPayload.length) {
    socketChannel.send({
      command: 'publish',
      payload: filteredPayload
    })
  }
})

export { socketChannel }

/* @ngInject */
export default function socketChannelEcosystemService() {
  /* @ngInject */
  this.$get = $rootScope => {
    socketChannel.on('message', payload => {
      if (!Array.isArray(payload)) {
        payload = [payload]
      }

      payload.forEach(p => {
        const { channel, message } = p
        $rootScope.$broadcast(channel, message)
      })
    })
    return socketChannel
  }
}
