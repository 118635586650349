/* @ngInject */
export default function navigationService($rootScope, $window, $state, MPA_PAGES, ConfigProvider) {
  let defaultPage = MPA_PAGES.DEFAULT
  // Public API
  const hardReset = () => {
    $window.location.href = '/'
  }
  const goToDefaultPage = () => {
    $state.go(defaultPage.name)
  }
  const goToSelectorPage = groupId => {
    $state.go(MPA_PAGES.SELECTOR_LIST.name, { groupId })
  }
  const goToAdmin = () => {
    $window.location.href = ConfigProvider.getFrontendSettingByKey('ADMIN_URL')
  }
  const goToCheckoutPage = itemGroupId => {
    $state.go(MPA_PAGES.CHECKOUT.name, { itemGroupId })
  }
  const goToDetailsPage = (groupId, itemGroupId) => {
    $state.go(MPA_PAGES.DETAILS_SPECS.name, { groupId, itemGroupId })
  }
  const goToComparisonPage = () => {
    if ($state.current.name === MPA_PAGES.COMPARISON.name) $state.reload()
    else $state.go(MPA_PAGES.COMPARISON.name)
  }
  const goToPreviousState = () => {
    $window.history.back()
  }

  const setDefaultPage = page => {
    defaultPage = page
  }
  const getDefaultPage = () => defaultPage

  return {
    setDefaultPage,
    hardReset,
    goToAdmin,
    goToDefaultPage,
    goToComparisonPage,
    goToSelectorPage,
    goToCheckoutPage,
    goToDetailsPage,
    goToPreviousState,
    getDefaultPage
  }
}
