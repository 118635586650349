/* @ngInject */
export default function cartService($http, ConfigProvider) {
  const { apiUrl, apiTimeout } = ConfigProvider.SETTINGS.CART

  const addItem = data =>
    $http({
      method: 'post',
      responseType: 'json',
      cache: 'false',
      url: `proxy/${apiUrl}`,
      timeout: apiTimeout,
      data
    })

  return {
    addItem
  }
}
