import controller from './cart.controller'
import template from './cart.html'

export default {
  bindings: {
    item: '<'
  },
  template,
  controller
}
