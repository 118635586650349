import { each, get, min, max, map, has, find, includes, uniq, filter, bind } from 'lodash'

const getGroupName = (config, id) => {
  const group = find(config['feature_filter_named_groups'], group => group.filters.includes(id))
  return group ? group.group_label : null
}
const getGroupOperator = (config, id, groupName) => {
  // let's try as feature filter
  let group = find(config['feature_filter_named_groups'], group => group.filters.includes(id))
  // if not, it's a dynamic filter
  group = group || find(config['dynamic_filter_groups'], group => group.filter_name === groupName)
  return group ? group.group_operator : null
}

/* @ngInject */
export default function ProductFilterService(ProductFilterFactory, ProductOtfFilterFactory, ProductFilterGroupFactory) {
  let SHOW_EMPTY_FILTER = false

  let localData = {}

  const getServiceData = () => localData
  const reset = () => {
    resetActiveFilters()
  }
  const resetFeatureGroups = () => {
    each(localData.featureGroups, (featureGroup, k) => {
      featureGroup.reset()
    })
  }
  const activateFilters = filters => {
    each(filters, (filter, k) => {
      filter.setActive()
    })
  }
  const resetActiveFilters = () => {
    each(localData.filters, (filter, k) => {
      filter.reset()
    })
  }
  const getActiveFeatures = () => {
    refreshActiveFeatures()
    return localData.activeFeatures
  }

  const getActiveProperties = () => {
    refreshActiveProperties()
    return localData.activeProperties
  }

  const getActiveRangeSelectors = () => {
    refreshActiveRangeSelectors()
    return localData.activeRangeSelectors
  }

  return {
    // Base Component Service API
    init,
    reset,
    getServiceData,
    // Component Specific API
    resetFeatureGroups,
    activateFilters,
    resetActiveFilters,
    getActiveFeatures,
    getActiveProperties,
    getActiveRangeSelectors
  }

  function buildProductFilterGroup(config, filters, simpleCollection) {
    let productFilterGroup = ProductFilterGroupFactory.build(config, filters)
    if (simpleCollection) {
      // If it is a simple collection the config should be an array with the ordered filter ids
      // If it is a named group the config should be an array of objects
      let filterIdOrderArray = get(config, 'filters', config)
      productFilterGroup.orderFiltersByIdArray(filterIdOrderArray)
    }
    return productFilterGroup
  }

  // ~~~~~~~~~~~~~~
  // Private API
  // ~~~~~~~~~~~~~~
  function initLocalData() {
    return {
      // filter collection, data store, not for UI use
      filters: {},
      filterConfig: {},
      // iterable data structures for easier UI representation
      propertyGroups: [],
      featureGroups: [],
      lifestyleGroups: [],
      rangeSelectors: [],
      // data representation for listener services (ex.: some kind of business logic)
      activeFeatures: [],
      activeProperties: [],
      activeRangeSelectors: []
    }
  }

  /**
   * Self constructor, build up process
   */
  function init(filterConfig, filters, articles) {
    console.groupCollapsed(`%c ProductFilterService :: initializing `, 'background: #000; color: orange;')
    console.log('ProductFilterService :: init', filterConfig, filters)

    // Init empty local data structure
    localData = initLocalData()
    // node_settings.app_settings.product_filter_config
    localData.filterConfig = filterConfig
    // Get the data
    localData.filters = buildFiltersFromData(filters, null)
    // Get the config and build up structured map for easier data representation
    mapDataByConfig(localData.filterConfig)
    // Build the predefined rangeSelectors (like price)
    // TODO
    localData.rangeSelectors = buildRangeSelectors(articles)
    // Register range selector filters
    each(localData.rangeSelectors, function(selector) {
      localData.filters[selector.id] = selector
    })

    console.log('ProductFilterService :: initialized', localData)
    console.groupEnd()
  }

  /**
   * Recursive Filter from row data builder. Decides up on the raw data type: array, object.
   * @param rawData data to model
   * @param groupName is the container filter group's name, if any
   * @returns {{filters: {}, filterGroups: {}}}
   */
  function buildFiltersFromData(rawData, groupName) {
    let data = {}
    each(rawData, function(filterSectionMeta, id) {
      if (filterSectionMeta && angular.isArray(filterSectionMeta)) {
        // The value is an array full of ids
        // LEFT EMPTY FILTERS OUT by config
        if (filterSectionMeta.length > 0 || SHOW_EMPTY_FILTER) {
          data[id] = ProductFilterFactory.build(filterSectionMeta, {
            id,
            groupName: groupName || getGroupName(localData.filterConfig, id),
            groupOperator: getGroupOperator(localData.filterConfig, id, groupName) || 'AND',
            iconName: getMappedIconNameById(id),
            label: getMappedLabelNameById(id)
          })
        }
      } else if (angular.isObject(filterSectionMeta)) {
        // If it is a container object, start the recursion
        angular.extend(data, buildFiltersFromData(filterSectionMeta, id))
      }
    })
    return data
  }

  function buildRangeSelectors(articles) {
    let selectors = []
    let listPriceSelector = ProductOtfFilterFactory.build({
      id: 'price',
      fieldToFilterOn: 'price',
      label: 'FILTER_PRICE_RANGE',
      from: min(map(articles, 'price')),
      to: max(map(articles, 'price')),
      possibleValuesQuantity: _(articles)
        .map('price')
        .uniq()
        .size()
    })
    selectors.push(listPriceSelector)
    return selectors
  }

  /**
   * Attach the mapped icon name by key.
   * @param key
   * @returns {*}
   */
  function getMappedIconNameById(key) {
    let ret = key
    if (
      localData.filterConfig.filter_video_icon_mapping &&
      localData.filterConfig.filter_video_icon_mapping[key] &&
      localData.filterConfig.filter_video_icon_mapping[key].icon_name
    ) {
      ret = localData.filterConfig.filter_video_icon_mapping[key].icon_name
    }
    return ret
  }

  /**
   * Attach the mapped label value by key.
   * @param key
   * @returns {*}
   */
  function getMappedLabelNameById(key) {
    let ret = key
    if (localData.filterConfig.filter_video_icon_mapping && localData.filterConfig.filter_video_icon_mapping[key]) {
      ret = get(localData.filterConfig.filter_video_icon_mapping[key], 'label')
    }
    return ret
  }

  /**
   * Based on the raw config builds up the needed data structures (maps).
   * Finally, push changes into localData.
   * @param rawFilterConfig config data from EP.
   */
  function mapDataByConfig(rawFilterConfig) {
    // Map up the selector groups
    let _shadowPropertyGroups = dataByConfigBuilder(
      rawFilterConfig.dynamic_filter_groups,
      function(element) {
        let value = this // We have to pass the context param to achieve this
        return element.groupName && element.groupName === value.filter_name
      },
      false
    )

    // Map the ordered single filters
    // NOTE: some magic here
    // Since when these filters can have a group name, we must handle this here.
    // In this case the filter config is not an array of strings but an array of objects, like:
    // [{filters:['filterName', ...], group_label:''}, ...]
    let isNamedGroup = has(rawFilterConfig, 'feature_filter_named_groups')
    let configKey = isNamedGroup ? 'feature_filter_named_groups' : 'feature_filter_groups'
    let filterConfig = get(rawFilterConfig, configKey)
    let _shadowFeatureOrderGroups = dataByConfigBuilder(
      filterConfig,
      function(element) {
        let value = this // We have to pass the context param to achieve this
        let filterArray = isNamedGroup ? get(value, 'filters', []) : value
        return element.id && includes(filterArray, element.id)
      },
      true
    )

    // Map the lifestyle filter groups
    let _shadowLifestyleGroups = dataByConfigBuilder(
      rawFilterConfig.lifestyle_filters,
      function(element) {
        let value = this // We have to pass the context param to achieve this
        return element.id && value.filters.indexOf(element.id) > -1
      },
      false
    )

    localData.propertyGroups = _shadowPropertyGroups
    localData.featureGroups = _shadowFeatureOrderGroups
    localData.lifestyleGroups = _shadowLifestyleGroups
  }

  /**
   * The worker function for dataMapping.
   * @param configSegment part of the raw data config
   * @param filterFunction the filter function for the underscore filter.
   * @returns {Array}
   */
  function dataByConfigBuilder(configSegment, filterFunction, simpleCollection) {
    let _shadowGroups = []
    each(configSegment, function(value, key) {
      let _filters = uniq(filter(localData.filters, bind(filterFunction, value)))
      _shadowGroups[key] = buildProductFilterGroup(value, _filters, simpleCollection)
    })
    return _shadowGroups
  }

  /**
   * Recalculate the activeFeatures array.
   */
  function refreshActiveFeatures() {
    localData.activeFeatures = filter(localData.filters, function(element) {
      return element.active && element.isType(ProductFilterFactory.getFilterTypes().feature)
    })
  }

  /**
   * Recalculate the activeProperties array.
   */
  function refreshActiveProperties() {
    localData.activeProperties = filter(localData.filters, function(element) {
      return element.active && element.isType(ProductFilterFactory.getFilterTypes().property)
    })
  }

  function refreshActiveRangeSelectors() {
    localData.activeRangeSelectors = filter(localData.rangeSelectors, {
      active: true
    })
  }
}
