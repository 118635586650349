/* @ngInject */
export default function priceDisplayController($translate, priceFilter, $transitions) {
  let ctrl = this

  const processPrice = () => {
    ctrl.price = ctrl.isRange
      ? $translate.instant('TXT_PRICE_RANGE', { price: priceFilter(ctrl.value) })
      : priceFilter(ctrl.value)
  }

  ctrl.$onChanges = processPrice
  $transitions.onEnter({}, processPrice)
}
