import { each } from 'lodash'

/* @ngInject */
export default function preloadRegistryService($injector, $q, $timeout) {
  let componentReadyDeferred = $q.defer()
  let PRELOAD_REGISTRY = null
  let localData = getLocalData()

  try {
    PRELOAD_REGISTRY = $injector.get('PRELOAD_REGISTRY')
    selfInitService()
  } catch (e) {
    console.info('No preload registry keys were provided')
    componentReadyDeferred.resolve(false)
  }

  // Service object
  return {
    isPreloadReady: isPreloadReady,
    resolveDirectiveLoad: resolveDirectiveLoad,
    PRELOAD_REGISTRY: PRELOAD_REGISTRY
  }

  // ~~~~~~~~~~~~~~
  // Public API
  // ~~~~~~~~~~~~~~
  function isPreloadReady() {
    return componentReadyDeferred.promise
  }

  /**
   * Resolve a directive load hook by its PRELOAD_REGISTRY name
   * @param name
   */
  function resolveDirectiveLoad(name) {
    if (name && localData.directives[name]) {
      localData.directives[name].resolve()
    }
  }

  // ~~~~~~~~~~~~~~
  // Private API
  // ~~~~~~~~~~~~~~
  function selfInitService() {
    let startTime = Date.now()
    componentReadyDeferred = $q.defer()
    localData = getLocalData()
    if (PRELOAD_REGISTRY) {
      let loadTimeout = null

      // Try to instantiate services
      let actual = null
      try {
        each(PRELOAD_REGISTRY.SERVICES, function(serviceName) {
          actual = serviceName
          localData.services[serviceName] = $injector.get(serviceName)
        })
      } catch (e) {
        console.warn('preloadRegistryService :: Could not inject service %s', actual)
      }

      // Add directive listeners
      each(PRELOAD_REGISTRY.DIRECTIVES, function(name) {
        // Wait for components to load
        let deferred = $q.defer()
        localData.directives[name] = deferred
        // Register all promises
        localData.promises.push(deferred.promise)
      })

      // Optimal case, everything is resolved
      $q.all(localData.promises).then(
        function() {
          $timeout.cancel(loadTimeout)
          resolveComponent(true, startTime)
        },
        function() {
          $timeout.cancel(loadTimeout)
          resolveComponent(false, startTime)
        }
      )

      // Fail case, not resolved in time
      loadTimeout = $timeout(function() {
        console.warn('Preload registry not finished in time [%s], enforce component ready resolve', 5000)
        componentReadyDeferred.resolve(false)
      }, 5000) // TODO config letiable
    } else {
      // If nothing to do
      resolveComponent(true)
    }
  }

  function resolveComponent(outcome, startTime) {
    if (startTime) {
      console.info(
        'preloadRegistryService :: finished directive preload without timeout in [%s] ms',
        Date.now() - startTime
      )
    }
    componentReadyDeferred.resolve(outcome)
  }

  function getLocalData() {
    return {
      directives: {},
      services: {},
      promises: []
    }
  }
}
