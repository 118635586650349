import { extend, first, keys, isObject, each, get } from 'lodash'

/* @ngInject */
export default function ComponentMatrixProvider(MPA_COMPONENT_SELECTION, $q, ConfigProvider) {
  let appComponentSelectionChoices = MPA_COMPONENT_SELECTION

  // Set some alias for matching the backend send config names with the real component names
  let componentAliases = {
    face_recognition: 'faceRecLayer',
    idle_video: 'idleVideoPlayer',
    print: 'printerService',
    qr: 'qrService',
    email: 'emailService',
    animation: 'animationService',
    led: 'ledService',
    product_group_selector: 'productGroupSelector'
  }

  let serviceDataPromise = $q.defer()
  let localData = {}
  // Extend with configurable alias names

  // Service object
  return {
    // General
    init,
    isServiceReady,
    // Service
    isComponentAllowed,
    isComponentAllowedOriginally,
    getComponentSelectionConfig,
    getComponentSelectionByName,
    getComponentSelectionConfigByName,
    setComponentAllowance,
    // Utils
    isCurrentDeviceMobileOrTablet
  }

  function setComponentAllowance(component, value) {
    localData.optionalComponentAllowanceMatrix[component] = value
  }

  // ~~~~~~~~~~~~~~
  // Public API
  // ~~~~~~~~~~~~~~
  function getComponentSelectionConfig() {
    return localData.componentSelection.config
  }

  function getComponentSelectionByName(componentId) {
    let componentSelection = null
    if (componentId) {
      if (localData.componentSelection.choices[componentId]) {
        // Specified
        if (localData.componentSelection.config[componentId]) {
          componentSelection = localData.componentSelection.config[componentId]
        } else if (localData.componentSelection.choices[componentId]['default']) {
          componentSelection = 'default'
        } else {
          componentSelection = first(keys(localData.componentSelection.choices[componentId]))
        }
      } else {
        console.warn('ComponentMatrixProvider :: no component choices for id %s', componentId)
      }
    }
    return componentSelection
  }

  /**
   * Get the component selection config. If called without specified it will return
   * the configured meta.
   * @param componentId
   * @param specified
   * @returns {*}
   */
  function getComponentSelectionConfigByName(componentId, specified) {
    let componentSelectionConfig = null
    if (componentId) {
      let cs = specified || getComponentSelectionByName(componentId)
      if (cs) {
        // Is it the config or just a named link
        componentSelectionConfig = localData.componentSelection.choices[componentId][cs]
        if (!isObject(componentSelectionConfig)) {
          componentSelectionConfig = getComponentSelectionConfigByName(componentId, componentSelectionConfig)
        } else {
          // Just dev friendly addon
          componentSelectionConfig.letiantName = cs
        }
      } else {
        console.warn('ComponentMatrixProvider :: no component choices for id %s', componentId)
      }
    }

    return componentSelectionConfig
  }

  /**
   * Determine if a component allowed or not, if not found then the default is true.
   * @param componentName
   * @returns {boolean}
   */
  function isComponentAllowed(componentName) {
    let ret = false
    if (localData.optionalComponentAllowanceMatrix.hasOwnProperty(componentName)) {
      ret = localData.optionalComponentAllowanceMatrix[componentName]
    }
    return ret
  }

  function isComponentAllowedOriginally(componentName) {
    let ret = false
    if (localData.originalComponentAllowance.hasOwnProperty(componentName)) {
      ret = localData.originalComponentAllowance[componentName]
    }
    return ret
  }

  /**
   * Callable promise responder for other services, chaining.
   */
  function isServiceReady() {
    return serviceDataPromise.promise
  }

  // ~~~~~~~~~~~~~~
  // Local API
  // ~~~~~~~~~~~~~~
  function init(componentConfig) {
    console.groupCollapsed('%c ComponentMatrixProvider :: initializing ', 'background: #000; color: orange;')
    localData = initLocalData()
    componentAliases = $.extend(componentAliases, ConfigProvider.SETTINGS.COMPONENT.ALIAS)
    localData.optionalComponentAllowanceMatrix = getDefaultOptionalComponentAllowanceMatrix()
    setComponentSelectionConfig()
    localData.rawComponentConfig = componentConfig
    setServerConfiguredComponentAllowanceMatrix()
    calculateFinalComponentAllowanceMatrix()
    // note: call again only for security purposes: all data should be ready at first call
    setComponentSelectionConfig()
    console.info('ComponentMatrixProvider :: Component allowance matrix build up by data provider node settings!')
    localData.originalComponentAllowance = Object.assign({}, localData.optionalComponentAllowanceMatrix)
    serviceDataPromise.resolve(true)
    console.log('ComponentMatrixProvider', localData)
    console.groupEnd()
  }

  function initLocalData() {
    return {
      optionalComponentAllowanceMatrix: {},
      serverComponentConfig: {},
      serverAllowance: {},
      componentSelection: {
        choices: {},
        config: {}
      }
    }
  }

  /**
   * This is a default config, in optimal case it will be override by all kind of configs
   */
  function getDefaultOptionalComponentAllowanceMatrix() {
    return {
      faceRecognitionVideoPlayer: !isCurrentDeviceMobileOrTablet(),
      idleVideoPlayer: !isCurrentDeviceMobileOrTablet(),
      printerService: !isCurrentDeviceMobileOrTablet(),
      qrService: !isCurrentDeviceMobileOrTablet(),
      emailService: !isCurrentDeviceMobileOrTablet(),
      ledService: !isCurrentDeviceMobileOrTablet(),
      animationService: !isCurrentDeviceMobileOrTablet(),
      productGroupSelector: true
    }
  }

  /**
   * Parses the raw server component allowance by role
   */
  function setServerConfiguredComponentAllowanceMatrix() {
    // TODO: we determine the role manually, it should come from backend (or cookie etc.)
    let role = isCurrentDeviceMobileOrTablet() ? 'mobile_client_services' : 'primary_client_services'

    // Set the service configs from server
    each(get(localData, 'rawComponentConfig.services'), function(config, rawComponentName) {
      localData.serverComponentConfig[rawComponentName] = config
      localData.serverAllowance[rawComponentName] = false
      if (componentAliases[rawComponentName]) {
        localData.serverComponentConfig[componentAliases[rawComponentName]] = config
        localData.serverAllowance[componentAliases[rawComponentName]] = false
      }
    })

    // Read the server related part set
    let componentSet = {}
    if (get(localData, 'rawComponentConfig.sets')) {
      componentSet = localData.rawComponentConfig.sets[role]
    }

    // The component aliases are the keys, so populate them
    // Try to find some masked raw data
    each(componentSet, function(rawComponentName) {
      localData.serverAllowance[rawComponentName] = true
      if (componentAliases[rawComponentName]) {
        localData.serverAllowance[componentAliases[rawComponentName]] = true
      }
    })
  }

  function calculateFinalComponentAllowanceMatrix() {
    // At this point we can intake the server overrides
    // Priority: locale file system override | provider boot time force | serverOverride | localOverride | default

    localData.optionalComponentAllowanceMatrix = $.extend(
      {},
      localData.optionalComponentAllowanceMatrix,
      localData.serverAllowance,
      ConfigProvider.SETTINGS.COMPONENT.ALLOWANCE
    )
  }

  function setComponentSelectionConfig() {
    localData.componentSelection.config = ConfigProvider.SETTINGS.COMPONENT.SELECTION
    localData.componentSelection.choices = extend({}, appComponentSelectionChoices)
  }

  function isCurrentDeviceMobileOrTablet() {
    return false
  }
}
