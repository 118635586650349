import { pick, values } from 'lodash'

export const SUBSCRIPTION_CHANNELS = {
  HW_INFO_STATE: 'raw.hw_info.state',
  SOUND_STATE: 'raw.sound.state',
  PICKUP_STATE: 'service.pickup.state',
  BARCODE_READ: 'service.barcode.read',
  SYSTEM: 'system'
}

export const PUBLISHER_CHANNELS = {
  HEARTBEAT: 'service.heartbeat',
  FINDBOX_SET: 'service.esl.findbox.set',
  LED_SET: 'service.led.set',
  HW_INFO_GET: 'raw.hw_info.get',
  SOUND_PLAY: 'raw.sound.play',
  SOUND_STOP: 'raw.sound.stop',
  SOUND_PAUSE: 'raw.sound.pause',
  SOUND_RESUME: 'raw.sound.resume',
  SOUND_SET_RAW: 'raw.sound.set',
  SOUND_SET_BY_ARTICLE_IDS: 'service.sound.set',
  SOUND_SET_VOLUME: 'raw.sound.set_volume',
  SOUND_GET_STATUS: 'raw.sound.get_status'
}

export const ALLOWED_REMOTE_PUBLISHER_CHANNELS = values(pick(PUBLISHER_CHANNELS, ['HW_INFO_GET', 'SOUND_GET_STATUS']))
