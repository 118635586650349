export default {
  LOG: {
    EVENT: 'anyLogEvent'
  },
  LOGIC: {
    ACTIVE_FILTER_LIST_CHANGED: 'activeFilterListChanged',
    ANY_USER_INTERACTION: 'AnyUserInteraction',
    APPLICATION_STATE_TRANSITION: 'ApplicationStateTransition',
    ESL_BLINK: 'blinkEsl',
    FACE_RECOGNITION_LEAVE_FACE: 'faceRecognitionLeaveFaceTriggered',
    FACE_RECOGNITION_NEW_FACE: 'faceRecognitionNewFaceTriggered',
    FILTER_RESET: 'filterReset',
    FILTER_SELECT: 'filterSelect',
    FILTERED_ITEM_GROUP_LIST_CHANGED: 'filteredItemGroupListChanged',
    FILTERED_PRODUCT_LIST_CHANGED: 'filteredProductListChanged',
    GLOBAL_INIT: 'globalInit',
    GLOBAL_INIT_TRIGGER: 'globalInitTrigger',
    GLOBAL_RESET: 'globalReset',
    GRACEFUL_SHUTDOWN: 'GracefulShutdown',
    LOCALIZATION_CHANGED: '$translateChangeStart',
    PINNED_LIST_CHANGED: 'pinnedListChanged',
    SELECTED_COMBINATION_CHANGED: 'onSelectedCombinationChanged',
    SELECTED_ITEMGROUP_CHANGED: 'onSelectedItemGroupChanged',
    SHOW_PICK_UP_NOTIFICATION: 'showPickUpNotification',
    STATE_CHANGE: '$onStateChangeStart',
    TURN_OFF_LEDS: 'turnOffLeds',
    TURN_ON_LED_BY_ARTICLE_IDS: 'turnOnLedByArticleIds',
    TURN_OFF_AUDIO_PORTS: 'turnOffAudioPorts',
    TURN_ON_AUDIO_PORT_BY_ARTICLE_IDS: 'turnOnAudioPortByArticleIds'
  },
  COMPONENT: {
    idleVideoPlayer: {
      START_VIDEO: 'startIdleVideo',
      CLOSE_VIDEO: 'closeIdleVideo',
      RESET_VIDEO: 'restartIdleVideo'
    }
  }
}
