import iconComponent from './feature-filter-icon.directive'
import taggedComponent from './feature-filter-tagged.directive'
import labeledComponent from './feature-filter-labeled.directive'

const module = angular.module('mpa.components.feature-filter', [])

module
  .directive('featureFilterIcon', iconComponent)
  .directive('featureFilterLabeled', taggedComponent)
  .directive('featureFilterTagged', labeledComponent)

export default module.name
