import controller from './swiper-item.controller'
import template from './swiper-item.template.html'

export default {
  bindings: {
    item: '<',
    pinned: '<',
    onItemSelect: '&',
    onItemPin: '&'
  },
  template,
  controller
}
