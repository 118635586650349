import controller from './out-of-stock-indicator.controller'
import template from './out-of-stock-indicator.html'

export default {
  bindings: {
    primerArticle: '<'
  },
  template,
  controller
}
