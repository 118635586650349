/* @ngInject */
export default function detailsMediaPageBootstrap($stateProvider, MPA_PAGES) {
  $stateProvider.state(MPA_PAGES.DETAILS_TARIFFS.name, {
    url: MPA_PAGES.DETAILS_TARIFFS.url,
    // custom props
    title: 'MENU_TARIFFS',
    weight: 3,
    visible: true,
    componentAllowanceId: 'tariffsExtension',
    // custom props end
    component: 'detailsTariffsPageComponent',
    resolve: {
      /* @ngInject */
      interaction: (businessLogService, itemGroup) =>
        businessLogService.generate({
          event: businessLogService.events.DETAILS_TARIFFS,
          data: {
            product: itemGroup.getUsageInfo()
          }
        })
    }
  })
}
