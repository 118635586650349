import { trimStart } from 'lodash'

/* @ngInject */
export default function countryArticleNumberFilter(ConfigProvider) {
  let articleNumberPrefixToHide = ''
  articleNumberPrefixToHide = ConfigProvider.getNodeSettings().article_number_prefix_to_hide

  return function(articleNumber) {
    let retVal = articleNumber
    if (!retVal) {
      retVal = ''
    } else {
      retVal = trimStart(articleNumber, articleNumberPrefixToHide)
    }
    return retVal
  }
}
