import { size, flow, map, keyBy } from 'lodash/fp'

/* @ngInject */
export default function ProductListService(Product) {
  let localData = {}

  const getProductById = productId => {
    let _product = null
    if (productId && localData.products[productId]) {
      _product = localData.products[productId]
    } else {
      console.warn('ProductListService :: getProductById: the product not found: %s', productId)
    }
    return _product
  }

  function init(rawProducts, an2pim) {
    console.groupCollapsed(`%c ProductListService :: initializing `, 'background: #000; color: orange;')
    // Init empty local data structure
    localData = {
      products: {}
    }

    localData.products = flow(
      map(productData => Product.build(productData)),
      keyBy('id')
    )(rawProducts)

    localData.an2pim = an2pim

    console.info('ProductListService :: initialized SUCCESSFULLY with %s products', size(localData.products))
    console.log('ProductListService', localData)

    console.groupEnd()
  }

  return {
    init,
    getProductById
  }
}
