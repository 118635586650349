/* @ngInject */
export default function disableContextmenu($window, ConfigProvider) {
  return {
    restrict: 'A',
    scope: {},
    link: function() {
      if (!ConfigProvider.SETTINGS.DEBUG_MODE) {
        $window.addEventListener('contextmenu', function(event) {
          event.preventDefault()
          event.stopPropagation()
          return false
        })
      }
    }
  }
}
