/* @ngInject */
export default function categorySelectorController($state, MPA_PAGES, businessLogService) {
  const ctrl = this

  ctrl.$onInit = () => {
    console.debug('categorySelectorController', ctrl)
  }

  const handleCategorySelect = category => {
    if (category.products.length) {
      businessLogService.generate({
        event: businessLogService.events.ACCESSORY_GROUP_SELECTION,
        data: { product: ctrl.itemGroup.getUsageInfo() },
        info: { name: category.id }
      })

      $state.go(MPA_PAGES.DETAILS_ACCESSORIES_ACCESSORY_SELECTOR_LIST.name, { categoryId: category.id })
    }
  }

  ctrl.controlFunctions = {
    handleCategorySelect
  }
}
