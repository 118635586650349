/* @ngInject */
export default function acClickOutside() {
  return {
    restrict: 'A',
    scope: {
      acClickOutside: '&acClickOutside'
    },
    link: function(scope, element) {
      $('body').bind('touchend click', function(e) {
        if ($(e.target).is(element) || $.contains(element[0], e.target)) return
        scope.acClickOutside()
      })
    }
  }
}
