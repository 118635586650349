import { hasIn, trimStart } from 'lodash'

/*
  Article is the representation of a customer product. There is ONE related (PIM) Product.
*/

/* @ngInject */
export default function ArticleFactory($location, ConfigProvider) {
  const localConfig = {
    operatorImageExtension: '.png',
    simLockFreeOperatorImage: `sim-lock-free`
  }

  const isSoldAlone = soldAlone => (soldAlone === undefined ? true : soldAlone)

  function Article(data, product) {
    // Basic props from data
    for (let i in data) {
      if (data.hasOwnProperty(i)) {
        this[i] = data[i]
      }
    }

    this.id = data.an
    this.product = product

    this.itemGroupId = null // Container item group reference. It will be set later!

    this.sold_alone = isSoldAlone(this.sold_alone)
  }

  // ~~~~~~~~~~~~~~
  // Prototype API
  // ~~~~~~~~~~~~~~
  Article.prototype.setItemGroupId = function(id) {
    this.itemGroupId = id
  }

  Article.prototype.getUsageInfo = function(includeProductInfo = false) {
    return {
      an: this.an,
      ean: this.ean,
      ...(includeProductInfo ? this.product.getUsageInfo() : {})
    }
  }

  Article.prototype.getIdentifiers = function() {
    const prefixToHide = ConfigProvider.getArticleNumberPrefixToHide()

    return {
      an: trimStart(this.an, prefixToHide),
      ean: `${this.ean}`
    }
  }

  Article.prototype.getFullImageUrl = function() {
    let img = ''
    if (hasIn(this, 'product.images[0].image_url')) {
      const base = ConfigProvider.SETTINGS.API.baseApiUrl || ''
      img = base + this.product.images[0].image_url
    } else {
      console.warn('Article.getFullImageUrl :: could not find url: ' + this.id)
    }
    return img
  }

  /**
   * NOTE: when the Article has operator property but is null, set sim-lock-free-operator as image
   * @returns {*}
   */
  Article.prototype.getOperatorImageUrl = function() {
    return this.hasOwnProperty('operator')
      ? `${ConfigProvider.getFrontendSettingByKey('OPERATOR_IMG_DIR')}${this.operator ||
          localConfig.simLockFreeOperatorImage}${localConfig.operatorImageExtension}`
      : null
  }

  // ~~~~~~~~~~~~~~
  // Builder
  // ~~~~~~~~~~~~~~
  Article.build = function(articleId, data) {
    let article = null
    // Just the combination related data is optional
    if (articleId && data) {
      article = new Article(articleId, data)
    } else {
      console.warn('ArticleFactory :: some data is missing from constructor %s!', articleId)
    }
    return article
  }

  /**
   * Return the constructor function
   */
  return Article
}
