/* @ngInject */
export default function breadcrumbController(
  $transitions,
  $state,
  $stateParams,
  MPA_PAGES,
  ArticleListService,
  breadcrumbService
) {
  const ctrl = this

  ctrl.$onInit = () => {
    ctrl.data = breadcrumbService.get()

    $transitions.onSuccess({}, () => {
      ctrl.data = breadcrumbService.get()
    })
  }

  const handleCategorySelectorSelect = () => {
    $state.go(MPA_PAGES.DETAILS_ACCESSORIES_CATEGORY_SELECTOR.name)
  }

  const handleCategorySelect = category => {
    console.log('handleCategorySelect', category)
    $state.go(MPA_PAGES.DETAILS_ACCESSORIES_ACCESSORY_SELECTOR_LIST.name, {
      categoryId: category.value
    })
  }

  const showCategory = () => $stateParams.categoryId || $stateParams.accessoryId
  const showCategories = showCategory
  const showAccessory = () => $stateParams.accessoryId

  ctrl.controlFunctions = {
    handleCategorySelectorSelect,
    handleCategorySelect,
    showCategories,
    showCategory,
    showAccessory
  }
}
