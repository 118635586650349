import controller from './details.controller'
import horizontalTemplate from './templates/details-layout.horizontal.html'
import verticalTemplate from './templates/details-layout.vertical.html'

/* @ngInject */
const template = displayService =>
  displayService.getTemplate({
    component: 'detailsLayout',
    horizontal: horizontalTemplate,
    vertical: verticalTemplate
  })

export default {
  bindings: {
    itemGroup: '<'
  },
  template,
  controller
}
