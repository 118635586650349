import accounting from 'accounting'

const priceFilter = (DataProvider, ConfigProvider, $translate) => {
  let currencyFormatIds = null
  let currentFormatConfig = null
  const nodeSettings = ConfigProvider.getNodeSettings()

  if (nodeSettings.app_settings && nodeSettings.app_settings.currency_format) {
    currencyFormatIds = nodeSettings.app_settings.currency_format

    const currentLang = $translate.use().toUpperCase()

    if (currentLang && currencyFormatIds && currencyFormatIds[currentLang]) {
      if (nodeSettings.app_settings.currency_format_config[currencyFormatIds[currentLang]]) {
        currentFormatConfig = nodeSettings.app_settings.currency_format_config[currencyFormatIds[currentLang]]
      }
    }
  }

  return number => {
    if (currentFormatConfig && isInt(number)) {
      // Integer
      const config = currentFormatConfig.int
      if (config) {
        const settings = {
          symbol: config.symbol, // default currency symbol is '$'
          format: config.format.replace('%v', '%v' + config.value_suffix), // controls output: %s = symbol, %v = value/number (can be object: see below)
          thousand: config.thousand_separator, // thousands separator
          precision: config.decimal_places || 0
        }
        number = accounting.formatMoney(number, settings)
      }
    } else if (currentFormatConfig && isFloat(number)) {
      // Float
      const config = currentFormatConfig.float
      if (config) {
        const settings = {
          symbol: config.symbol, // default currency symbol is '$'
          format: config.format.replace('%v', '%v' + config.value_suffix), // controls output: %s = symbol, %v = value/number (can be object: see below)
          decimal: config.decimal_separator, // decimal point separator
          thousand: config.thousand_separator, // thousands separator
          precision: config.decimal_places // decimal places
        }
        number = accounting.formatMoney(number, settings)
      }
    }

    return number
  }
}

const isInt = n => Number(n) === n && n % 1 === 0
const isFloat = n => n === Number(n) && n % 1 !== 0

export default priceFilter
