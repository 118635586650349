import { hasIn, get, lowerCase } from 'lodash'

/* @ngInject */
export default function TariffFactory(ConfigProvider, $translate) {
  function Tariff(data, key) {
    for (let i in data) {
      this[i] = data[i]
    }
    this.id = key
    this.hideFeatureDetailsDisplay = !!this.hide_attr_display
  }

  Tariff.prototype.getUsageInfo = function() {
    return {
      id: this.id
    }
  }

  /**
   * Calculates and returns operator image url for the tariff.
   * @returns {string}
   */
  Tariff.prototype.getOperatorImageUrl = function() {
    let _img = ''
    if (
      this.operator &&
      hasIn(ConfigProvider, 'SETTINGS.OPERATOR_IMG_DIR') &&
      hasIn(ConfigProvider, 'SETTINGS.OPERATOR_IMG_EXTENSION')
    ) {
      _img =
        ConfigProvider.SETTINGS.OPERATOR_IMG_DIR + this.operator + '.' + ConfigProvider.SETTINGS.OPERATOR_IMG_EXTENSION
    } else {
      console.debug(
        'Tariff.getOperatorImageUrl :: missing some data: ',
        this.operator.toLowerCase(),
        get(ConfigProvider, 'SETTINGS.OPERATOR_IMG_DIR'),
        ConfigProvider,
        'SETTINGS.OPERATOR_IMG_EXTENSION'
      )
    }
    return _img
  }

  Tariff.prototype.getName = function() {
    const lang = lowerCase($translate.use())
    return get(this, ['nameDictionary', lang], this.name)
  }

  Tariff.prototype.getDescription = function() {
    const lang = lowerCase($translate.use())
    return get(this, ['descriptionDictionary', lang], this.description)
  }

  // ~~~~~~~~~~~~~~
  // Builder
  // ~~~~~~~~~~~~~~
  Tariff.build = function(tariffObject, id) {
    let tariff = null
    if (tariffObject && id && angular.isObject(tariffObject)) {
      tariff = new Tariff(tariffObject, id)
    } else {
      console.warn('Tariff.build :: could not build new Tariff!')
    }
    return tariff
  }

  /**
   * Return the constructor function
   */
  return Tariff
}
