import controller from './product-filter-description.controller'
import template from './product-filter-description.template.html'

export default {
  bindings: {
    product: '<',
    showTitle: '@'
  },
  template,
  controller
}
