import controller from './item-group-selector.controller'
import template from './item-group-selector.template.html'

export default {
  bindings: {
    itemGroup: '<',
    enableSelect: '<'
  },
  controller,
  template
}
