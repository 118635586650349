import 'swiper/dist/css/swiper.min.css'
import 'reflex-grid/css/reflex.min.css'
import 'animate.css/animate.css'
import 'angularjs-slider/dist/rzslider.min.css'
import 'nouislider/distribute/nouislider.min.css'

import { get } from 'lodash'

import 'jquery'
import 'angular'
import 'angular-animate'
import 'angular-strap'
import 'angular-translate'
import 'angular-translate-handler-log'
import '@uirouter/angularjs'
import 'angular-sanitize'
import 'swiper'
import 'ng-fittext'
import 'angularjs-slider'
import 'nouislider'

import '../assets/font-awesome/fontawesome-all.min'

import towerClient from 'tower2/client'
import { SUBSCRIPTION_CHANNELS, PUBLISHER_CHANNELS } from './components/socket-comm/socket.comm.config'
import * as channels from './components/socket-comm/channels'

import appBaseConfig from './app.config'
import appRun from './app.run'
import commonsModule from './commons/commons.module'
import componentsModule from './components/components.module'
import businesslogicModule from './businesslogic/businesslogic.module'
import pagesModule from './pages/pages.module'

import initData, { getFrontendSettings } from './app.data'
import setDebugMode from './commons/services/logger'
import { role, ROLES } from './commons/services/identity'

console.time('APP BOOTSTRAP')

angular.module('mpa.vendors', [
  'ui.router',
  'ngAnimate',
  'ngSanitize',
  'ngFitText',
  'pascalprecht.translate',
  'mgcrea.ngStrap',
  'rzModule'
])

const module = angular.module('mpaApp', [
  'mpa.vendors',
  businesslogicModule,
  commonsModule,
  componentsModule,
  pagesModule
])

module.config(appBaseConfig).run(appRun)

const connectToTower = () =>
  towerClient.create({
    path: '/tower',
    log: console,
    channels
  })

const subscribeToChannels = (ws, ecosystem) => {
  if (ws.readyState === 1) {
    ecosystem.send({
      command: 'subscribe',
      payload: {
        channels: Object.values(SUBSCRIPTION_CHANNELS)
      }
    })
  }
}

async function bootstrap() {
  let ws = await connectToTower()

  if (typeof window.COD !== 'object' && !window.localStorage.getItem('datasourceUrl')) {
    subscribeToChannels(ws, channels.ecosystem)
    setInterval(async () => {
      if (role === ROLES.MAIN && ENVIRONMENT === 'production') {
        channels.ecosystem.send({
          command: 'publish',
          payload: {
            channel: PUBLISHER_CHANNELS.HEARTBEAT,
            message: {}
          }
        })
      }
      if (ws.readyState === 3) {
        ws = await connectToTower()
        subscribeToChannels(ws, channels.ecosystem)
      }
    }, 5000)
  }

  await initData()
  setDebugMode(getFrontendSettings().DEBUG_MODE)
  angular.bootstrap(document, [module.name])
  console.timeEnd('APP BOOTSTRAP')

  if (typeof window.COD === 'object') {
    // codData defaults
    window.codData = {
      findbox: {
        apiBaseUrl: '',
        username: '',
        password: ''
      }
    }

    // codData from getContextData
    try {
      const codContextData = JSON.parse(window.COD.getContextData())
      window.codData.findbox.apiBaseUrl = get(codContextData, 'eslApiBaseUrl', '')
      window.codData.findbox.username = get(codContextData, 'eslUsername', '')
      window.codData.findbox.password = get(codContextData, 'eslPassword', '')
    } catch {}

    // reload on datasource change
    window.CODDataSourceChangeCallback = function() {
      setTimeout(function() {
        window.location.href = '/'
      }, 1000)
    }

    // reload on context data change
    window.CODContextDataChangeCallback = function() {
      setTimeout(function() {
        window.location.href = '/'
      }, 1000)
    }

    // event based reload
    window.addEventListener("message", function(event){
      if (event.data.type == 'CODContextDataChange' || event.data.type == 'CODDataSourceChange') {
        setTimeout(function() {
          window.location.href = '/'
        }, 1000)
      }
    })
  }
}

angular.element(() => {
  bootstrap()
})
