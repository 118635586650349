import { get } from 'lodash'
/* @ngInject */
export default function displayService($window, ConfigProvider) {
  const layoutModes = {
    horizontal: 'horizontal',
    vertical: 'vertical',
    custom: ConfigProvider.getFrontendSettingByKey('LAYOUT')
  }
  const orientation = determinateOrientation()
  console.info('displayService :: orientation: %s %j', orientation, {
    width: $window.screen.width,
    height: $window.screen.height
  })

  function determinateOrientation() {
    return $window.screen.width > $window.screen.height ? layoutModes.horizontal : layoutModes.vertical
  }

  const getTemplate = layouts => {
    if (!layouts.component) {
      console.warn('displayService :: getTemplate: you should provide the component name for debug purposes!')
    }
    let template
    let selectedLayout = layoutModes.horizontal

    if (orientation === layoutModes.vertical && get(layouts, layoutModes.vertical)) {
      selectedLayout = layoutModes.vertical
    }
    if (get(layouts, layoutModes.custom)) selectedLayout = layoutModes.custom

    template = layouts[selectedLayout]
    if (!template) {
      console.warn(
        'displayService :: getTemplate: no [%s] template for component [%s]',
        selectedLayout,
        layouts.component
      )
    }

    return template
  }

  const isHorizontal = () => orientation === layoutModes.horizontal
  const loadSkin = () => {
    const skinName = ConfigProvider.getFrontendSettingByKey('SKIN')
    if (ENVIRONMENT === 'production') {
      $('head').append($('<link rel="stylesheet" type="text/css" />').attr('href', `./${skinName}.${COMMON_HASH}.css`))
    }
  }

  return {
    getTemplate,
    isHorizontal,
    loadSkin
  }
}
