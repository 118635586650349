/* @ngInject */
export default function selectorPageBootstrap($stateProvider, MPA_PAGES) {
  $stateProvider.state(MPA_PAGES.GROUP_SELECTOR_LIST.name, {
    url: MPA_PAGES.GROUP_SELECTOR_LIST.url,
    component: 'groupSelectorPageComponent',
    resolve: {
      /* @ngInject */
      flow: (ConfigProvider, navigationService, groupSelectorService, $timeout) => {
        const primaryGroups = ConfigProvider.getPrimaryProductGroupConfig()
        const defaultPrimaryGroup = ConfigProvider.getDefaultPrimaryProductGroup()
        let shouldRedirect = false

        if (primaryGroups.length < 2) {
          shouldRedirect = true
          $timeout(() => {
            navigationService.goToSelectorPage(primaryGroups[0].id)
          })
          console.warn('GROUP SELECTOR state: less than 2 product groups in the application, redirect to selector page')
        } else if (defaultPrimaryGroup.computed.value && groupSelectorService.isFirstRun()) {
          shouldRedirect = true
          $timeout(() => {
            navigationService.goToSelectorPage(defaultPrimaryGroup.computed.value)
          })
          console.warn(
            `GROUP SELECTOR state: defaul primary group is set to '%s', redirect to selector page`,
            defaultPrimaryGroup.computed
          )
        }
        return Promise.resolve({ shouldRedirect })
      },
      /* @ngInject */
      services: (ComponentMatrixProvider, pinnedList) => {
        ComponentMatrixProvider.init()
        pinnedList.clear()
      },
      /* @ngInject */
      productGroups: ConfigProvider => ConfigProvider.getPrimaryProductGroupConfig()
    }
  })
}
