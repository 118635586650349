import { first, find } from 'lodash'

export default function groupSelectorService(ConfigProvider) {
  let localData = {}
  const init = () => {
    localData = {
      firstRun: true,
      groups: ConfigProvider.getPrimaryProductGroupConfig(),
      active: null,
      highlightedGroup: null,
      updating: true
    }
  }
  const setActiveProductGroup = group => {
    localData.active = group
  }
  const getActiveProductGroup = () => localData.active
  const getDefaultProductGroup = () => {
    localData.active = first(localData.groups).id
    return localData.active
  }

  const setHighlightedGroup = item => {
    localData.highlightedGroup = item
  }
  const getProductGroupData = id => (id ? find(localData.groups, { id }) : localData.groups)
  const getHighlightedGroup = () => localData.highlightedGroup
  const isFirstRun = () => {
    const current = localData.firstRun
    localData.firstRun = false
    return current
  }

  const isUpdating = updating => {
    if (updating !== undefined) {
      localData.updating = updating
    }
    return localData.updating
  }

  return {
    init,
    setHighlightedGroup,
    getHighlightedGroup,
    setActiveProductGroup,
    getActiveProductGroup,
    getProductGroupData,
    getDefaultProductGroup,
    isFirstRun,
    isUpdating
  }
}
