import { each, size, round, map } from 'lodash'

/* @ngInject */
export default function selectorPageBootstrap($stateProvider, MPA_PAGES, $translateProvider) {
  $stateProvider
    .state(MPA_PAGES.SELECTOR.name, {
      abstract: true,
      url: MPA_PAGES.SELECTOR.url,
      template: '<ui-view class="grid" />',
      resolve: {
        /* @ngInject */
        selectedProductGroup: ($stateParams, groupSelectorService) => {
          const group = $stateParams.groupId
          groupSelectorService.setActiveProductGroup(group)
          return group
        },
        /* @ngInject */
        data: (DataProvider, ConfigProvider, selectedProductGroup) => DataProvider.load(selectedProductGroup),
        /* @ngInject */
        productGroup: (groupSelectorService, selectedProductGroup) =>
          selectedProductGroup || groupSelectorService.getDefaultProductGroup(),
        /* @ngInject */
        allowances: (data, DataProvider, ComponentMatrixProvider) => {
          const accessoryExtension =
            ComponentMatrixProvider.isComponentAllowed('accessoriesExtension') &&
            Object.keys(DataProvider.getSecondaryProducts()).length !== 0
          const tariffsExtension =
            ComponentMatrixProvider.isComponentAllowed('tariffsExtension') &&
            Object.keys(DataProvider.getTariffCombinations()).length !== 0

          ComponentMatrixProvider.setComponentAllowance('accessoriesExtension', accessoryExtension)
          ComponentMatrixProvider.setComponentAllowance('tariffsExtension', tariffsExtension)
        },
        /* @ngInject */
        services: (
          ArticleListService,
          BusinessLogic,
          CombinationListService,
          ComponentMatrixProvider,
          ConfigProvider,
          data,
          DataProvider,
          ItemGroupListService,
          ItemGroupFilteredListService,
          TariffListService,
          productDetailsTranslateService,
          ProductFilterService,
          productGroup,
          ProductGroupService,
          ProductListService
        ) => {
          ProductFilterService.init(
            ConfigProvider.getProductGroupFilterConfig(productGroup),
            DataProvider.getFilters(),
            DataProvider.getPrimaryArticles()
          )
          ProductListService.init(DataProvider.getProducts(), DataProvider.getAn2Pim())
          TariffListService.init(DataProvider.getTariffs())
          ArticleListService.init(DataProvider.getArticles())
          CombinationListService.init(DataProvider.getTariffCombinations(), DataProvider.getArticles())
          ItemGroupListService.init(DataProvider.getItemGroupIdToArticleIdMap())
          ItemGroupFilteredListService.init()
          BusinessLogic.setInitialData(DataProvider.getProducts(), DataProvider.getTariffs())
          ProductGroupService.init(DataProvider.getProductGroupsData())
          productDetailsTranslateService.init(DataProvider.getPimDictionary())
        },
        /* @ngInject */
        translations: (data, services, DataProvider, productGroup) => {
          const dictionaries = DataProvider.getAppDictionary(productGroup)
          each(dictionaries, (val, key) => {
            $translateProvider.translations(key, val)
          })
        },
        /* @ngInject */
        itemGroupData: (data, translations, ItemGroupFilteredListService) =>
          ItemGroupFilteredListService.getServiceData(),
        /* @ngInject */
        interaction: (data, ItemGroupListService, productGroup, businessLogService, ConfigProvider, DataProvider) => {
          const articleFiltering = ConfigProvider.isArticlePreFilteringEnabledAndProvided()

          businessLogService.generate({
            event: businessLogService.events.PRODUCT_GROUP_LOADED,
            info: {
              'num-products': size(DataProvider.getProducts()),
              'num-articles': size(DataProvider.getArticles()),
              'num-articles-primary': size(DataProvider.getPrimaryArticles()),
              'num-articles-secondary': size(DataProvider.getSecondaryArticles()),
              'mode-entity-grouping': ConfigProvider.getFrontendSettingByKey('MULTI_ARTICLE_ITEM_GROUP')
                ? 'joined'
                : 'simple',
              'num-available-entities': size(ItemGroupListService.getItemGroups())
            },
            cntbl: true
          })

          if (articleFiltering) {
            const mapAns = ans => map(ans, an => ({ an }))
            const numMappingSlots = ConfigProvider.getNumberOfPorts()
            const numArticleMappings = size(ConfigProvider.getConfiguredArticles())
            const numUnresolvedArticles = size(DataProvider.getUnresolvedArticles())
            const numResolvedArticles = size(DataProvider.getResolvedArticles())
            const configuredArticles = mapAns(ConfigProvider.getConfiguredArticles())

            businessLogService.generate({
              event: businessLogService.events.PRODUCT_GROUP_LOADED,
              info: {
                'num-mapping-slots': numMappingSlots,
                'rate-mapping-occupancy-set': round(numArticleMappings / numMappingSlots, 3),
                'rate-mapping-occupancy-validated': round(numResolvedArticles / numMappingSlots, 3)
              },
              cntbl: false
            })

            businessLogService.generate({
              event: businessLogService.events.PRODUCT_GROUP_LOADED,
              data: { product: configuredArticles },
              info: {
                'num-article-mappings': numArticleMappings
              },
              cntbl: false
            })

            businessLogService.generate({
              event: businessLogService.events.PRODUCT_GROUP_LOADED,
              data: { product: mapAns(DataProvider.getUnresolvedArticles()) },
              info: {
                'num-unresolved-article-mappings': numUnresolvedArticles
              },
              cntbl: false
            })
          }
        }
      }
    })
    .state(MPA_PAGES.SELECTOR_LIST.name, {
      url: MPA_PAGES.SELECTOR_LIST.url,
      component: 'selectorPageComponent'
    })
}
