import { each, union, intersection, every, groupBy, get } from 'lodash'
import { PUBLISHER_CHANNELS, SUBSCRIPTION_CHANNELS } from '../components/socket-comm/socket.comm.config'

/* @ngInject */
export default function BusinessLogic(
  $rootScope,
  $transitions,
  $timeout,
  $window,
  $injector,
  $stateParams,
  DataProvider,
  stateEngineService,
  EVENT_KEYS,
  ConfigProvider,
  ProductFilterService,
  ItemGroupListService,
  ledService,
  MPA_PAGES,
  pinnedList,
  navigationService,
  ArticleListService,
  eslService,
  CombinationListService,
  $state,
  $document,
  displayService,
  businessLogService,
  SessionService,
  socketChannelEcosystemService
) {
  // Factory
  // NOTE: preload-registry should by used...
  // TODO: not inject ledservice here, but enforce loading somewhere else
  // TODO: not inject eslService here, but enforce loading somewhere else
  // Local structures
  let localData = initLocalData()
  let localConfig = initLocalConfig()

  // Make sure DataProvider is loaded
  function init(products, tariffs) {
    // All tariff and product Id list to store
    localData.stateEngine
      .setStates(localConfig.appStates)
      .setEvents(localConfig.appEvents)
      .setConfig('auto-broadcast', true)
      .setConfig('verbose-log', false)
      .setConfig('application-state-transition-event-name', EVENT_KEYS.LOGIC.APPLICATION_STATE_TRANSITION)
      .setAsyncAutoTransition(ConfigProvider.SETTINGS.GLOBAL_RESET_TIMEOUT, 'init', triggerGlobalResetTimeout)
      .addTransition('init', '*', 'touched')
      .addTransition('init', 'AnyUserInteraction', 'touched', 'init2touched')
      .start()

    Object.keys(localConfig.ecosystemChannelEventHandlers).forEach(key => {
      $rootScope.$on(key, localConfig.ecosystemChannelEventHandlers[key])
    })

    // Optionally start timing right now
    if (!ConfigProvider.SETTINGS.RESTART_COUNTDOWN_START_ONLY_IF_USER_STARTED) {
      localData.stateEngine.startAsyncAutoTransition()
    }
  }

  // Broadcast event handlers
  $rootScope.$on(EVENT_KEYS.LOGIC.ANY_USER_INTERACTION, userInteractionDetected)
  $rootScope.$on(EVENT_KEYS.LOGIC.ACTIVE_FILTER_LIST_CHANGED, productActiveFilterListChangeHandler)
  $rootScope.$on(EVENT_KEYS.LOGIC.FILTERED_ITEM_GROUP_LIST_CHANGED, filteredItemGroupListChangeHandler)
  $rootScope.$on('ApplicationStateTransition', (event, transition) => {
    console.log('ApplicationStateTransition', transition)
  })
  $rootScope.$on(SUBSCRIPTION_CHANNELS.SYSTEM, (e, data) => {
    if (data === 'data_updated' && getStateInformation().current === 'init') {
      console.info('System data updated, app restart requested')
      SessionService.appSessionStop(SessionService.reasons.dataUpdate)
      navigationService.hardReset()
    }
  })
  // The ui router state change handler
  $transitions.onEnter({}, stateLocationChangeHandler)

  // ~~~~~~~~~~~~~~
  // Public API
  // ~~~~~~~~~~~~~~
  return {
    init,
    setInitialData,
    getServiceData,
    getStateInformation,
    userInteractionDetected
  }

  function getServiceData() {
    return localData
  }

  function setInitialData(productData, tariffData) {
    localData.productIdList = Object.keys(productData)
    localData.tariffIdList = Object.keys(tariffData)
  }

  function getStateInformation() {
    return localData.stateEngine
  }

  // ~~~~~~~~~~~~~~
  // Private API
  // ~~~~~~~~~~~~~~
  function initLocalData() {
    return {
      stateEngine: stateEngineService.build(),
      started: false
    }
  }

  function initLocalConfig() {
    return {
      appStates: ['init', 'touched'],
      appEvents: ['*', 'AnyUserInteraction'],
      // Fires when the state is set
      appEventCallbackNet: {},
      ecosystemChannelEventHandlers: {
        [SUBSCRIPTION_CHANNELS.PICKUP_STATE]: pickUpStateHandler,
        'PROCESSED.FACE_RECOGNITION.NEW_FACE': faceRecognitionNewFaceHandler,
        'PROCESSED.FACE_RECOGNITION.FACE_LEAVE': faceRecognitionLeaveFaceHandler
      }
    }
  }

  /**
   * Handle when any type of interaction detected.
   */
  function userInteractionDetected(e) {
    // Log first move as user session start
    if (!localData.started) {
      SessionService.userSessionStart()
      localData.started = true
    }
    localData.stateEngine.evalTransitionEvent('AnyUserInteraction')
    localData.stateEngine.startAsyncAutoTransition()
  }

  /**
   * Fires a global init event.
   */
  function triggerGlobalResetTimeout() {
    // Log if manual session ended
    if (localData.started) {
      localData.started = false
      SessionService.appSessionStop(SessionService.reasons.timeout)
    }
    // Before reload log the event
    if (ConfigProvider.SETTINGS.RELOAD_ON_TIMEOUT) {
      // Signal that will be a forced reload and wait some
      $rootScope.$broadcast(EVENT_KEYS.LOGIC.GRACEFUL_SHUTDOWN)
      $timeout(function() {
        // we gotta reload the application and wipe any route to home
        navigationService.hardReset()
      }, 1000)
    } else {
      $rootScope.$broadcast(EVENT_KEYS.LOGIC.GLOBAL_INIT_TRIGGER)
    }
  }

  /**
   * Event handler for filtered item group list changes
   * @param $event
   * @param itemGroupIds
   */
  function filteredItemGroupListChangeHandler($event, itemGroupIds) {
    let activeFeatures = ProductFilterService.getActiveFeatures()
    let selectedProperties = ProductFilterService.getActiveProperties()
    if (!(activeFeatures.length === 0 && selectedProperties.length === 0)) {
      let articleIds = []
      each(itemGroupIds, function(itemGroupId) {
        articleIds = union(articleIds, getArticleIdsFromItemGroupById(itemGroupId))
      })

      $rootScope.$broadcast(EVENT_KEYS.LOGIC.TURN_ON_LED_BY_ARTICLE_IDS, articleIds) // ok
    }
  }

  /**
   * Event handler for product list related changes.
   */
  function productActiveFilterListChangeHandler() {
    // Gather data
    let allProductIdList = localData.productIdList
    let activeFeatures = ProductFilterService.getActiveFeatures()
    let selectedProperties = ProductFilterService.getActiveProperties()
    let activeRangeSelectors = ProductFilterService.getActiveRangeSelectors()
    // Calculate subsets
    let productIdsByActiveFeatures = calculateFilteredProductIdsByActiveFeatures(activeFeatures, allProductIdList)
    let productIdsBySelectedProperties = calculateFilteredProductIdsByActiveFeatures(
      selectedProperties,
      allProductIdList
    )
    let productIdsByActiveRangeSelectors = calculateFilteredProductIdsByActiveRangeSelectors(
      activeRangeSelectors,
      allProductIdList
    )
    // Calculate and broadcast ids to show
    let filteredProductIds = intersection(
      productIdsByActiveFeatures,
      productIdsBySelectedProperties,
      productIdsByActiveRangeSelectors,
      allProductIdList
    )
    $rootScope.$broadcast(EVENT_KEYS.LOGIC.FILTERED_PRODUCT_LIST_CHANGED, filteredProductIds)
    // Adjust the leds
    if (activeFeatures.length === 0 && selectedProperties.length === 0) {
      // Handle if no active filter
      $rootScope.$broadcast(EVENT_KEYS.LOGIC.TURN_OFF_LEDS)
    }
  }

  /**
   * Filter the articles! with the active range selectors.
   * NOTE: currently working only on articles, not products.
   * NOTE: articles can have parent item selectors (that can contain product object, so in the future
   * it is possible to continue range product selectors here, but with different fieldToFilterOn signature)
   * @param activeRangeSelectors
   * @param fullProductIdList
   * @returns {*}
   */
  function calculateFilteredProductIdsByActiveRangeSelectors(activeRangeSelectors, fullProductIdList) {
    let _resultIdList = fullProductIdList
    if (activeRangeSelectors.length) {
      let articles = ArticleListService.getArticles()
      let articleIdToProductIdMap = DataProvider.getArticleIdToProductIdMap()
      _resultIdList = _(articles)
        .filter(article =>
          every(
            activeRangeSelectors,
            selector =>
              article[selector.fieldToFilterOn] >= selector.from && article[selector.fieldToFilterOn] <= selector.to
          )
        )
        .map(article => articleIdToProductIdMap[article.id])
        .uniq()
        .value()
    }
    return _resultIdList
  }

  function calculateFilteredProductIdsByActiveFeatures(activeFeatures, fullProductIdList) {
    let resultIdList = fullProductIdList
    const filterGroups = groupBy(activeFeatures, property => property.groupName)
    each(filterGroups, filterGroupFilters => {
      let _actResultIdList = []
      each(filterGroupFilters, filter => {
        _actResultIdList =
          filter.groupOperator === 'OR'
            ? union(_actResultIdList, filter.productIdList)
            : (resultIdList = intersection(resultIdList, filter.productIdList))
      })
      resultIdList = intersection(resultIdList, _actResultIdList)
    })
    return resultIdList
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // SSE event handler functions
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  function faceRecognitionNewFaceHandler(event, eventData) {
    $rootScope.$broadcast(EVENT_KEYS.LOGIC.FACE_RECOGNITION_NEW_FACE, eventData)
  }

  function faceRecognitionLeaveFaceHandler(event, eventData) {
    $rootScope.$broadcast(EVENT_KEYS.LOGIC.FACE_RECOGNITION_LEAVE_FACE, eventData)
  }

  function pickUpStateHandler(event, eventData) {
    if (eventData) {
      console.debug('pickUpStateHandler :: called with %j', eventData)

      const addedIds = intersection(eventData.addedIds, DataProvider.getResolvedArticles())
      const activeIds = intersection(eventData.activeIds, DataProvider.getResolvedArticles())

      if (activeIds.length) {
        userInteractionDetected()
      }

      socketChannelEcosystemService.emit('publish', {
        channel: PUBLISHER_CHANNELS.LED_SET,
        message: {
          ids: activeIds
        }
      })

      addedIds.forEach(id => {
        const article = ArticleListService.getArticleById(id)
        businessLogService.generate({
          event: businessLogService.events.PICK_UP,
          data: { product: article.getUsageInfo() }
        })
      })

      if (ConfigProvider.getFrontendSettingByKey('PICKUP_COMPARE_MODE')) {
        const activeItemGroups = activeIds.map(id => get(ArticleListService.getArticleById(id), 'itemGroupId'))
        console.warn('pickUpStateHandler :: PICKUP_COMPARE_MODE :: activeItemGroups: %j', activeItemGroups)
        if (activeItemGroups.length === 0) {
          console.warn(
            'pickUpStateHandler :: PICKUP_COMPARE_MODE: itemGroup list is empty (maybe incorrect pickup config)!'
          )
        }

        if (activeItemGroups.length === 1) {
          pinnedList.clear()
          navigationService.goToDetailsPage($stateParams.groupId, activeItemGroups[0])
        }
        const key = displayService.isHorizontal()
          ? 'MAX_PINNED_ITEMS_TO_COMPARE_HORIZONTAL'
          : 'MAX_PINNED_ITEMS_TO_COMPARE_VERTICAL'
        if (activeItemGroups.length > 1 && activeItemGroups.length <= ConfigProvider.getFrontendSettingByKey(key)) {
          pinnedList.clear()
          pinnedList.addRange(activeItemGroups)
          if ($state.current.name !== MPA_PAGES.COMPARISON.name) {
            navigationService.goToComparisonPage()
          }
        }
      } else {
        const article = ArticleListService.getArticleById(addedIds[0])

        if (article) {
          if (localData.stateEngine.current === 'init') {
            navigationService.goToDetailsPage($stateParams.groupId, article.itemGroupId)
          } else if (localData.stateEngine.current === 'touched') {
            $rootScope.$broadcast(EVENT_KEYS.LOGIC.SHOW_PICK_UP_NOTIFICATION, article.id)
          }
        }
      }
    }
  }

  function stateLocationChangeHandler(transition, state) {
    if (state.name === MPA_PAGES.SELECTOR_LIST.name) {
      productActiveFilterListChangeHandler()
      // Audio
      $rootScope.$broadcast(EVENT_KEYS.LOGIC.TURN_OFF_AUDIO_PORTS)
    } else if (state.name === MPA_PAGES.DETAILS.name) {
      var articleIds = getArticleIdsFromItemGroupById(transition.params().itemGroupId)
      // Leds
      $rootScope.$broadcast(EVENT_KEYS.LOGIC.TURN_ON_LED_BY_ARTICLE_IDS, articleIds)
      // Audio
      $rootScope.$broadcast(EVENT_KEYS.LOGIC.TURN_ON_AUDIO_PORT_BY_ARTICLE_IDS, articleIds)
    } else if (state.name === MPA_PAGES.COMPARISON.name) {
      // Audio
      $rootScope.$broadcast(EVENT_KEYS.LOGIC.TURN_OFF_AUDIO_PORTS)
    }
  }

  function getArticleIdsFromItemGroupById(itemGroupId) {
    return Object.keys(ItemGroupListService.getItemGroupById(itemGroupId).articles)
  }
}
