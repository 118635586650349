import { get as _get } from 'lodash'

/* @ngInject */
export default function combinationComparerService() {
  let combinations = []
  let selected = null

  const add = combination => {
    if (!combinations[0]) {
      combinations[0] = combination
    } else {
      combinations[1] = combination
    }
  }

  const get = () => combinations
  const isSelected = combination => combinations.map(combi => _get(combi, 'id')).includes(combination.id)
  const remove = combination => {
    const index = combinations.findIndex(combi => _get(combi, 'id') === combination.id)
    console.log('remove', index)
    combinations[index] = null
  }
  const reset = () => {
    combinations = []
    selected = null
  }

  const select = combination => {
    selected = combination
  }
  const getSelectedCombination = () => selected

  return {
    add,
    remove,
    get,
    reset,
    select,
    getSelectedCombination,
    isSelected
  }
}
