import accessoryDetails from './subpages/accessory-details'
import accessorySelector from './subpages/accessory-selector'
import accessoryService from './service'
import tagCloud from './components/tag-cloud/component'
import breadcrumb from './components/breadcrumb/breadcrumb.module'
import categorySelector from './subpages/category-selector'
import component from './component'
import config from './config'

const module = angular.module('mpa.pages.details.accessories', [
  accessoryDetails,
  accessorySelector,
  categorySelector,
  breadcrumb
])

module
  .component('accessoriesPageComponent', component)
  .component('tagCloud', tagCloud)
  .config(config)
  .factory('accessoryService', accessoryService)

export default module.name
