import { get } from 'lodash'
import defaultConfig from '../config/application.default.config.js'

let frontendSettings = {}
let nodeSettings = {}
let dataIndex = {}

const getDevEnvSettings = () => import('../config/application.development.config.js')
const getProdEnvSettings = () => import('../config/application.production.config.js')

export default async () => {
  let envSettings = await (ENVIRONMENT === 'production' ? getProdEnvSettings() : getDevEnvSettings())
  envSettings = envSettings.default

  if (typeof window.COD === 'object' || window.localStorage.getItem('datasourceUrl')) {
    let remoteSettingsUrl, dataIndexUrl
    if (typeof window.COD === 'object') {
      remoteSettingsUrl = '//datasource.local'
      dataIndexUrl = '//datasource.local'
    } else {
      remoteSettingsUrl = window.localStorage.getItem('datasourceUrl')
      dataIndexUrl = window.localStorage.getItem('datasourceUrl')
    }

    nodeSettings = await fetch(remoteSettingsUrl)
      .then(res => res.json())
      .then(data => data['get_node_settings'])
      .catch(err => {
        console.error(`App data: data fetch failed from ${remoteSettingsUrl}, error: ${err}`)
      })
    dataIndex = await fetch(dataIndexUrl)
      .then(res => res.json())
      .then(data => data['getdata_v3_index'])
      .catch(err => {
        console.error(`App data: data fetch failed from ${dataIndexUrl}, error: ${err}`)
      })
  } else {
    const remoteSettingsUrl = get(defaultConfig, 'API.baseApiUrl') + get(defaultConfig, 'API.nodeSettingsRelativeUrl')
    const dataIndexUrl = get(defaultConfig, 'API.baseApiUrl') + get(defaultConfig, 'API.dataIndexRelativeUrl')

    nodeSettings = await fetch(remoteSettingsUrl)
      .then(res => res.json())
      .catch(err => {
        console.error(`App data: data fetch failed from ${remoteSettingsUrl}, error: ${err}`)
      })
    dataIndex = await fetch(dataIndexUrl)
      .then(res => res.json())
      .catch(err => {
        console.error(`App data: data fetch failed from ${dataIndexUrl}, error: ${err}`)
      })
  }

  const forcedSettingsUrl = ENVIRONMENT === 'production' ? './' : '../config/'
  const forcedSettings = await fetch(forcedSettingsUrl + 'application.forced.config.json').then(res => res.json())

  const remoteFrontendSettings = get(nodeSettings, 'node_settings.frontend_settings')

  frontendSettings = $.extend(true, defaultConfig, envSettings, remoteFrontendSettings, forcedSettings)

  console.groupCollapsed(`%c App data :: initializing in [${ENVIRONMENT}] mode`, 'background: #000; color: orange;')
  console.log('App data :: envSettings', envSettings)
  console.log('App data :: nodeSettings', nodeSettings)
  console.log('App data :: forcedSettings', forcedSettings)
  console.log('App data :: data index', dataIndex)
  console.log('App data :: final application settings', frontendSettings)
  console.groupEnd()
}

export const getFrontendSettings = () => frontendSettings
export const getNodeSettings = () => nodeSettings
export const getDataIndex = () => dataIndex
