import MPA_COMPONENT_SELECTION from './component-selection.constants'
import EVENT_KEYS from './event-key.constants'
import MPA_PAGES from './pages-config.constants'
import PRELOAD_REGISTRY from './preload-registry.constants'
import MPA_CORE_URL_KEYS from './template-url.constants'

const module = angular.module('mpa.commons.constants', [])

module.constant('MPA_COMPONENT_SELECTION', MPA_COMPONENT_SELECTION)
module.constant('EVENT_KEYS', EVENT_KEYS)
module.constant('MPA_PAGES', MPA_PAGES)
module.constant('PRELOAD_REGISTRY', PRELOAD_REGISTRY)
module.constant('MPA_CORE_URL_KEYS', MPA_CORE_URL_KEYS)

export default module.name
