import { find, get, size, each } from 'lodash'
import { getProductByMatchingCategories, getArticlesByMatchingTags } from './utils'

/* @ngInject */
export default function DetailsAccessoriesService($rootScope, ConfigProvider, Product, ArticleListService, EVENT_KEYS) {
  let localData = {
    cache: {},
    accessories: [],
    categories: []
  }

  function init() {
    const accessories = ArticleListService.getSecondaryProducts()
    const config = ConfigProvider.getSecondaryProductGroupConfig()

    localData.accessories = each(accessories, accessory => (accessory.product = Product.build(accessory.product)))

    if (accessories && config) {
      localData.categories = config
      console.info(
        'accessoryService :: initialized SUCCESSFULLY with %s accessories in %s categories',
        size(localData.accessories),
        size(localData.categories)
      )
    } else {
      console.error('accessoryService :: no accessories data or secondary_product_groups config')
    }
  }

  const getCategoriesForItemGroup = itemGroup => {
    const productTags = get(itemGroup, 'product.tags', [])
    const matchingAccessories = getArticlesByMatchingTags(localData.accessories, productTags)
    const data = localData.categories.map(category => {
      const pimCategories = category.pim_categories.map(category => category.category_id)
      return {
        ...category,
        products: getProductByMatchingCategories(matchingAccessories, pimCategories)
      }
    })

    return data
  }

  const getAccessoryBySplitId = an => find(localData.accessories, { an })
  const highlightAccessory = ean => {
    $rootScope.$broadcast(EVENT_KEYS.LOGIC.ESL_BLINK, ean)
  }

  return {
    init,
    getCategoriesForItemGroup,
    getAccessoryBySplitId,
    highlightAccessory
  }
}
