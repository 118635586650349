/* @ngInject */
export default function DetailsPageController() {
  let ctrl = this

  ctrl.$onInit = initialize

  function initialize() {
    console.log('DetailsPageController: itemGroup', ctrl.itemGroup)
  }
}
