export default {
  ADMIN_URL: '/admin/',
  APP_NAME: 'mpa',
  CHECK_BACKEND_FOR_NEW_DATA_IN_THE_BACKGROUND_PERIOD: 30000,
  DEBUG_MODE: true,
  DYNAMIC_FILTER_IMG_DIR: '/media/manufacturer-logos/',
  DYNAMIC_FILTER_IMG_EXTENSION: 'png',
  DYNAMIC_FILTER_TEXT_INSTEAD_OF_IMG: false,
  FIX_FALLBACK_LANGUAGE: 'EN',
  GLOBAL_RESET_TIMEOUT: 120000,
  LAYOUT: '',
  MAX_PINNED_ITEMS_TO_COMPARE_HORIZONTAL: 5,
  MAX_PINNED_ITEMS_TO_COMPARE_VERTICAL: 2,
  MIN_PINNED_ITEMS_TO_COMPARE: 2,
  MULTI_ARTICLE_ITEM_GROUP: false,
  OPERATOR_IMG_DIR: '/media/operator-logos/',
  OPERATOR_IMG_EXTENSION: 'png',
  PICKUP_COMPARE_MODE: false,
  PRE_FILTER_BY_CONFIGURED_ARTICLES: false,
  PRE_FILTER_LIST_ALL_ON_EMPTY_PRODUCT_MAPPING: false,
  RELOAD_ON_TIMEOUT: true,
  RESTART_COUNTDOWN_START_ONLY_IF_USER_STARTED: true,
  SHOW_ONLY_EFFECTIVE_FILTERS: false,
  SHOW_PICK_UP_NAVIGATION_NOTIFICATION_TIMEOUT_MS: 10000,
  SKIN: 'aiss',
  COMPONENT: {
    ALLOWANCE: {
      accessoriesExtension: false,
      animationService: true,
      appTitle: false,
      audioExtension: false,
      audioLineIn: false,
      audioAllPorts: false,
      autoReloadOnBackendDataUpdate: true,
      cartExtension: false,
      checkoutExtension: false,
      detailsMenu: true,
      detailsSpecialsTab: false,
      detailsSpecificationsTab: true,
      displayPriceOnSelector: true,
      displayOperatorOnSelector: true,
      emailService: false,
      faceRecLayer: false,
      filterBrandLogo: false,
      filterDynamic: true,
      filterFeature: true,
      filterLifestyle: true,
      filterReset: true,
      findboxExtension: false,
      forcedCheckoutPage: false,
      idleVideoPlayer: false,
      mediaExtension: false,
      pickUpNotificationService: true,
      printerService: false,
      productGroupName: true,
      productGroupSelector: false,
      productSwiperAutoplay: false,
      qrService: false,
      rangeFilters: false,
      showFeatureVideos: true,
      tariffsExtension: false
    },
    SELECTION: {
      'feature-filter-list': 'default',
      'product-filter': 'default',
      'specs-feature-icon-list': 'default'
    },
    ALIAS: {
      pickup: 'pickUpNotificationService',
      qrcode: 'qrService'
    }
  },
  API: {
    baseApiUrl: '',
    dataRelativeUrl: '/backend/getdata_v3',
    dataIndexRelativeUrl: '/backend/getdata_v3_index',
    nodeSettingsRelativeUrl: '/backend/get_node_settings',
    nodeStatusRelativeUrl: '/backend/get_node_status'
  },
  CART: {
    apiUrl: '',
    apiTimeout: 15000,
    customerIdValidationRegex: '^.*?$'
  } /*,
  ESL: {
    type: 'findbox',
    item_attr_to_send: 'ean',
    findbox: {
       blinkPattern: [[2,'red'],[2,'red'],[2,'red']]
    }
  },
  PRIMARY_PRODUCT_GROUPS: {
    default_primary_product_group: 'mobilephones',
    user_set_primary_product_group: 'washers'
  } */
}
