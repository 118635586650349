import controller from './details-media.controller'
import template from './details-media.html'

export default {
  bindings: {
    itemGroup: '<'
  },
  template,
  controller
}
