import controller from './price-display.controller'
import template from './price-display.template.html'

export default {
  bindings: {
    value: '<',
    isRange: '<'
  },
  template,
  controller
}
