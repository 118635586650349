import { startsWith } from 'lodash'

/* @ngInject */
export default function DetailsPageController($state, ComponentMatrixProvider) {
  let ctrl = this
  ctrl.$onInit = initialize

  function initialize() {
    ctrl.data = {
      menuItems: []
    }

    if (ctrl.itemGroupId) {
      ctrl.data.menuItems = getChildStates($state)
    } else {
      console.warn('details-page: no itemGroupId param!')
    }
  }

  const isMenuItemVisible = s =>
    startsWith(s.name, 'mpa-app.groups.products.details.') &&
    s.visible &&
    ComponentMatrixProvider.isComponentAllowed(s.componentAllowanceId)
  const getChildStates = $state =>
    _($state.get())
      .filter(isMenuItemVisible)
      .sortBy('weight')
      .map(menuItem => ({
        title: menuItem.title,
        name: menuItem.name
      }))
      .value()
}
