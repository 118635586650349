import controller from './product-display.controller'
import template from './product-display.template.html'

export default {
  bindings: {
    itemGroup: '<',
    showTitle: '<',
    showPrice: '<',
    showManufacturerLogo: '<',
    showIdentifiers: '<',
    showFindboxButton: '<',
    crossedPrice: '<',
    showCart: '<',
    showOutOfStockIndicator: '<',
    showCheckout: '<',
    showButtons: '<',
    combination: '<'
  },
  template,
  controller
}
