import component from './component'
import config from './config'

import combinationListComponent from './components/combination-list/component'
import combinationDetails from './components/combination-details/combination-details.component'

const module = angular.module('mpa.pages.details.tariffs', [])

module
  .config(config)
  .component('detailsTariffsPageComponent', component)
  .component('combinationList', combinationListComponent)
  .component('combinationDetails', combinationDetails)

export default module.name
