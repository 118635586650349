import { get } from 'lodash'
import findboxModalTpl from './findbox-modal/findbox.modal.html'
import findboxModalCtrl from './findbox-modal/findbox.modal.controller'

/* @ngInject */
export default function findboxButtonController(
  $timeout,
  $modal,
  accessoryService,
  businessLogService,
  ConfigProvider
) {
  const ctrl = this
  ctrl.lightOn = false

  const eslBlinkAttr = get(ConfigProvider, 'SETTINGS.ESL.item_attr_to_send', 'ean')

  ctrl.handleLightUp = (event, item) => {
    let findboxModal = null
    let timeoutId = null
    if (!ctrl.lightOn) {
      let blinkId = item[eslBlinkAttr]
      accessoryService.highlightAccessory(blinkId)

      businessLogService.generate({
        event: businessLogService.events.ESL_BLINK,
        data: { product: ctrl.item.getUsageInfo() }
      })
      findboxModal = $modal({
        controller: findboxModalCtrl,
        controllerAs: '$ctrl',
        template: findboxModalTpl,
        onBeforeShow: () => {
          $('body').addClass('findbox-modal-opened')
        },
        onHide: () => {
          $('body').removeClass('findbox-modal-opened')
          ctrl.lightOn = false
          $timeout.cancel(timeoutId)
        }
      })
    }
    ctrl.lightOn = true
    timeoutId = $timeout(() => {
      ctrl.lightOn = false
      if (findboxModal) {
        findboxModal.hide()
      }
    }, 1000 * 5)
  }
}
