import template from './selector.html'
import controller from './selector.controller'

export default {
  bindings: {
    itemGroupData: '<'
  },
  template,
  controller
}
