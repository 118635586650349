/* @ngInject */
export default function checkoutPageConfig($stateProvider, MPA_PAGES) {
  $stateProvider.state(MPA_PAGES.CHECKOUT.name, {
    url: MPA_PAGES.CHECKOUT.url,
    component: 'checkoutPageComponent',
    resolve: {
      /* @ngInject */
      itemGroup: (ItemGroupListService, $stateParams) =>
        ItemGroupListService.getItemGroupById($stateParams.itemGroupId),
      /* @ngInject */
      combination: (combinationComparerService, itemGroup) =>
        combinationComparerService.getSelectedCombination() || itemGroup.getPrimerCombination(),
      /* @ngInject */
      notify: (combination, EVENT_KEYS, itemGroup, $rootScope) => {
        // send Esl
        if (combination) {
          $rootScope.$broadcast(EVENT_KEYS.LOGIC.ESL_BLINK, combination.article.ean)
        }
      }
    }
  })
}
