/* @ngInject */
export default function ProductOtfFilterFactory(ConfigProvider) {
  // ~~~~~~~~~~~~~~
  // Constructor with default object structure
  // ~~~~~~~~~~~~~~
  function ProductOtfFilter(featureId, fieldToFilterOn, label, from, to, possibleValuesQuantity) {
    this.id = featureId
    this.label = label
    this.fieldToFilterOn = fieldToFilterOn
    this.from = from
    this.fromRange = from
    this.to = to
    this.toRange = to
    this.possibleValuesQuantity = possibleValuesQuantity
    this.active = false
    this.type = 'selector'
  }

  // ~~~~~~~~~~~~~~
  // Prototype methods
  // ~~~~~~~~~~~~~~
  ProductOtfFilter.prototype.toggle = function() {
    this.active = !this.active
  }

  ProductOtfFilter.prototype.setInactive = function() {
    this.active = false
  }

  ProductOtfFilter.prototype.setActive = function() {
    this.active = true
  }

  ProductOtfFilter.prototype.getLabel = function() {
    return this.label
  }

  ProductOtfFilter.prototype.isType = function(type) {
    return this.type === type
  }

  ProductOtfFilter.prototype.reset = function() {
    this.from = this.fromRange
    this.to = this.toRange
    this.setInactive()
  }

  // ~~~~~~~~~~~~~~
  // Factory Public API
  // ~~~~~~~~~~~~~~

  // ~~~~~~~~~~~~~~
  // Private API
  // ~~~~~~~~~~~~~~

  // ~~~~~~~~~~~~~~
  // Builder
  // ~~~~~~~~~~~~~~
  /**
   * The factory's builder.
   * @param config
   * @returns {*}
   */
  ProductOtfFilter.build = function(config) {
    let newProductOtfFilter = null
    if (config) {
      newProductOtfFilter = new ProductOtfFilter(
        config.id,
        config.fieldToFilterOn,
        config.label,
        config.from,
        config.to,
        config.possibleValuesQuantity
      )
    }
    return newProductOtfFilter
  }

  // Return the constructor function
  return ProductOtfFilter
}
