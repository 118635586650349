import { filter, intersection } from 'lodash/fp'

import { get } from 'lodash'

export const getArticlesByMatchingTags = (articles, tags) =>
  filter(article => productMatchesByTag(article.product, tags))(articles)
export const getProductByMatchingCategories = (products, categories) =>
  filter(product => productMatchesByCategory(product, categories))(products)
export const productMatchesByTag = (product, tags) => intersection(get(product, 'tags', []), tags).length > 0
export const productMatchesByCategory = (product, categories) => categories.includes(get(product, '_pim.category_id'))
