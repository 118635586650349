/* @ngInject */
export default function selectorPageController(
  $compile,
  $rootScope,
  $scope,
  pinnedList,
  $timeout,
  $stateParams,
  displayService,
  EVENT_KEYS,
  ItemGroupFilteredListService,
  ConfigProvider,
  businessLogService,
  navigationService,
  combinationComparerService,
  selectorService,
  groupSelectorService
) {
  const ctrl = this
  let items = []
  let swiperConfig = {
    slidesPerView: displayService.isHorizontal() ? 5 : 3,
    autoplay: false,
    coverflowEffect: {
      rotate: 0,
      depth: 300,
      stretch: -100,
      slideShadows: false
    }
  }

  const filteredProductListChangeHandler = () => {
    ctrl.state.updating = true
    const data = ItemGroupFilteredListService.getServiceData()

    items.map(item => {
      item.remove()
    })
    items = []

    ctrl.itemGroupData = { ...data }
    if (!ctrl.itemGroupData.filteredItemGroups.length) {
      ctrl.state.highlightedItemGroup = null
    }

    $timeout(() => {
      ctrl.state.updating = false
    }, 0)
  }

  const handleSelectedItemGroup = ({ item }) => {
    businessLogService.generate({
      event: businessLogService.events.PRODUCT_SELECTION,
      data: { product: item.getUsageInfo() },
      info: { from: 'selector-page:product-selector' }
    })

    navigationService.goToDetailsPage($stateParams.groupId, item.id)
  }

  const handlePinnedItemGroup = ({ item }) => {
    pinnedList.toggle(item)
  }

  const renderSlideFunction = itemGroup => {
    const itemScope = $scope.$new(true)
    itemScope.item = itemGroup
    itemScope.handleItemSelect = handleSelectedItemGroup
    itemScope.handleItemPin = handlePinnedItemGroup
    itemScope.pinned = pinnedList.isIdPinned(itemGroup)
    const template = `<swiper-item 
                        class="swiper-slide"
                        item="item"
                        pinned="pinned"
                        on-item-select="handleItemSelect({item})"
                        on-item-pin="handleItemPin({item})">                      
                    </swiper-item>`
    const item = $compile(template)(itemScope)
    items = items.concat([item])
    return item
  }

  ctrl.$onInit = () => {
    ctrl.state = {
      updating: false,
      highlightedItemGroup: selectorService.getActiveItemGroup(),
      renderSlide: renderSlideFunction,
      swiperConfig
    }
    combinationComparerService.reset()
    $scope.$on(EVENT_KEYS.LOGIC.FILTERED_PRODUCT_LIST_CHANGED, filteredProductListChangeHandler)
    groupSelectorService.isFirstRun()
    groupSelectorService.isUpdating(false)
  }

  const handleHighlightedItemGroupChange = ({ item }) => {
    if (item) {
      ctrl.state.highlightedItemGroup = item
      selectorService.setActiveItemGroup(item)
    }
  }

  ctrl.controlFunctions = {
    handleHighlightedItemGroupChange
  }
}
