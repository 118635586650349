import constants from './constants/constants.module'
import directives from './directives/directives.module'
import filters from './filters/filters.module'
import services from './services/services.module'
import controllers from './controllers/controllers.module'
import repositories from './repositories/repositories.module'
import providers from './providers/providers.module'

const module = angular.module('mpa.commons', [
  constants,
  directives,
  filters,
  services,
  controllers,
  repositories,
  providers
])

export default module.name
