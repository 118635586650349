import { each } from 'lodash'

/* @ngInject */
export default function TariffListService(Tariff, $q) {
  let serviceDataPromise = $q.defer()
  let localData = {}

  // Service object
  return {
    init,
    getServiceData,
    getTariffs,
    isServiceReady,
    getTariffById
  }

  // ~~~~~~~~~~~~~~
  // Public API
  // ~~~~~~~~~~~~~~
  /**
   * Public full data getter
   */
  function getServiceData() {
    return localData
  }

  function getTariffs() {
    return localData.tariffs
  }

  /**
   * Callable promise responder for other services, chaining.
   */
  function isServiceReady() {
    return serviceDataPromise.promise
  }

  function getTariffById(tariffId) {
    let tariff = null
    if (tariffId && localData.tariffs[tariffId]) {
      tariff = localData.tariffs[tariffId]
    }
    return tariff
  }

  // ~~~~~~~~~~~~~~
  // Local API
  // ~~~~~~~~~~~~~~
  function initLocalData() {
    return {
      tariffs: {}
    }
  }

  /**
   * Self constructor, build up process
   */
  function init(rawTariffs) {
    console.groupCollapsed(`%c TariffListService :: initializing `, 'background: #000; color: orange;')
    // Init empty local data structure
    localData = initLocalData()
    let tariffs = {}
    each(rawTariffs, (tariffObject, key) => {
      tariffs[key] = Tariff.build(tariffObject, key)
    })
    localData.tariffs = tariffs
    serviceDataPromise.resolve(true)
    console.log('TariffListService', localData)
    console.groupEnd()
  }
}
