/* @ngInject */
export default function productDisplayController() {
  let ctrl = this

  ctrl.$onInit = () => {
    ctrl.showCheckout = ctrl.showCheckout === undefined ? true : ctrl.showCheckout
    ctrl.showButtons = ctrl.showButtons === undefined ? true : ctrl.showButtons
    ctrl.primerCombination = ctrl.itemGroup.getPrimerCombination()
    ctrl.primerArticle = ctrl.itemGroup.getPrimerArticle()
  }
}
