/* @ngInject */
export default function componentIf(ngIfDirective, ComponentMatrixProvider, $parse) {
  // The core angular if
  let ngIf = ngIfDirective[0]
  return {
    scope: {},
    transclude: ngIf.transclude,
    priority: ngIf.priority - 1,
    terminal: ngIf.terminal,
    restrict: ngIf.restrict,
    replace: false,
    link: function($scope, $element, $attr) {
      // The value is the component name
      let componentName = $attr['componentIf']
      $scope.allowed = false

      // Listeners to update
      ComponentMatrixProvider.isServiceReady().then(refreshAllowed)
      // TODO: use real event name or EVENT_KEYS
      $scope.$on('ComponentSetupReset', refreshAllowed)

      // We also can use one way binding :: here, because the parser affects the watcher-once
      // There we can name a scope letiable
      $attr.ngIf = $parse('allowed')
      ngIf.link.apply(ngIf, arguments)

      /**
       * Refresh watched value
       */
      function refreshAllowed() {
        $scope.allowed = ComponentMatrixProvider.isComponentAllowed(componentName)
      }
    }
  }
}
