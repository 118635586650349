import { get, size, map, filter, intersection, without } from 'lodash'
import {
  filterPim2An,
  mapIg2An,
  preFilterCombinations,
  filterProducts,
  filterFilters,
  getProductGroupCategories,
  getProductsByCategoryIds,
  getRawProducts,
  getProductGroupHierarchyConfig
} from './utils'

/* @ngInject */
export default function DataProvider($http, $q, ConfigProvider, groupSelectorService) {
  let serviceDataPromise = $q.defer()
  let localData = {}

  async function load(productGroup) {
    console.group(`%c DataProvider::load: product group: "${productGroup}"`, 'background: #000; color: orange;')
    console.time('DataProvider::load')

    try {
      let data
      if (typeof window.COD === 'object' || window.localStorage.getItem('datasourceUrl')) {
        let dataUrl
        if (typeof window.COD === 'object') {
          dataUrl = '//datasource.local'
        } else {
          dataUrl = window.localStorage.getItem('datasourceUrl')
        }
        console.info(`DataProvider::load: loading data from ${dataUrl}`)

        data = await getRemoteData(dataUrl)
        localData.rawRemoteData = data[`getdata_v3_${productGroup}`]
      } else {
        let dataUrl =
          get(ConfigProvider.SETTINGS, 'API.baseApiUrl') + get(ConfigProvider.SETTINGS, 'API.dataRelativeUrl')
        dataUrl = `${dataUrl}_${productGroup}`
        console.info(`DataProvider::load: loading data from ${dataUrl}`)

        data = await getRemoteData(dataUrl)
        localData.rawRemoteData = data
      }

      console.info(
        'DataProvider: Raw data: products: %s, combination articles: %s, tariffs: %s, filters: %s',
        size(data.pim_products),
        size(get(data, 'customer_product_sales_variants.tariff_combination_prices.product_tariff_combinations')),
        size(get(data, 'customer_product_sales_variants.tariff_combination_prices.tariffs')),
        size(data.filters)
      )
    } catch (err) {
      console.error('DataProvider::load ', err)
    }

    localData.processed = processRawData(localData.rawRemoteData, ConfigProvider.getNodeSettings(), productGroup)

    console.info(`DataProvider: Processed data: ${map(localData.processed, (v, k) => `\n  ${k}: ${size(v)}`)}`)
    console.log('localData', localData)
    console.timeEnd('DataProvider::load')
    console.groupEnd()

    serviceDataPromise.resolve()

    return localData
  }

  async function getRemoteData(url) {
    const { data } = await $http.get(url, { timeout: 30000 })
    return data
  }

  /**
   * Pre-process all data from raw to processed
   */
  function processRawData(raw, settings, productGroupId) {
    const processed = {}

    let articleIdsToKeep = Object.keys(raw.an2pim)
    if (ConfigProvider.isArticlePreFilteringEnabledAndProvided()) {
      articleIdsToKeep = ConfigProvider.getConfiguredArticles()
      articleIdsToKeep =
        articleIdsToKeep.length === 0 && get(ConfigProvider.SETTINGS, 'PRE_FILTER_LIST_ALL_ON_EMPTY_PRODUCT_MAPPING')
          ? Object.keys(raw.an2pim)
          : ConfigProvider.getConfiguredArticles()
    }

    processed.resolvedArticles = intersection(articleIdsToKeep, Object.keys(raw.an2pim))
    processed.unresolvedArticles = without(articleIdsToKeep, ...processed.resolvedArticles)
    console.info('articleIdsToKeep', articleIdsToKeep)
    console.info('resolvedArticles', processed.resolvedArticles)
    console.info('unresolvedArticles', processed.unresolvedArticles)

    const productIdsToKeep = map(articleIdsToKeep, article => raw.an2pim[article])

    const multiItemGroup = get(ConfigProvider.SETTINGS, 'MULTI_ARTICLE_ITEM_GROUP')
    const products = getRawProducts(raw)
    if (products.length === 0) console.log('no raw products found!')

    const productHierarchy = get(settings, 'product_group_hierarchy')
    if (productHierarchy) console.log('product hierarchy found:', settings.product_group_hierarchy)
    const activeProductGroupHierarchyConfig = getProductGroupHierarchyConfig(productHierarchy, productGroupId)
    console.log('activeProductGroupHierarchyConfig', activeProductGroupHierarchyConfig)
    const primaryProductCategoryIds = getProductGroupCategories(activeProductGroupHierarchyConfig.primary)
    const secondaryProductCategoryIds = getProductGroupCategories(activeProductGroupHierarchyConfig.secondary)

    console.log('primaryProductCategoryIds', primaryProductCategoryIds)
    console.log('secondaryProductCategoryIds', secondaryProductCategoryIds)

    processed.activeProductIds = productIdsToKeep
    processed.primary_products = productHierarchy
      ? filterProducts(getProductsByCategoryIds(products, primaryProductCategoryIds), productIdsToKeep)
      : filterProducts(products, productIdsToKeep)

    processed.an2pim = raw.an2pim
    processed.pim2an = filterPim2An(raw.pim2an, productIdsToKeep, articleIdsToKeep)

    processed.secondary_products = getProductsByCategoryIds(products, secondaryProductCategoryIds)
    processed.pig2an = mapIg2An(multiItemGroup, processed.pim2an, Object.keys(processed.primary_products), 'pig')
    processed.sig2an = mapIg2An(multiItemGroup, processed.pim2an, Object.keys(processed.secondary_products), 'sig')

    processed.tariffCombinations = preFilterCombinations(
      get(raw, 'customer_product_sales_variants.tariff_combination_prices.product_tariff_combinations', []),
      articleIdsToKeep
    )

    processed.products = products
    processed.articles = map(raw.customer_products, product => ({
      ...product,
      sold_alone: get(processed, ['tariffCombinations', product.an, 'sold_alone'])
    }))
    processed.primary_articles = filter(raw.customer_products, article => article['_role'].primary)
    processed.secondary_articles = filter(raw.customer_products, article => article['_role'].secondary)
    processed.tariffs = get(raw, 'customer_product_sales_variants.tariff_combination_prices.tariffs', [])
    processed.filters = filterFilters(get(raw, `filters.${productGroupId}`), productIdsToKeep)
    processed.pim_dictionary = raw.pim_dictionary
    processed.app_dictionary = raw.app_dictionary

    return processed
  }

  const getFromProcessed = (...args) => () => get(localData, ['processed', ...args])
  const getAppDictionary = groupName => getFromProcessed('app_dictionary', groupName)()
  const getArticleIdToProductIdMap = getFromProcessed('an2pim')
  const getArticles = getFromProcessed('articles')
  const getResolvedArticles = getFromProcessed('resolvedArticles')
  const getUnresolvedArticles = getFromProcessed('unresolvedArticles')
  const getPrimaryArticles = getFromProcessed('primary_articles')
  const getSecondaryArticles = getFromProcessed('secondary_articles')
  const getTariffCombinations = getFromProcessed('tariffCombinations')
  const getFilters = getFromProcessed('filters')
  const getItemGroupIdToArticleIdMap = getFromProcessed('pig2an')
  const getTariffs = getFromProcessed('tariffs')
  const getPimDictionary = getFromProcessed('pim_dictionary')
  const getProductFeaturesConfig = getFromProcessed('pim_dictionary', 'features')
  const getProductGroupsData = getFromProcessed('pim_dictionary', 'product_groups')
  const getProducts = getFromProcessed('products')
  const getPrimaryProducts = getFromProcessed('primary_products')
  const getSecondaryProducts = getFromProcessed('secondary_products')
  const getActiveProductIds = getFromProcessed('activeProductIds')
  const getPim2An = getFromProcessed('pim2an')
  const getAn2Pim = getFromProcessed('an2pim')
  const isServiceReady = () => serviceDataPromise.promise

  const getManufacturerById = id => get(localData, ['processed', 'pim_dictionary', 'manufacturers', id])

  return {
    getActiveProductIds,
    getAn2Pim,
    getAppDictionary,
    getArticleIdToProductIdMap,
    getArticles,
    getFilters,
    getItemGroupIdToArticleIdMap,
    getManufacturerById,
    getPim2An,
    getPimDictionary,
    getPrimaryArticles,
    getResolvedArticles,
    getUnresolvedArticles,
    getSecondaryArticles,
    getPrimaryProducts,
    getProductFeaturesConfig,
    getProductGroupsData,
    getProducts,
    getSecondaryProducts,
    getTariffCombinations,
    getTariffs,
    isServiceReady,
    load
  }
}
