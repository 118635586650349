/* @ngInject */
export default function breadcrumbService() {
  let serviceData = {
    accessories: {},
    category: {
      value: '',
      label: ''
    },
    accessory: {
      value: '',
      label: ''
    }
  }

  const get = () => serviceData
  const set = (page, crumb) => {
    serviceData = {
      ...serviceData,
      [page]: crumb
    }

    console.log('serviceData', serviceData)
  }

  return {
    get,
    set
  }
}
