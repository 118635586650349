import { version } from '../../../../package.json'

/* @ngInject */
export default function AppControlController(
  navigationService,
  businessLogService,
  $document,
  $window,
  ConfigProvider,
  $rootScope,
  SessionService
) {
  let ctrl = this
  let debugCounter = 0

  ctrl.data = {
    debugMode: ConfigProvider.getFrontendSettingByKey('DEBUG_MODE') === true,
    layout: true,
    config: ConfigProvider.SETTINGS,
    showDebugInformation: false,
    version,
    debugInformation: {
      clientWidth: $document.innerWidth(),
      clientHeight: $document.innerHeight(),
      userAgent: $window.navigator.userAgent
    }
  }

  function startPageReload() {
    SessionService.appSessionStop(SessionService.reasons.hardReset)
    businessLogService.generate({ event: businessLogService.events.MANUAL_HARD_RESET })
    navigationService.hardReset()
  }

  function redirectToAdmin() {
    SessionService.appSessionStop(SessionService.reasons.admin)
    navigationService.goToAdmin()
  }

  function toggleDebugInformation() {
    if (isDebugModeOn()) {
      ctrl.data.showDebugInformation = !ctrl.data.showDebugInformation
    }
  }

  function isDebugModeOn() {
    ctrl.data.debugMode = ctrl.data.debugMode || debugCounter++ > 4
    return ctrl.data.debugMode
  }

  ctrl.controlFunctions = {
    startPageReload,
    redirectToAdmin,
    toggleDebugInformation
  }
}
