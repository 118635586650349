import { size, get, flow, map, keyBy, filter } from 'lodash/fp'

/* @ngInject */
export default function ArticleListService(Article, ProductListService) {
  let localData = {}

  /**
   * Self constructor, build up process
   */
  function init(rawArticles) {
    console.groupCollapsed('%c ArticleListService :: initializing ', 'background: #000; color: orange;')

    localData.articles = flow(
      map(rawArticleData => {
        const pim = get('_pim.id')(rawArticleData)
        const product = ProductListService.getProductById(pim)
        return Article.build(rawArticleData, product)
      }),
      keyBy('id')
    )(rawArticles)

    console.info('ArticleListService :: initialized SUCCESSFULLY with %s articles', size(localData.articles))
    console.log('ArticleListService', localData.articles)

    console.groupEnd()
  }

  const getServiceData = () => localData
  const getArticleById = id => localData.articles[id]
  const getArticles = () => localData.articles
  const getSecondaryProducts = () => filter(article => get('_role.secondary')(article) === true)(localData.articles)

  return {
    init,
    getServiceData,
    getArticleById,
    getArticles,
    getSecondaryProducts
  }
}
