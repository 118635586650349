import controller from '../product-filter.controller'
import template from './lifestyle-filter.html'

export default {
  bindings: {
    filters: '<'
  },
  template,
  controller
}
