import config from './config'
import accessoryItem from './accessory-item/component'

import accessorySelector from './component'

const module = angular.module('accessory-selector', [])

module
  .config(config)
  .component('accessorySelector', accessorySelector)
  .component('accessoryItem', accessoryItem)

export default module.name
