import uuid from 'uuid/v4'
import { version } from '../../../../../package.json'

const msToSec = ms => Math.round(ms / 1000)

/* @ngInject */
export default function SessionService(businessLogService, ConfigProvider) {
  const localData = {
    sessionId: null
  }

  const reasons = {
    timeout: {
      name: 'timeout',
      compensate: true
    },
    hardReset: {
      name: 'hard-reset',
      compensate: false
    },
    admin: {
      name: 'admin',
      compensate: false
    },
    dataUpdate: {
      name: 'data-update',
      compensate: false
    }
  }

  const generateSessionId = () => uuid()

  const init = () => {
    console.groupCollapsed(`%c SessionService :: initialize`, 'background: #000; color: orange;')
    localData.sessionId = generateSessionId()
    console.log('localData', localData)
    console.groupEnd()
  }
  const getSessionId = () => localData.sessionId
  const appSessionStart = () => {
    if (!localData.appStarted) {
      localData.appStarted = true
      localData.appStartDate = Date.now()
      const groups = ConfigProvider.getPrimaryProductGroupConfig()
      const defaultPrimaryGroup = ConfigProvider.getDefaultPrimaryProductGroup()

      businessLogService.setSessionId(getSessionId())
      businessLogService.generate({
        event: businessLogService.events.APP_SESSION_START,
        info: {
          'frontend-version': version,
          ppgs: groups.map(g => g.id),
          'num-of-ppgs': groups.length,
          ...(groups.length > 1
            ? {
                'default-ppg-remote': defaultPrimaryGroup.remote.repr,
                'default-ppg-local': defaultPrimaryGroup.local.repr,
                'default-ppg': defaultPrimaryGroup.computed.repr
              }
            : {})
        }
      })

      console.log('SessionService::appSessionStart', localData)
    } else {
      console.warn('SessionService::appSessionStart: app session already started!')
    }
  }

  const idleEnd = () => {
    const endDate = Date.now()
    businessLogService.generate({
      event: businessLogService.events.IDLE_END,
      info: { 'idle-duration': msToSec(endDate - localData.appStartDate) }
    })
  }

  const appSessionStop = reason => {
    if (localData.useStarted) {
      userSessionStop(reason)
    } else {
      console.warn('SessionService::appSessionStop: session not started!')
      idleEnd()
    }

    if (localData.appStarted) {
      localData.appStopped = true
      localData.appStopDate = Date.now()
      localData.appDuration = msToSec(localData.appStopDate - localData.appStartDate)

      businessLogService.generate({
        event: businessLogService.events.APP_SESSION_STOP,
        info: {
          'app-duration': localData.appDuration,
          reason: reason.name
        }
      })
    } else {
      console.warn('SessionService::appSessionStop: session not started!')
    }
  }

  const userSessionStart = () => {
    if (!localData.useStarted) {
      idleEnd()
      localData.useStarted = true
      localData.useStartDate = Date.now()
      businessLogService.generate({ event: businessLogService.events.USER_SESSION_START })
      console.log('SessionService::userSessionStart', localData)
    } else {
      console.warn('SessionService::userSessionStart: user session already started')
    }
  }

  const userSessionStop = reason => {
    localData.useStopped = true
    localData.useStopDate = Date.now()
    let useDuration = localData.useStopDate - localData.useStartDate
    if (reason.compensate) useDuration = useDuration - ConfigProvider.SETTINGS.GLOBAL_RESET_TIMEOUT
    localData.useDuration = msToSec(useDuration)
    businessLogService.generate({
      event: businessLogService.events.USER_SESSION_STOP,
      info: {
        'use-duration': localData.useDuration,
        reason: reason.name
      }
    })
    console.log('SessionService::userSessionStop', localData)
  }

  return {
    init,
    reasons,
    appSessionStart,
    appSessionStop,
    userSessionStart
  }
}
