import createEventEmitter from 'tower2/common/utils/event-emitter'
import { role, ROLES } from '../../../commons/services/identity'

const socketChannel = createEventEmitter({
  name: 'log'
})

socketChannel.on('remote', payload => {
  if (role === ROLES.MAIN) {
    const { level, message } = payload
    socketChannel.send({
      command: 'write',
      payload: {
        name: 'mpa-application-frontend',
        level,
        message
      }
    })
  }
})

socketChannel.on('business', payload => {
  const { message, name } = payload
  socketChannel.send({
    command: 'write',
    payload: {
      name,
      level: 'info',
      message
    }
  })
})

export { socketChannel }

/* @ngInject */
export default function socketChannelLogService() {
  this.$get = () => socketChannel
}
