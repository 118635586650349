import controller from './controller'
import horizontalTemplate from './templates/horizontal.html'
import verticalTemplate from './templates/vertical.html'

/* @ngInject */
const template = displayService =>
  displayService.getTemplate({
    component: 'detailsMenu',
    horizontal: horizontalTemplate,
    vertical: verticalTemplate
  })

export default {
  bindings: {
    itemGroupId: '<'
  },
  template,
  controller
}
