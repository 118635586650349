import { has, set, includes } from 'lodash'

let missingTranslations = {}

/* @ngInject */
const missingTranslationHandlerFactory = () => (translationID, uses) => {
  if (!has(missingTranslations, uses)) set(missingTranslations, uses, [])
  if (!includes(missingTranslations[uses], translationID)) {
    missingTranslations[uses].push(translationID)
    console.groupCollapsed(`%c missing translation found in '${uses}': '${translationID}'`, 'color: purple')
    console.warn(JSON.stringify(missingTranslations))
    console.groupEnd()
  }
  return translationID
}

export default missingTranslationHandlerFactory
