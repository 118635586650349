import controller from './controller'
import template from './template.html'

export default {
  bindings: {
    combinations: '<',
    onSelect: '&'
  },
  template,
  controller
}
