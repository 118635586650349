import { isNumber, includes, indexOf, each, isArray, find } from 'lodash'

/* @ngInject */
export default function pinnedListService($rootScope, EVENT_KEYS, businessLogService) {
  let items = []

  return {
    get,
    getPinnedIds,
    isIdPinned,
    add,
    addRange,
    remove,
    clear,
    toggle
  }

  // PUBLIC MEMBERS
  /**
   * Gets the specified element by index, if index not specified
   * returns the whole collection
   */
  function get(index) {
    if (index) {
      if (!isNumber(index)) {
        throw new Error('Index should be a number')
      }

      return items[index]
    } else {
      return items
    }
  }

  /**
   * Alias function: this.get()
   */
  function getPinnedIds() {
    return items
  }

  /**
   * Check if item is pinned or not.
   * @param item
   * @returns {boolean}
   */
  function isIdPinned(item) {
    return includes(items, item.id)
  }

  /**
   * Adds a product Id to the list
   * @param itemGroup
   */
  function add(itemGroup) {
    businessLogService.generate({
      event: businessLogService.events.ITEM_GROUP_PINNED,
      data: {
        product: itemGroup.getUsageInfo()
      }
    })
    if (indexOf(items, itemGroup.id) < 0) {
      items.push(itemGroup.id)
      broadcastChange()
    }
  }

  function toggle(itemGroup) {
    isIdPinned(itemGroup) ? remove(itemGroup) : add(itemGroup)
  }

  /**
   * Adds multiple product Id to the list
   * @param Can be an array or arguments list
   */
  function addRange() {
    let args = Array.prototype.slice.call(arguments)

    each(args, function(arg) {
      if (isArray(arg)) {
        each(arg, function(id) {
          items.push(id)
        })
      } else {
        items.push(arg)
      }
    })
    broadcastChange()
  }

  /**
   * Removes the specified element of the list
   * @param itemGroup
   */
  function remove(itemGroup) {
    const id = itemGroup.id
    let found = find(items, function(v) {
      return v === id
    })

    if (found) {
      items.splice(indexOf(items, found), 1)
      businessLogService.generate({
        event: businessLogService.events.ITEM_GROUP_UNPINNED,
        data: {
          product: itemGroup.getUsageInfo()
        }
      })
      broadcastChange()
    }
  }

  /**
   * Removes all product Id of the list
   */
  function clear() {
    items = []
    broadcastChange()
  }

  // PRIVATE MEMBERS

  function broadcastChange() {
    $rootScope.$broadcast(EVENT_KEYS.LOGIC.PINNED_LIST_CHANGED, items)
  }
}
