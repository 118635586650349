export default function combinationDetailsController(combinationComparerService) {
  const ctrl = this

  ctrl.$onInit = () => {
    ctrl.state = {
      featuresToShow: {
        contract_length: { type: 'text', key: 'contract_length' },
        upfront_act: { type: 'price', key: 'TXT_UPFRONTACTIVATIONPRICE' },
        upfront_pkg: { type: 'price', key: 'TXT_UPFRONTPACKAGEPRICE' },
        mins: { type: 'text', key: 'mins' },
        sms: { type: 'text', key: 'sms' },
        data_gb: { type: 'text', key: 'data_gb' },
        data_speed: { type: 'text', key: 'data_speed' }
      },
      valuesToTranslate: ['ul']
    }
  }

  const removeCombination = combination => {
    combinationComparerService.remove(combination)
    ctrl.combination = null
  }

  ctrl.controlFunctions = {
    removeCombination
  }
}
