import componentSelection from './component-selection/component-selection.service'
import dataProvider from './data-provider/data.provider'
import displayService from './display/display.service'
import eslService from './esl/esl.service'
import faceRecService from './face-rec/face-rec.service'
import imageCacheService from './image-cache/image-cache.service'
import ledService from './led/led.service'
import missingTranslationHandlerFactory from './missing-translation-handler.factory'
import navigationService from './navigation.service'
import pinnedListService from './pinned-list.service'
import preLoaderService from './preloader/preloader.service'
import ProductDetailsService from './product-details/product-details.service'
import productDetailsTranslateService from './product-details/product-details-translate.service'
import ProductGroupService from './product-group-selector/product-group.service'
import stateEngineService from './state-engine/state-engine.builder'
import combinationComparerService from './tariff-comparer.service'
import sessionService from './session/session.service'
import ConfigProvider from './config-provider/config.provider'

const module = angular.module('mpa.commons.services', [])

module
  .factory('DataProvider', dataProvider)
  .factory('displayService', displayService)
  .factory('ConfigProvider', ConfigProvider)
  .factory('missingTranslationHandlerFactory', missingTranslationHandlerFactory)
  .factory('ProductDetailsService', ProductDetailsService)
  .factory('ProductGroupService', ProductGroupService)
  .service('componentSelection', componentSelection)
  .service('eslService', eslService)
  .service('faceRecService', faceRecService)
  .service('imageCacheService', imageCacheService)
  .service('ledService', ledService)
  .service('navigationService', navigationService)
  .service('pinnedList', pinnedListService)
  .service('preLoaderService', preLoaderService)
  .service('productDetailsTranslateService', productDetailsTranslateService)
  .service('stateEngineService', stateEngineService)
  .service('combinationComparerService', combinationComparerService)
  .service('SessionService', sessionService)

export default module.name
