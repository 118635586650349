import template from './qr-code-viewer.template.html'
import controller from './qr-code-viewer.controller'

/* @ngInject */
export default {
  bindings: {
    text: '<'
  },
  template,
  controller
}
