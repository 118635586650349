import { debounce } from 'lodash'
/* @ngInject */
export default function RootController(
  $rootScope,
  $scope,
  $translate,
  $document,
  DataProvider,
  EVENT_KEYS,
  BusinessLogic,
  $window,
  ConfigProvider,
  displayService
) {
  // Controller data
  $scope.data = {
    countryCode: '',
    langCode: 'en'
  }

  displayService.loadSkin()

  $scope.state = {
    interactionDebounceTimeout: null,
    isApplicationReady: false
  }

  // User interaction handler
  let debouncedInteractionHandler = debounce(function() {
    BusinessLogic.userInteractionDetected()
  }, 10)

  $rootScope.$on('$translateChangeSuccess', handleTranslateChangeSuccess)
  $document.on('touchstart mousedown touchend mouseup touchcancel touchmove', debouncedInteractionHandler)

  $window.onresize = function(event) {
    $window.location.reload()
  }

  // Get Default
  DataProvider.isServiceReady().then(function() {
    let nodeSettings = ConfigProvider.getNodeSettings()
    $scope.data.countryCode = nodeSettings.country
    $scope.data.langCode = nodeSettings.default_language.toLowerCase()
    $scope.state.isApplicationReady = true
    $rootScope.$broadcast(EVENT_KEYS.LOGIC.TURN_OFF_LEDS)
    // $rootScope.$broadcast(EVENT_KEYS.LOGIC.TURN_OFF_AUDIO_PORTS) // handled by audio service
  })

  // ~~~~~~~~~~~~~~
  // Event handlers
  // ~~~~~~~~~~~~~~
  function handleTranslateChangeSuccess() {
    $scope.data.countryCode = $translate.use().toLowerCase()
    $scope.data.langCode = $translate.use().toLowerCase()
  }
}
