import { has, get, isArray, find } from 'lodash'

/* @ngInject */
export default function productDetailsTranslateService($translate) {
  let localData = {}

  // Service object
  return {
    getServiceData,
    getTranslation,
    init
  }

  // ~~~~~~~~~~~~~~
  // Public API
  // ~~~~~~~~~~~~~~
  /**
   * Public full data getter
   */
  function getServiceData() {
    return localData
  }

  /**
   * Response with a translation (or with null if not found) respecting the $translate settings.
   * @param contextType the type in pim_dict
   * @param id
   * @param enableFallbackLanguageUsage allow fallback if nothing found
   * @returns {null||*}
   */
  function getTranslation(contextType, id, enableFallbackLanguageUsage) {
    if (enableFallbackLanguageUsage === null || enableFallbackLanguageUsage === undefined) {
      enableFallbackLanguageUsage = true
    }
    let contextTypeId = localData.context_types[contextType] + ''
    let lang = $translate.use()
    // Default
    let translation = get(localData, 'dictionary[' + contextTypeId + '][' + lang + '][' + id + ']')
    // If not found with default, use fallbacks
    if (!translation && enableFallbackLanguageUsage) {
      // Find the proper fallback language if any
      let langs = isArray($translate.fallbackLanguage())
        ? $translate.fallbackLanguage()
        : [$translate.fallbackLanguage()]
      let translationLang = find(langs, function(fLang) {
        return has(localData, 'dictionary[' + contextTypeId + '][' + fLang + '][' + id + ']')
      })
      // Get the fallback translation
      if (
        contextTypeId &&
        translationLang &&
        has(localData, 'dictionary[' + contextTypeId + '][' + translationLang + '][' + id + ']')
      ) {
        translation = localData.dictionary[contextTypeId][translationLang][id]
      }
    }
    // Send back null if really nothing have been found
    return translation
  }

  // ~~~~~~~~~~~~~~
  // Local API
  // ~~~~~~~~~~~~~~
  function initLocalData() {
    return {}
  }

  /**
   * Self constructor, build up process
   */
  function init(pimDictionary) {
    console.groupCollapsed('%c productDetailsTranslateService :: initializing ', 'background: #000; color: orange;')
    // Init empty local data structures
    localData = initLocalData()
    $.extend(localData, pimDictionary)
    console.log('localData', localData)
    console.groupEnd()
  }
}
