import { has, difference, get, chain, each, map, includes, find } from 'lodash'

/* @ngInject */
export default function ComparisonPageController(
  $scope,
  $timeout,
  $rootScope,
  $stateParams,
  ConfigProvider,
  EVENT_KEYS,
  ProductDetailsService,
  ItemGroupListService,
  businessLogService,
  navigationService,
  pinnedList,
  displayService
) {
  let ctrl = this
  let comparedItems

  ctrl.$onInit = initialize

  $scope.$on(EVENT_KEYS.LOGIC.PINNED_LIST_CHANGED, pinnedListChangedHandler)

  function initialize() {
    comparedItems = []
    ctrl.properties = getDefaultProperties()
    ctrl.data = getDefaultData()

    let pinnedItems = pinnedList.get()
    if (!pinnedItems.length) {
      navigationService.goToDefaultPage()
    } else {
      updateComparedProducts(pinnedItems)
      console.info('Comparison page loaded properly. There are %s pinned product in list.', ctrl.data.products.length)
    }
  }

  function pinnedListChangedHandler(event, items) {
    if (items.length > 1) updateComparedProducts(items)
  }

  function updateComparedProducts(itemGroupIds) {
    const diff = {
      add: difference(itemGroupIds, comparedItems).map(generateProduct),
      remove: difference(comparedItems, itemGroupIds).map(generateProduct)
    }

    diff.add.forEach(item => ctrl.data.products.push(item))
    diff.remove.forEach(removeProduct)

    // in case of using LED controller, multi-item-group us NOT supported!!
    let led = ctrl.data.products.map(itemGroup => map(itemGroup.data.getArticles(), a => a.an)[0])
    $rootScope.$broadcast(EVENT_KEYS.LOGIC.TURN_ON_LED_BY_ARTICLE_IDS, led)

    $timeout(function() {
      if (!ctrl.data.definition) {
        ctrl.data.definition = generateProductDefinition(ctrl.data.products)
      }
    })

    comparedItems = itemGroupIds
    createUsage(comparedItems)
  }

  function createUsage(comparedItems) {
    const itemGroups = generateItemGroups(comparedItems)
    businessLogService.generate({
      event: businessLogService.events.PRODUCT_COMPARISON,
      data: {
        product: itemGroups.map(ig => ig.getUsageInfo())
      }
    })
  }

  function generateProduct(itemGroupId) {
    const itemGroup = ItemGroupListService.getItemGroupById(itemGroupId)
    const details = ProductDetailsService($stateParams, itemGroup.product)
    return {
      data: itemGroup,
      details
    }
  }

  function generateItemGroups(pinnedItems) {
    return pinnedItems.map(function(id) {
      return ItemGroupListService.getItemGroupById(id)
    })
  }

  function generateProductDefinition(products) {
    return chain(products)
      .map(product => product.details.featureGroups)
      .flatten()
      .groupBy('name')
      .reduce((result, featureGroups, key) => {
        each(featureGroups, featureGroup => {
          each(featureGroup.features, feature => {
            if (!has(result, key)) {
              result[key] = []
            }
            if (!includes(result[key], feature.name)) {
              result[key].push(feature.name)
            }
          })
        })
        return result
      }, {})
      .map((value, key) => ({
        name: key,
        features: map(value, name => ({ name: name }))
      }))
      .value()
  }

  function getDefaultProperties() {
    const key = displayService.isHorizontal()
      ? 'MAX_PINNED_ITEMS_TO_COMPARE_HORIZONTAL'
      : 'MAX_PINNED_ITEMS_TO_COMPARE_VERTICAL'
    return {
      niceOps: {
        horizrailenabled: false,
        autohidemode: true
      },
      maxComparisonNum: ConfigProvider.getFrontendSettingByKey(key)
    }
  }

  function getDefaultData() {
    return {
      definition: null,
      products: []
    }
  }

  // PUBLIC METHODS
  let removeProduct = function(product) {
    ctrl.data.products = ctrl.data.products.filter(p => p.data.id !== product.data.id)
    // remove from pinned list as well
    pinnedList.remove(product.data)
  }

  let selectProduct = function(product) {
    businessLogService.generate({
      event: businessLogService.events.PRODUCT_SELECTION,
      data: { product: product.data.getUsageInfo() },
      info: { from: 'comparison-page:selector-button' }
    })

    navigationService.goToDetailsPage($stateParams.groupId, product.data.id)
  }

  let getProperty = function(product, groupName, feature, prop) {
    let featureGroup = find(product.details.featureGroups, { name: groupName })
    let feat = find(featureGroup.features, { name: feature.name })

    return get(feat, prop)
  }

  ctrl.controlFunctions = {
    removeProduct,
    selectProduct,
    getProperty
  }
}
