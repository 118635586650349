import { debounce } from 'lodash'
/* @ngInject */
export default function userInteraction(businessLogService) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      const events = ['touchend']
      const properties = {
        debounceTimeout: 10
      }

      const elementName = attrs['userInteraction']

      if (!elementName) {
        console.warn(
          `userInteraction: no value provided for directive. Use like this: <div user-interaction="my-button">`
        )
      }

      const debouncedInteractionHandler = debounce(() => {
        const triggerInfo = {
          element: elementName
        }

        businessLogService.screen(
          businessLogService.events.SCREEN_INTERACTION,
          triggerInfo,
          'mpa-application-screen-interaction'
        )
      }, properties.debounceTimeout)

      // event listener
      if (elementName) element.on(events.join(' '), debouncedInteractionHandler)
    }
  }
}
