/* @ngInject */
export default function ProductFilterFactory(ConfigProvider, DataProvider) {
  let localConfig = {
    // TODO
    types: {
      feature: 'feature',
      property: 'property'
    },
    defaultTypeNum: 0
  }

  DataProvider.isServiceReady().then(() => {
    localConfig.manufacturers = DataProvider.getPimDictionary().manufacturers
    localConfig.manufacturerKeys = Object.keys(localConfig.manufacturers)
  })

  const getProductFilterType = (manufacturerKeys, featureId) =>
    manufacturerKeys.includes(featureId) ? localConfig.types.property : localConfig.types.feature

  // ~~~~~~~~~~~~~~
  // Constructor with default object structure
  // ~~~~~~~~~~~~~~
  function ProductFilter(productIds, { id, groupName, iconName, label, groupOperator }) {
    this.id = id
    this.productIdList = productIds
    this.groupName = groupName
    this.groupOperator = groupOperator
    this.iconName = iconName || 'feat-' + id
    this.label = label || this.iconName
    this.type = getProductFilterType(localConfig.manufacturerKeys, id)
  }

  // ~~~~~~~~~~~~~~
  // Prototype methods
  // ~~~~~~~~~~~~~~
  ProductFilter.prototype.toggle = function() {
    this.active = !this.active
  }

  ProductFilter.prototype.getUsageInfo = function() {
    const name = this.groupName === 'manufacturers' ? `manu-${this.id}` : this.id
    return {
      name
    }
  }

  ProductFilter.prototype.setInactive = function() {
    this.active = false
  }

  ProductFilter.prototype.setActive = function() {
    this.active = true
  }

  ProductFilter.prototype.isType = function(type) {
    return this.type === type
  }

  ProductFilter.prototype.getLabel = function() {
    return this.label
  }

  ProductFilter.prototype.reset = function() {
    this.setInactive()
  }

  ProductFilter.prototype.getPopOverFilterImageUrl = function() {
    let _img = ''
    if (this.id) {
      _img = localConfig.manufacturers[this.id].logo_url
    }
    return _img
  }

  // ~~~~~~~~~~~~~~
  // Factory Public API
  // ~~~~~~~~~~~~~~
  ProductFilter.getFilterTypes = function() {
    return localConfig.types
  }
  // ~~~~~~~~~~~~~~
  // Private API
  // ~~~~~~~~~~~~~~

  // ~~~~~~~~~~~~~~
  // Builder
  // ~~~~~~~~~~~~~~
  /**
   * The factory's builder.
   * @param ids the productIds if the filter is selected
   * @param config {id: the unique inner id, groupName: name of the filter group or null if not in group}
   * @returns {FeatureFactory.Feature}
   */
  ProductFilter.build = function(ids, config) {
    let newProductFilter = null
    if (config && angular.isArray(ids)) {
      config.groupName = config.groupName ? config.groupName : null
      newProductFilter = new ProductFilter(ids, config)
    }
    return newProductFilter
  }

  // Return the constructor function
  return ProductFilter
}
