import Swiper from 'swiper'
import { map } from 'lodash'

import template from './swiper.template.html'

/* @ngInject */
export default function swiperDirective($window, $timeout) {
  let swiper = null
  const defaultSwiperConfig = {
    centeredSlides: true,
    effect: 'coverflow',
    freeMode: false,
    grabCursor: true,
    loop: false, // virtual slides do not work with loop mode
    slidesPerView: 5,
    lazy: true,
    preloadImages: true,
    updateOnImagesReady: true,
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: false
    }
  }
  const checkWidth = (element, desiredWidth) =>
    new Promise(resolve => {
      const checker = () => {
        const toId = $timeout(() => {
          const width = parseInt($window.getComputedStyle(element[0]).width, 10)
          if (width < desiredWidth) {
            checker()
          } else {
            $timeout.cancel(toId)
            resolve(width)
          }
        }, 1000 / 60)
      }
      checker()
    })

  const link = (scope, element) => {
    $timeout(() => {
      const mapSlides = () => map(scope.items, item => item)
      const getItemByIndex = index => scope.items[index]
      const getCenterItem = items => Math.floor(items.length / 2)
      const getIndexByItemId = id => {
        const index = scope.items.findIndex(item => item.id === id)
        return index > -1 ? index : getCenterItem(scope.items)
      }
      const onSlideChange = () => {
        if (swiper) {
          const highlightedItem = getItemByIndex(swiper.activeIndex)
          scope.onItemHighlight({ item: highlightedItem })
          scope.$evalAsync()
        }
      }
      const getInitialSlide = () =>
        scope.initialHighlightedItem ? getIndexByItemId(scope.initialHighlightedItem.id) : getCenterItem(scope.items)
      const overrideConfig = scope.config || {}
      const desiredWith = element.parents(scope.parentElementSelectorToCheckWidth).width()
      checkWidth(element, desiredWith).then(() => {
        $timeout(() => {
          swiper = new Swiper('#swiper-id', {
            ...defaultSwiperConfig,
            ...overrideConfig,
            initialSlide: getInitialSlide(),
            virtual: {
              cache: false,
              slides: mapSlides(),
              renderSlide: scope.renderSlide
            },
            on: {
              slideChange: onSlideChange
            }
          })
        }, 0)
      })

      scope.$on('$destroy', () => {
        if (swiper) {
          swiper.destroy()
        }
        swiper = null
      })
    }, 0)
  }

  return {
    restrict: 'E',
    scope: {
      config: '<',
      initialHighlightedItem: '<',
      items: '<',
      onItemHighlight: '&',
      renderSlide: '<',
      parentElementSelectorToCheckWidth: '@'
    },
    template,
    link
  }
}
