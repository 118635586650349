import template from './range-slider.template.html'
import controller from './range-slider.controller'

export default {
  bindings: {
    selector: '<'
  },
  controller,
  template
}
