import productFilterPopup from './cv-popup/product-filter-popup.component'
import productFilterIcon from './cv-icon/product-filter-icon.component'
import productFilterAccordion from './cv-accordion/product-filter-accordion.component'
import lifestyleFilterComponent from './lifestyle-filter-component/lifestyle-filter.component'
import dynamicFilterComponent from './dynamic-filter-component/dynamic-filter.component'

const module = angular.module('mpa.components.productfilter', [])

module
  .component('productFilterPopup', productFilterPopup)
  .component('productFilterIcon', productFilterIcon)
  .component('productFilterAccordion', productFilterAccordion)
  .component('lifestyleFilterComponent', lifestyleFilterComponent)
  .component('dynamicFilterComponent', dynamicFilterComponent)

export default module.name
