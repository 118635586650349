import { getFrontendSettings, getNodeSettings as getRawNodeSettings, getDataIndex } from '../../../app.data'
import { get, find } from 'lodash'

const processRawSettings = ({ node_settings }, { ppgs }) => {
  const {
    display_order_of_primary_product_groups,
    product_group_hierarchy: { primary_product_groups }
  } = node_settings
  node_settings.product_group_hierarchy.primary_product_groups = primary_product_groups
    .map(group => ({
      ...group,
      ...(ppgs[group.id] || {})
    }))
    .filter(group => group.primary_customer_products)

  if (Array.isArray(display_order_of_primary_product_groups) && display_order_of_primary_product_groups.length) {
    const ppgIds = node_settings.product_group_hierarchy.primary_product_groups.map(g => g.id)
    const orderedPpgs = display_order_of_primary_product_groups.reduce(
      (groups, id) =>
        ppgIds.includes(id)
          ? [...groups, find(node_settings.product_group_hierarchy.primary_product_groups, { id })]
          : groups,
      []
    )
    const notFoundInOrder = node_settings.product_group_hierarchy.primary_product_groups.filter(
      g => !display_order_of_primary_product_groups.includes(g.id)
    )
    node_settings.product_group_hierarchy.primary_product_groups = [...orderedPpgs, ...notFoundInOrder]
  }

  return node_settings
}

const stringifyDefaultPrimaryGroup = value => (typeof value === 'string' ? value || 'no ppg set' : 'undefined')
const stringifyComputedPrimaryGroup = (remote, local, computed) =>
  ((typeof remote === 'string' && remote.length && typeof local !== 'string') ||
    (typeof local === 'string' && local.length)) &&
  !computed
    ? 'can not be determined'
    : (remote === '' && typeof local !== 'string') || local === ''
    ? 'no ppg set'
    : computed || 'undefined'

/* @ngInject */
export default function ConfigProvider() {
  const frontendSettings = getFrontendSettings()
  const getFrontendSettingByKey = key => get(frontendSettings, key)
  let localData = {}

  const load = () => {
    console.groupCollapsed(`%c ConfigProvider :: load `, 'background: #000; color: orange;')
    const rawRemoteSettings = getRawNodeSettings()
    const dataIndex = getDataIndex()
    localData.settings = processRawSettings(rawRemoteSettings, dataIndex)
    localData.index = dataIndex
    console.log('ConfigProvider :: localData', localData)
    console.groupEnd()
  }

  const getComponentConfig = () => get(localData, 'settings.component_config')
  const getLogData = () => get(localData, 'settings.log_data')
  const getConfiguredArticles = () => get(localData, 'settings.configured_articles', [])
  const getNumberOfPorts = () => get(localData, 'settings.num_of_ports')
  const getFeatureVideoConfig = () => get(localData, 'settings.app_settings.feature_video')
  const getArticleNumberPrefixToHide = () => get(localData, 'settings.article_number_prefix_to_hide')
  const getProductGroupFilterConfig = groupName =>
    get(localData, `settings.app_settings.product_filter_config.${groupName}`)
  const getNodeSettings = () => get(localData, 'settings')
  const getOverrideBrandLogoUrl = () => get(localData, 'settings.app_settings.brand_logo')
  const getIndexDictionary = () => get(localData, 'index.app_dictionary')
  const getProductGroups = () => get(localData, 'index.ppgs')
  const getPrimaryProductGroupConfig = () =>
    get(localData, 'settings.product_group_hierarchy.primary_product_groups', {})
  const getSecondaryProductGroupConfig = () =>
    get(localData, 'settings.product_group_hierarchy.secondary_product_groups', {})
  const isArticlePreFilteringEnabledAndProvided = () =>
    get(localData, 'settings.num_of_ports') && getFrontendSettingByKey('PRE_FILTER_BY_CONFIGURED_ARTICLES')
  const getDefaultPrimaryProductGroup = () => {
    const { default_primary_product_group, user_set_default_primary_product_group } = getNodeSettings()
    const defaultGroup =
      typeof user_set_default_primary_product_group === 'string'
        ? user_set_default_primary_product_group
        : default_primary_product_group
    const { id } = find(getPrimaryProductGroupConfig(), { id: defaultGroup }) || {}
    return {
      remote: {
        value: default_primary_product_group,
        repr: stringifyDefaultPrimaryGroup(default_primary_product_group)
      },
      local: {
        value: user_set_default_primary_product_group,
        repr: stringifyDefaultPrimaryGroup(user_set_default_primary_product_group)
      },
      computed: {
        value: id,
        repr: stringifyComputedPrimaryGroup(default_primary_product_group, user_set_default_primary_product_group, id)
      }
    }
  }

  return {
    getArticleNumberPrefixToHide,
    getComponentConfig,
    getConfiguredArticles,
    getFeatureVideoConfig,
    getFrontendSettingByKey,
    getIndexDictionary,
    getLogData,
    getProductGroups,
    getNodeSettings,
    getNumberOfPorts,
    getOverrideBrandLogoUrl,
    getPrimaryProductGroupConfig,
    getProductGroupFilterConfig,
    getSecondaryProductGroupConfig,
    isArticlePreFilteringEnabledAndProvided,
    getDefaultPrimaryProductGroup,
    load,
    SETTINGS: frontendSettings
  }
}
