import component from './details.component'
import config from './details.config'
import specificationsSubPage from './subpages/specs/details-specs.module'
import mediaSubPage from './subpages/media/details-media.module'
import accessoriesSubPage from './subpages/accessories'
import tariffsSubPage from './subpages/tariffs'
import detailsMenu from './components/details-menu/component'

const module = angular.module('mpa.pages.details', [
  accessoriesSubPage,
  mediaSubPage,
  specificationsSubPage,
  tariffsSubPage
])

module
  .config(config)
  .component('detailsPageComponent', component)
  .component('detailsMenuComponent', detailsMenu)

export default module.name
