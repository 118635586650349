/* @ngInject */
export default function accessoryDetailsConfig($stateProvider, $stateParamsProvider, MPA_PAGES) {
  $stateProvider.state(MPA_PAGES.DETAILS_ACCESSORIES_ACCESSORY_DETAILS.name, {
    url: MPA_PAGES.DETAILS_ACCESSORIES_ACCESSORY_DETAILS.url,
    // custom props
    component: 'accessoryDetails',
    resolve: {
      /* @ngInject */
      accessory: ($stateParams, ArticleListService) => ArticleListService.getArticleById($stateParams.accessoryId),
      /* @ngInject */
      accessoryDetails: ($stateParams, accessory, ProductDetailsService) =>
        ProductDetailsService($stateParams, accessory.product),
      /* @ngInject */
      breadcrumb: (breadcrumbService, accessory) => {
        breadcrumbService.set('accessory', {
          label: `${accessory.product.manufacturer} - ${accessory.product.getName()}`,
          value: accessory.id
        })
      },
      /* @ngInject */
      interaction: (businessLogService, itemGroup, accessory) => {
        businessLogService.generate({
          event: businessLogService.events.ACCESSORY_SELECTION,
          data: {
            product: itemGroup.getUsageInfo(),
            accessory: accessory.getUsageInfo()
          }
        })
      }
    }
  })
}
