import { each } from 'lodash'

/* @ngInject */
export default function ApplicationBaseConfig(
  $provide,
  $stateProvider,
  $urlRouterProvider,
  $translateProvider,
  $sceDelegateProvider,
  MPA_CORE_URL_KEYS,
  MPA_PAGES
) {
  $provide.decorator('$modal', $delegate => config => {
    const createConfigMethodDecorator = (originalMethod, lifeCycleEventName) => $m => {
      if (config.controllerAs) {
        const modalPromise = $m.$promise
        if (modalPromise) {
          modalPromise.then(val => {
            if (val && val.locals) {
              const modalScope = val.locals.$scope
              if (modalScope) {
                const ctrl = modalScope[config.controllerAs]
                if (ctrl) {
                  if (angular.isFunction(ctrl[lifeCycleEventName])) {
                    ctrl[lifeCycleEventName]()
                  }
                }
              }
            }
          })
        }
      }

      if (angular.isFunction(originalMethod)) {
        originalMethod($m)
      }
    }

    config.onBeforeShow = createConfigMethodDecorator(config.onBeforeShow, '$onInit')
    config.onBeforeHide = createConfigMethodDecorator(config.onBeforeHide, '$onDestroy')

    return $delegate(config)
  })

  $urlRouterProvider.otherwise('/')

  $stateProvider
    .state(MPA_PAGES.ROOT.name, {
      url: MPA_PAGES.ROOT.url,
      abstract: true,
      resolve: {
        /* @ngInject */
        config: ConfigProvider => {
          console.log('ROOT state: loading configurations')
          try {
            ConfigProvider.load()
          } catch (e) {
            console.groupEnd()
            console.error('ROOT state: configurations could not load.')
          }
          console.log('ROOT state: configurations loaded')
        },
        /* @ngInject */
        services: (
          config,
          ConfigProvider,
          businessLogService,
          SessionService,
          groupSelectorService,
          BusinessLogic,
          navigationService,
          ComponentMatrixProvider
        ) => {
          console.log('ROOT state: loading services')
          BusinessLogic.init()
          ComponentMatrixProvider.init(ConfigProvider.getComponentConfig())
          businessLogService.init()
          SessionService.init()
          SessionService.appSessionStart()
          groupSelectorService.init()
          console.log('ROOT state: services loaded')

          const primaryGroups = ConfigProvider.getPrimaryProductGroupConfig()
          if (primaryGroups.length < 2) {
            navigationService.setDefaultPage(MPA_PAGES.SELECTOR_LIST)
            console.log(`ROOT state: set default page to ${MPA_PAGES.SELECTOR_LIST}`)
          }
          return Promise.resolve()
        },
        /* @ngInject */
        translations: (services, ConfigProvider) => {
          console.log('ROOT state: building up translations')
          const defaultLanguage = ConfigProvider.getNodeSettings().default_language
          const dictionaries = {
            [defaultLanguage]: {},
            ...ConfigProvider.getIndexDictionary()
          }
          const fallbackLanguage = ConfigProvider.SETTINGS.FIX_FALLBACK_LANGUAGE

          each(dictionaries, (val, key) => $translateProvider.translations(key, val))
          $translateProvider.preferredLanguage(defaultLanguage)
          $translateProvider.fallbackLanguage(fallbackLanguage)
          $translateProvider.use(defaultLanguage)
          $translateProvider.useSanitizeValueStrategy('escaped')
          $translateProvider.useMissingTranslationHandler('missingTranslationHandlerFactory')
          console.log('ROOT state: translations ready')
        }
      },
      component: 'layoutComponent'
    })
    .state(MPA_PAGES.GROUP_SELECTOR.name, {
      abstract: true,
      template: '<ui-view class="grid"/>'
    })
}
