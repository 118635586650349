import { map, size, flow, filter, flatten, isNumber, keyBy } from 'lodash/fp'

const cmap = map.convert({ cap: false })

/* @ngInject */
export default function CombinationListService(
  ConfigProvider,
  Combination,
  ArticleListService,
  TariffListService,
  $q,
  ComponentMatrixProvider
) {
  let localData

  const getCombinationsByAn = an => filter(combination => combination.article.an === an)(localData.combinations)

  const buildTariffCombinations = cmap(({ tariff_combinations }, an) => {
    const article = ArticleListService.getArticleById(an)
    return cmap((combiData, tariffId) => {
      const tariff = TariffListService.getTariffById(tariffId)
      const id = `${an}_${tariffId}`
      return Combination.build({ article, tariff, combiData, id, type: 'tariff' })
    })(tariff_combinations)
  })

  const getPrimerCombinations = rawArticles =>
    rawArticles.map(combi =>
      Combination.build({
        article: ArticleListService.getArticleById(combi.an),
        id: `${combi.an}_primer`,
        type: 'primer'
      })
    )

  const filterValidArticlesForTariff = filter(article => isNumber(article.price) && article.sold_alone !== false)

  const getTariffCombinations = flow(
    buildTariffCombinations,
    flatten
  )

  // Service object
  return {
    init,
    getCombinationsByAn
  }

  function init(rawTariffCombinations, rawArticles) {
    console.group('%c CombinationListService :: initializing ', 'background: #000; color: orange;')

    localData = {
      combinations: buildCombinations(rawTariffCombinations, rawArticles)
    }
    console.info(
      'CombinationListService initialized SUCCESSFULLY with %s Combinations',
      size(localData.combinations),
      localData.combinations
    )
    console.groupEnd()
  }

  function buildCombinations(rawTariffCombinations, rawArticles) {
    const isTariffExtensionAllowed = ComponentMatrixProvider.isComponentAllowed('tariffsExtension')

    const tariffCombinations = isTariffExtensionAllowed ? getTariffCombinations(rawTariffCombinations) : []
    const primerCombinations = isTariffExtensionAllowed
      ? getPrimerCombinations(filterValidArticlesForTariff(rawArticles))
      : getPrimerCombinations(rawArticles)

    console.log('CombinationListService: primerCombinations', primerCombinations)
    console.log('CombinationListService: tariffCombinations', tariffCombinations)

    return keyBy('id')([...primerCombinations, ...tariffCombinations])
  }
}
