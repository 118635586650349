import { has } from 'lodash'

/* @ngInject */
export default function componentSelection(ComponentMatrixProvider, $parse, $compile, componentSelection) {
  return {
    scope: true,
    replace: false,
    link: function($scope, $element, $attr) {
      // The value is the component name
      let componentName = $attr['componentSelection']

      // Listeners to update
      ComponentMatrixProvider.isServiceReady().then(refreshAllowed)
      // TODO: use real event name or EVENT_KEYS
      $scope.$on('ComponentSetupReset', refreshAllowed)

      /**
       * Refresh watched value
       */
      function refreshAllowed() {
        let componentConfig = ComponentMatrixProvider.getComponentSelectionConfigByName(componentName)
        if (componentConfig.type === 'DIRECTIVE') {
          let directiveName = componentConfig.name
          // Execute the calculator function
          if (has(componentConfig, 'func')) {
            // Execute the function from the service
            directiveName = componentSelection.executeFunction(componentConfig.func)
          }
          if (directiveName) {
            $element.html('<' + directiveName + '></' + directiveName + '>')
            $compile($element.contents())($scope)
            console.info(
              'componentSelection :: for "%s" component "%s" letiant selected',
              componentName,
              componentConfig.letiantName
            )
          } else {
            console.warn('componentSelection :: no match for %s', componentName)
          }
        }
      }
    }
  }
}
