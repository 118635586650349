import controller from './combination-details.controller'
import template from './combination-details.html'

export default {
  bindings: {
    combination: '<',
    itemGroup: '<'
  },
  template,
  controller
}
