let MPA_PAGES = {
  ROOT: {
    name: 'mpa-app'
  },
  GROUP_SELECTOR: {
    name: 'mpa-app.groups'
  },
  GROUP_SELECTOR_LIST: {
    name: 'mpa-app.groups.list',
    url: '/'
  },
  SELECTOR: {
    name: 'mpa-app.groups.products',
    url: '/:groupId'
  },
  SELECTOR_LIST: {
    name: 'mpa-app.groups.products.list',
    url: ''
  },
  DETAILS: {
    name: 'mpa-app.groups.products.details',
    url: '/:itemGroupId'
  },
  DETAILS_SPECS: {
    name: 'mpa-app.groups.products.details.specs',
    url: '/spec'
  },
  COMPARISON: {
    name: 'mpa-app.groups.products.comparison',
    url: '/comparison'
  },
  CHECKOUT: {
    name: 'mpa-app.groups.products.checkout',
    url: '/:itemGroupId/checkout'
  },
  DETAILS_TARIFFS: {
    name: 'mpa-app.groups.products.details.tariffs',
    url: '/tariffs'
  },
  DETAILS_SPECIALS: {
    name: 'mpa-app.groups.products.details.specials',
    url: '/specials'
  },
  DETAILS_MEDIA: {
    name: 'mpa-app.groups.products.details.media',
    url: '/media'
  },
  DETAILS_ACCESSORIES: {
    name: 'mpa-app.groups.products.details.accessories',
    url: '/accessories'
  },
  DETAILS_ACCESSORIES_CATEGORY_SELECTOR: {
    name: 'mpa-app.groups.products.details.accessories.categories',
    url: ''
  },
  DETAILS_ACCESSORIES_ACCESSORY_SELECTOR: {
    name: 'mpa-app.groups.products.details.accessories.category',
    url: '/:categoryId'
  },
  DETAILS_ACCESSORIES_ACCESSORY_SELECTOR_LIST: {
    name: 'mpa-app.groups.products.details.accessories.category.list',
    url: ''
  },
  DETAILS_ACCESSORIES_ACCESSORY_DETAILS: {
    name: 'mpa-app.groups.products.details.accessories.category.accessory',
    url: '/:accessoryId'
  }
}

MPA_PAGES.DEFAULT = MPA_PAGES.GROUP_SELECTOR_LIST

export default MPA_PAGES
