import { hasIn, get, map, find } from 'lodash'

/*
  Product is the representation of a PIM product
*/

/* @ngInject */
export default function ProductFactory(ConfigProvider, productDetailsTranslateService, DataProvider, $location) {
  function Product(data) {
    for (let i in data) {
      if (data.hasOwnProperty(i)) {
        this[i] = data[i]
      }
    }

    this.id = '' + this.id
  }

  /**
   * Determine image of product relative uri.
   * @returns {string}
   */
  Product.prototype.getFullImageUrl = function() {
    let _img = ''
    if (hasIn(this, 'images[0].image_url')) {
      let base = ConfigProvider.SETTINGS.API.baseApiUrl || ''
      _img = base + this.images[0].image_url
    } else {
      console.warn('Product.getFullImageUrl :: could not find url: ' + this.id)
    }
    return _img
  }

  // generic info for business-log
  Product.prototype.getUsageInfo = function() {
    return { id: parseInt(this.id) }
  }

  Product.prototype.getManufacturer = function() {
    return DataProvider.getManufacturerById(this.manufacturer_id)
  }

  Product.prototype.getName = function() {
    let name = this.name
    let translatedName = productDetailsTranslateService.getTranslation('product_name', this.id)
    if (translatedName) {
      name = translatedName
    }
    return name
  }

  /**
   * Returns the assets list of the Product
   * @returns {*|Array}
   */
  Product.prototype.getAssets = function() {
    return map(this.assets, a => ({
      type: a.asset_type,
      url: a.asset_url
    }))
  }

  /**
   * Returns the first video asset of the Product
   * @returns {T}
   */
  Product.prototype.getTopVideoUrl = function() {
    let found = find(this.getAssets(), { type: 'video' })
    return found ? get(found, 'url') : undefined
  }

  // ~~~~~~~~~~~~~~
  // Builder
  // ~~~~~~~~~~~~~~
  Product.build = function(productData) {
    let product = null
    if (productData && angular.isObject(productData)) {
      product = new Product(productData)
    } else {
      console.error('Product.build :: could not build new Product!', productData)
    }
    return product
  }

  /**
   * Return the constructor function
   */
  return Product
}
