export default {
  ACCESSORY_GROUP_SELECTION: { name: 'accessory-grp-selection' },
  ACCESSORY_SELECTION: { name: 'accessory-selection' },
  APP_SESSION_START: { name: 'app-session-start' },
  APP_SESSION_STOP: { name: 'app-session-stop' },
  AUDIO_PLAY: { name: 'audio-play' },
  AUDIO_TOGGLE_PLAYLIST: { name: 'audio-toggle-playlist' },
  AUDIO_TOGGLE_VOLUME_CONTROL: { name: 'audio-toggle-volume-control' },
  BARCODE_READ: { name: 'barcode-read' },
  CAROUSEL_ITEM_PIN_TOGGLE: { name: 'carousel-item-pin-toggle' },
  CAROUSEL_ITEM_SELECT: { name: 'carousel-item-select' },
  CART_ITEM_SEND: { name: 'cart-item-send' },
  CART_INVOKE: { name: 'cart-invoke' },
  CHECKOUT: { name: 'checkout' },
  COMBINATION_SELECTION: { name: 'combination-selection' },
  DETAILS_ACCESSORIES: { name: 'details-accessories' },
  DETAILS_ACCESSORIES_ACCESSORY_SELECTOR: { name: 'details-accessories_accessory_selector' },
  DETAILS_MEDIA: { name: 'details-media' },
  DETAILS_PAGE: { name: 'details-page' },
  DETAILS_SPECIFICATION: { name: 'details-specification' },
  DETAILS_TARIFFS: { name: 'details-tariff' },
  ESL_BLINK: { name: 'esl-blink' },
  FEATURE_VIDEO_VIEW: { name: 'feature-video-view' },
  FILTER_GRP_SELECTION: { name: 'filter-grp-selection' },
  FILTER_RESET: { name: 'filter-reset' },
  FILTER_SELECT: { name: 'filter-selection' },
  IDLE_END: { name: 'idle-end' },
  ITEM_GROUP_PINNED: { name: 'product-starred' },
  ITEM_GROUP_UNPINNED: { name: 'product-unstarred' },
  LANGUAGE_CHANGE: { name: 'language-change' },
  LIFESTYLE_FILTER_SELECT: { name: 'lifestyle-filter-selection' },
  MANUAL_HARD_RESET: { name: 'manual-hard-reset' },
  PICK_UP: { name: 'pick-up' },
  PRODUCT_COMPARISON: { name: 'product-comparison' },
  PRODUCT_GROUP_SELECTION: { name: 'product-group-selection' },
  PRODUCT_GROUP_LOADED: { name: 'product-group-loaded' },
  PRODUCT_SELECTION: { name: 'product-selection' },
  PRODUCT_STARRED: { name: 'product-starred' },
  PRODUCT_UNSTARRED: { name: 'product-unstarred' },
  SCREEN_INTERACTION: { name: 'screen-interaction' },
  USER_SESSION_START: { name: 'user-session-start' },
  USER_SESSION_STOP: { name: 'user-session-stop' }
}
