export default function selectorPageService() {
  const localData = {
    activeItemGroup: null
  }

  const setActiveItemGroup = itemGroup => {
    localData.activeItemGroup = itemGroup
  }
  const getActiveItemGroup = () => localData.activeItemGroup

  return {
    setActiveItemGroup,
    getActiveItemGroup
  }
}
