export default {
  /*
   'language': {
   'country_code': 'flag_code'
   },
   */
  en: {
    '*': 'gb'
  },
  de: {
    at: 'at'
  },
  ca: {
    es: 'es-ca',
    be: 'es-ca' // todo: temporary for ISERN with incomplete config
  }
}
