import { has } from 'lodash'

/* @ngInject */
export default function componentSelection($injector, ComponentMatrixProvider) {
  // Service object
  return {
    executeFunction,
    evaluate
    //
  }

  // ~~~~~~~~~~~~~~
  // Public API
  // ~~~~~~~~~~~~~~

  function evaluate(componentName) {
    let retVal = null
    let componentConfig = ComponentMatrixProvider.getComponentSelectionConfigByName(componentName)
    if (componentConfig.type === 'FUNCTIONALITY') {
      if (has(componentConfig, 'func')) {
        // Execute the function from the service
        retVal = executeFunction(componentConfig.func)
      }
    }
    return retVal
  }

  function executeFunction(functionPathString) {
    let retVal = null
    if (functionPathString) {
      let parts = functionPathString.split('.')
      if (parts.length === 2) {
        try {
          let service = $injector.get(parts[0])
          if (has(service, parts[1])) {
            retVal = service[parts[1]]()
          }
        } catch (e) {
          console.warn('componentSelection :: no service with name %s from path %s', parts[0], functionPathString)
        }
      }
    }
    return retVal
  }

  /**
   * Public full data getter
   */

  // ~~~~~~~~~~~~~~
  // Local API
  // ~~~~~~~~~~~~~~
}
