import { isEmpty } from 'lodash'
import modalTemplate from './feature-video-modal.html'

/* @ngInject */
export default function DetailsSpecsPageController($modal, $scope, businessLogService) {
  let ctrl = this

  ctrl.$onInit = initialize
  $scope.controlFunctions = {
    showFeatureVideo
  }

  $scope.data = {
    itemGroup: null,
    product: null,
    productDetails: null,
    featureVideos: []
  }

  function initialize() {
    if (!isEmpty(ctrl.itemGroup.product)) {
      $scope.data.itemGroup = ctrl.itemGroup
      $scope.data.product = ctrl.itemGroup.product
      $scope.data.productDetails = ctrl.productDetails
      $scope.data.featureVideos = ctrl.productDetails.productFilterFeatures

      console.info(
        'Details - Specs page loaded properly with product id: %s',
        $scope.data.product.id,
        $scope.data.product
      )
    } else {
      console.error("Details - Specs page didn't loaded properly")
    }
  }

  // PUBLIC METHODS
  function showFeatureVideo(feature) {
    if (feature.hasFeatureVideo() && feature.getFeatureVideoUrls()) {
      businessLogService.generate({
        event: businessLogService.events.FEATURE_VIDEO_VIEW,
        info: feature.getUsageInfo()
      })
      let scope = $scope.$new(true)
      scope.videos = feature.getFeatureVideoUrls()
      $modal({
        show: true,
        backdrop: true,
        template: modalTemplate,
        scope: scope
      })
    } else {
      console.warn('Details - Missing video URL (or mapping) for feature ' + feature.value)
    }
  }
}
