import { SUBSCRIPTION_CHANNELS } from '../../socket-comm/socket.comm.config'
/* @ngInject */
export default function cartModalController(
  $rootScope,
  $scope,
  $translate,
  item,
  cartService,
  ConfigProvider,
  businessLogService,
  BusinessLogic
) {
  const $ctrl = this
  const { storeId, customerIdValidationRegex } = ConfigProvider.SETTINGS.CART
  const { name, ean, an } = item
  const customerIdValidation = new RegExp(customerIdValidationRegex)
  const userInteractionCartSend = info => {
    businessLogService.generate({
      event: businessLogService.events.CART_ITEM_SEND,
      data: {
        product: item.getUsageInfo()
      },
      info
    })
  }
  let removeBarcodeReadListener = null

  $ctrl.item = item
  $ctrl.title = `${item.product.manufacturer} ${item.product.name}`
  $ctrl.imageUrl = item.product.images[0].image_url
  $ctrl.customerId = ''
  $ctrl.barcodeError = null
  $ctrl.error = null
  $ctrl.success = false
  $ctrl.qty = 1
  $ctrl.requestSending = false
  $ctrl.requestFinished = false
  $ctrl.barcodeReady = false

  $ctrl.$onInit = () => {
    removeBarcodeReadListener = $rootScope.$on(SUBSCRIPTION_CHANNELS.BARCODE_READ, (e, data) => {
      BusinessLogic.userInteractionDetected()
      const { barcode } = data

      businessLogService.generate({
        event: businessLogService.events.BARCODE_READ,
        info: { barcode }
      })

      if (barcode && customerIdValidation.test(barcode)) {
        $ctrl.customerId = barcode
        $ctrl.barcodeError = null
        $ctrl.barcodeReady = true
      } else {
        $ctrl.customerId = ''
        $ctrl.barcodeError = {
          message: $translate.instant('TXT_INVALID_BARCODE_MESSAGE')
        }
      }
      $scope.$evalAsync()
    })

    console.info('cart :: popup opened on item %j', { name, ean, an })
    businessLogService.generate({
      event: businessLogService.events.CART_INVOKE,
      data: {
        product: item.getUsageInfo()
      }
    })
  }

  $ctrl.$onDestroy = () => {
    removeBarcodeReadListener()
  }

  $ctrl.addItem = async () => {
    const baseData = {
      storeId,
      customerCard: $ctrl.customerId
    }

    $ctrl.requestSending = true
    $ctrl.requestFinished = false
    await cartService
      .addItem({
        ...baseData,
        products: [{ an: item.an, qty: $ctrl.qty }]
      })
      .then(res => {
        const resData = res.data
        $ctrl.success = resData.status !== 'fail' && resData.status !== 'error'
        if (!$ctrl.success) {
          if (resData.status === 'fail') {
            $ctrl.error = {
              message: resData.data.message
            }

            userInteractionCartSend({
              failed: {
                ...baseData,
                qty: $ctrl.qty,
                status: resData.status,
                message: resData.data.message
              }
            })
          } else {
            $ctrl.error = {
              message: $translate.instant('TXT_CART_API_GENERAL_ERROR')
            }

            userInteractionCartSend({
              failed: {
                ...baseData,
                qty: $ctrl.qty,
                status: resData.status
              }
            })
          }

          console.error('cart :: item failed to add: %j', {
            ...baseData,
            name,
            ean,
            an,
            qty: $ctrl.qty,
            status: resData.status,
            message: resData.data.message
          })
        } else {
          userInteractionCartSend({
            success: {
              ...baseData,
              qty: $ctrl.qty
            }
          })
          console.info('cart :: item successfully added: %j', { ...baseData, name, ean, an, qty: $ctrl.qty })
        }
      })
      .catch(err => {
        $ctrl.success = false
        $ctrl.error = {
          message: $translate.instant('TXT_CART_API_GENERAL_ERROR')
        }
        userInteractionCartSend({
          failed: {
            ...baseData,
            qty: $ctrl.qty,
            status: 'error',
            message: err.status + ':' + err.statusText
          }
        })
        console.error('cart :: item failed to add: %j', {
          ...err.config,
          status: 'error',
          message: `${err.status}(${err.xhrStatus}): ${err.statusText}`
        })
      })
    $ctrl.requestSending = false
    $ctrl.requestFinished = true
    $ctrl.barcodeReady = false

    removeBarcodeReadListener()
    $scope.$evalAsync()
  }

  $ctrl.incQty = () => {
    $ctrl.qty = Number($ctrl.qty) + 1
  }

  $ctrl.decQty = () => {
    $ctrl.qty -= 1
    if ($ctrl.qty < 1) {
      $ctrl.qty = 1
    }
  }
}
