import { get, each, bind, filter, sortBy, uniqBy, isString, find, chain, includes, isArray, size } from 'lodash'

/* @ngInject */
export default function ProductDetailsService(
  $sce,
  $translate,
  ProductGroupService,
  ConfigProvider,
  DataProvider,
  groupSelectorService,
  productDetailsTranslateService
) {
  return function($stateParams, product) {
    return buildProductDetails($stateParams, product)

    /**
     * Self constructor, build up process
     */
    function buildProductDetails($stateParams, product) {
      console.groupCollapsed('%c ProductDetailsService :: initializing ', 'background: #000; color: orange;')

      let data = {}
      // Init empty local data structure
      data.product = product
      data.filterConfig = ConfigProvider.getProductGroupFilterConfig($stateParams.groupId)
      data.featureVideoConfig = ConfigProvider.getFeatureVideoConfig()
      data.productFilterIconOrder = get(data, 'filterConfig.filter_list_for_product_description')
      // For icon filter features
      data.productFilterFeaturesConfig = DataProvider.getFilters()
      data.productFilterFeatures = buildProductFilterFeatures(data)
      // For detailed text view
      data.featureGroupsConfig = ProductGroupService.getCategoryFeatureGroupsById(product.category_id)
      if (!data.featureGroupsConfig) {
        data.featureGroupsConfig = ProductGroupService.getDefaultFeatureGroupCategory()
      }
      data.featuresConfig = DataProvider.getProductFeaturesConfig()
      data.featureGroups = buildFeatureGroups(data)
      console.groupEnd()
      return data
    }

    /**
     * Builds up the product filter features array, flattens the groups, discard some filters and orders the remaining•
     */
    function buildProductFilterFeatures(data) {
      let productId = null
      let filterFeatures = []
      let preparedProductFilterIconOrder = []
      productId = data.product.id
      each(data.productFilterFeaturesConfig, bind(recursiveFinder, { outerKey: null }))

      preparedProductFilterIconOrder = getPreparedProductFilterIconOrder(filterFeatures)

      // Filter if it can be shown
      filterFeatures = filter(filterFeatures, filterFeaturesByFeatureKey)
      // Sort by order
      filterFeatures = sortBy(filterFeatures, sortFeatures)

      // Because of possible duplicated filters
      filterFeatures = uniqBy(filterFeatures, 'value')

      return filterFeatures

      function recursiveFinder(filter, key) {
        if (angular.isArray(filter)) {
          if (filter.indexOf(productId) > -1) {
            filterFeatures.push(new ProductFeatureIcon(data, this.outerKey ? this.outerKey : key, key))
          }
        } else {
          each(filter, bind(recursiveFinder, { outerKey: key }))
        }
      }

      function filterFeaturesByFeatureKey(element) {
        return preparedProductFilterIconOrder.indexOf(element.value) >= 0
      }

      function sortFeatures(element) {
        return preparedProductFilterIconOrder.indexOf(element.value)
      }

      function getPreparedProductFilterIconOrder(filterFeatures) {
        let orderConfig = []
        each(data.productFilterIconOrder, function(filterConfig) {
          if (isString(filterConfig)) orderConfig.push(filterConfig)

          if (isArray(filterConfig)) {
            let matching = chain(filterConfig)
              .filter(filterKey => find(filterFeatures, filter => filter.key === filterKey))
              .first()
              .value()
            orderConfig.push(matching)
          }
        })
        return orderConfig
      }
    }

    /**
     * If the product on scope is represented iterate over the schema config (feature groups and features inside)
     * and build the data up upon it.
     * Warning: all string/value is translated during this function.
     */
    function buildFeatureGroups(data) {
      let _featureGroups = []
      // If the scope is lost erase data
      // Iterate over the feature groups from config, their order contains the schema
      each(data.featureGroupsConfig.feature_groups, function(groupSchema) {
        console.log('buildFeatureGroups', groupSchema)
        let _features = []
        // Iterate over the features in this feature group by the schema
        each(groupSchema.features, function(groupSchemaFeature) {
          // Find the feature from the current product's feature list
          let _foundFeature = find(data.product.features, function(prodFeature) {
            return prodFeature.id === groupSchemaFeature
          })
          // If we found we will add it
          if (_foundFeature) {
            // Find meta data from the features config
            let _foundFeatureMetaData = find(data.featuresConfig, function(pimFeature) {
              return pimFeature.id === groupSchemaFeature
            })
            // Build the ProductFeature with meta fallbacks and add it
            let name = productDetailsTranslateService.getTranslation('feature', _foundFeature.id) || _foundFeature.id
            let value =
              productDetailsTranslateService.getTranslation('feature_value', _foundFeature.value) || _foundFeature.value
            // NOTE: handle multidropdown types
            let translatedValues = []
            if (get(_foundFeatureMetaData, 'value_type') === 'multi_dropdown') {
              each(_foundFeature.value.split(', '), function(val) {
                translatedValues.push(productDetailsTranslateService.getTranslation('feature_value', val) || val)
              })
              value = translatedValues.join(', ')
            }
            let unit = null
            let type = null
            if (_foundFeatureMetaData) {
              type = _foundFeatureMetaData.value_type
              unit =
                productDetailsTranslateService.getTranslation('unit', _foundFeatureMetaData.unit_id) ||
                _foundFeatureMetaData.unit_id
            }
            _features.push(new ProductFeature(name, value, unit, type))
          }
        })
        // Create the new ProductFeatureGroup and push it
        let featureGroupName =
          productDetailsTranslateService.getTranslation('feature_group', groupSchema.id) || groupSchema.id
        _featureGroups.push(new ProductFeatureGroup(featureGroupName, _features))
      })

      return _featureGroups
    }

    // ~~~~~~~~~~~~~~
    // ProductFeature subclass
    // ~~~~~~~~~~~~~~
    function ProductFeature(name, value, unit, type) {
      this.name = name
      this.value = value
      this.unit = unit
      this.type = type
    }

    // ~~~~~~~~~~~~~~
    // ProductFeatureGroup subclass
    // ~~~~~~~~~~~~~~
    function ProductFeatureGroup(name, features) {
      this.name = name
      this.features = features
      this.isEmpty = isEmpty

      function isEmpty() {
        return size(features) < 1
      }
    }

    // ~~~~~~~~~~~~~~
    // ProductFeatureIcon subclass
    // ~~~~~~~~~~~~~~
    function ProductFeatureIcon(data, key, value) {
      this.key = key
      this.value = value
      // Attach the mapping
      this.mapping = null
      if (
        data.filterConfig.filter_video_icon_mapping &&
        data.filterConfig.filter_video_icon_mapping.hasOwnProperty(value)
      ) {
        this.mapping = data.filterConfig.filter_video_icon_mapping[value]
      }
      this.hasFeatureVideo = hasFeatureVideo
      this.getFeatureVideoUrls = getFeatureVideoUrls
      this.getUsageInfo = getUsageInfo

      function hasFeatureVideo() {
        return this.mapping && this.mapping.feature_video && this.mapping.feature_video !== ''
      }

      function getUsageInfo() {
        return { id: this.key }
      }

      function getFeatureVideoUrls() {
        let url = null
        let fallbackUrl = null
        let cf = data.featureVideoConfig
        if (cf) {
          let currentLang = $translate.use().toLowerCase()
          let fallbackLang = cf.fallback_lang
          let videoLang = includes(cf.langs, currentLang) ? currentLang : fallbackLang
          url =
            ConfigProvider.SETTINGS.API.baseApiUrl +
            cf.url_base +
            cf.design +
            '/' +
            videoLang +
            '/' +
            videoLang +
            '_' +
            this.mapping.feature_video +
            '.' +
            cf.file_extension

          // generate fallback video url in case the video for selected language does not exists
          // NOTE: the video for fallback lang must exist! (no check for that)
          fallbackUrl =
            ConfigProvider.SETTINGS.API.baseApiUrl +
            cf.url_base +
            cf.design +
            '/' +
            fallbackLang +
            '/' +
            fallbackLang +
            '_' +
            this.mapping.feature_video +
            '.' +
            cf.file_extension
        }

        return {
          url: $sce.trustAsResourceUrl(url),
          fallbackUrl: $sce.trustAsResourceUrl(fallbackUrl)
        }
      }
    }
  }
}
