import cartModalTpl from './cart-modal/cart.modal.html'
import cartModalCtrl from './cart-modal/cart.modal.controller'

/* @ngInject */
export default function cartController($modal) {
  const $ctrl = this

  $ctrl.open = () => {
    $modal({
      locals: {
        item: $ctrl.item
      },
      backdrop: 'static',
      keyboard: false,
      controller: cartModalCtrl,
      controllerAs: '$ctrl',
      template: cartModalTpl
    })
  }
}
