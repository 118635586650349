/* @ngInject */
export default function detailsPageBootstrap($stateProvider, MPA_PAGES) {
  $stateProvider.state(MPA_PAGES.DETAILS.name, {
    url: MPA_PAGES.DETAILS.url,
    abstract: true,
    component: 'detailsPageComponent',
    resolve: {
      /* @ngInject */
      itemGroup: ($stateParams, ItemGroupListService) =>
        ItemGroupListService.getItemGroupById($stateParams.itemGroupId),
      /* @ngInject */
      allowances: (itemGroup, ComponentMatrixProvider) => {
        // disable media extension if there is no video asset
        const mediaExtension =
          ComponentMatrixProvider.isComponentAllowedOriginally('mediaExtension') &&
          !!itemGroup.getProduct().getTopVideoUrl()

        ComponentMatrixProvider.setComponentAllowance('mediaExtension', mediaExtension)
      },
      /* @ngInject */
      interaction: (itemGroup, businessLogService) =>
        businessLogService.generate({
          event: businessLogService.events.DETAILS_PAGE,
          data: {
            product: itemGroup.getUsageInfo()
          }
        })
    }
  })
}
