import { get, sortBy } from 'lodash'

/* @ngInject */
export default function combinationListController(combinationComparerService) {
  let ctrl = this

  ctrl.$onInit = () => {
    ctrl.combinations = sortBy(ctrl.combinations, combi => combi.getPrice(), combi => get(combi, 'tariff.monthly_fee'))
  }

  const selectCombination = combination => {
    ctrl.onSelect({ combination })
  }

  const isCombinationSelected = combination => combinationComparerService.isSelected(combination)

  ctrl.controlFunctions = {
    selectCombination,
    isCombinationSelected
  }
}
