/* @ngInject */
export default function imageCacheService(ImgCache, $q, $timeout, $http, PreLoaderService) {
  let fileCacheSupported = false
  selfInitService()

  return {
    cacheImages: cacheImagesSelector
  }

  function cacheImagesSelector(imgUrls, timeoutMS) {
    return ImgCache.$promise.then(
      // Success
      function() {
        if (fileCacheSupported) {
          return cacheImages(imgUrls, timeoutMS)
        } else {
          return oldPreLoader(imgUrls)
        }
      },
      // Fail
      function() {
        return oldPreLoader(imgUrls)
      }
    )
  }

  function oldPreLoader(imgUrls) {
    let deferred = $q.defer()
    PreLoaderService.preloadImages(imgUrls).then(handlePreLoadResolve, handlePreLoadReject, handlePreLoadNotify)

    function handlePreLoadResolve(imageLocations) {
      deferred.resolve()
    }

    function handlePreLoadReject(imageLocation) {
      console.warn('imageCacheService :: oldPreLoader failed %s', imageLocation)
      deferred.resolve()
    }

    function handlePreLoadNotify(event) {}

    return deferred.promise
  }

  /**
   * Put all missing images to cache.
   * @param imgUrls array
   */
  function cacheImages(imgUrls, timeoutMS) {
    timeoutMS = timeoutMS || 10000
    let deferred = $q.defer()
    let checkAll = imgUrls.length
    let checkReady = 0
    let notCachedImages = []

    let t = $timeout(function() {
      deferred.resolve()
    }, timeoutMS)

    function checkerReady(success) {
      if (++checkReady >= checkAll) {
        populateImageCache(notCachedImages).then(function() {
          // Resolve it only when it is not already resolved
          if (!deferred.promise.$$state.status) {
            $timeout.cancel(t)
            deferred.resolve()
          }
        })
      }
    }

    if (checkAll === 0) {
      $timeout.cancel(t)
      deferred.resolve()
    } else {
      ImgCache.$promise.then(function() {
        imgUrls.forEach(function(img) {
          // Check if the image is cached or not
          ImgCache.isCached(
            img,
            // Success
            function(path, success) {
              if (success) {
                checkerReady(true)
              } else {
                notCachedImages.push(img)
                checkerReady(true)
              }
            },
            // Fail
            function() {
              checkerReady(false)
            }
          )
        })
      })
    }
    return deferred.promise
  }

  function populateImageCache(missingImages) {
    let deferred = $q.defer()
    let all = missingImages.length
    let ready = 0

    function localReady(success) {
      if (++ready >= all) {
        console.info('imageCacheService :: image cache population finished with %s images', all)
        deferred.resolve()
      }
    }

    if (missingImages.length === 0) {
      deferred.resolve()
    } else {
      missingImages.forEach(function(img) {
        ImgCache.cacheFile(
          img,
          // Success
          function(path) {
            localReady(true)
          },
          // Fail
          function() {
            localReady(false)
          }
        )
      })
    }
    return deferred.promise
  }

  function selfInitService() {
    // Set storage
    window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem

    // Check for support.
    if (window.requestFileSystem) {
      ImgCache.$init()
      ImgCache.$promise.then(
        // Success
        function() {
          fileCacheSupported = true
          console.info(
            'imageCacheService :: ImgCache library supported and started. The current cache size is %s MB, the quota is %s MB',
            ImgCache.getCurrentSize() / (1024 * 1024),
            ImgCache.options.chromeQuota / (1024 * 1024)
          )
        },
        // Fail
        function(e) {
          fileCacheSupported = false
          console.warn(
            'imageCacheService :: HTML5 FileSystem supported, but ImgCache library not started (for example because of private session). %s',
            e
          )
        }
      )
    } else {
      // FileSystem Not Supported
      fileCacheSupported = false
      console.warn(
        'imageCacheService :: ImgCache library could not start, HTML5 FileSystem API is unsupported on this device!'
      )
    }
  }
}
