import { difference, each, extend, filter, forEach, intersection, isEmpty, size, union } from 'lodash'

import popoverTemplate from './templates/product-filter-popover.template.html'
import dynamicPopoverTemplate from './templates/product-filter-dynamic-popup.template.html'
import dynamicTextPopoverTemplate from './templates/product-filter-dynamic-text-popup.template.html'

/* @ngInject */
export default function productFilterController(
  $scope,
  $timeout,
  $rootScope,
  EVENT_KEYS,
  businessLogService,
  ProductFilterService,
  DataProvider,
  ConfigProvider,
  $window,
  displayService
) {
  const ctrl = this
  ctrl.$onInit = initializeComponent

  $scope.$on(EVENT_KEYS.LOGIC.GLOBAL_RESET, initializeComponent)
  $scope.$on(EVENT_KEYS.LOGIC.FILTER_RESET, resetLifestyleFilters)
  $scope.$on(EVENT_KEYS.LOGIC.FILTER_SELECT, resetLifestyleFilters)

  function resetLifestyleFilters() {
    ctrl.data.activeLifestyleIcon = null
  }

  function resetProductFilters() {
    $rootScope.$broadcast(EVENT_KEYS.LOGIC.FILTER_RESET)
    ProductFilterService.resetActiveFilters()
    resetPriceFilter()

    businessLogService.generate({ event: businessLogService.events.FILTER_RESET })

    broadcastFilterListChangeEvent()
  }

  function resetPriceFilter() {
    ctrl.data.rangeSelectors.map(selector => selector.reset())
    broadcastFilterListChangeEvent()
  }

  function resetFilterGroup(e, filterGroup) {
    e.stopPropagation()
    filterGroup.reset()
    broadcastFilterListChangeEvent()
  }

  function resetFeatureNamedGroups(e, filters) {
    e.stopPropagation()
    each(filters, function(filter) {
      filter.reset()
    })
    broadcastFilterListChangeEvent()
  }

  function resetFeatureGroups(e) {
    e.stopPropagation()
    ProductFilterService.resetFeatureGroups()
    broadcastFilterListChangeEvent()
  }

  function getActiveFilterNumber() {
    return size(filter(ctrl.data.filters, { active: true }))
  }

  function getActiveFeatureGroupFilterNumber() {
    return size(filter(ctrl.data.filters, { active: true, type: 'feature' }))
  }

  function getActiveFeatureNamedGroupFilterNumber(filters) {
    return size(filter(filters, { active: true, type: 'feature' }))
  }

  function toggleFeature(feature) {
    feature.toggle()
    $rootScope.$broadcast(EVENT_KEYS.LOGIC.FILTER_SELECT, feature)
    businessLogService.generate({
      event: businessLogService.events.FILTER_SELECT,
      info: feature.getUsageInfo()
    })

    broadcastFilterListChangeEvent()
  }

  function handleFilterGroupTrigger(group) {
    businessLogService.generate({
      event: businessLogService.events.FILTER_GRP_SELECTION,
      info: group.getUsageInfo()
    })
  }

  function loadStaticImage(image) {
    return require('./images/' + image)
  }

  /**
   * Check whatever the given filter item has any product attached to it if it is activated
   * @param item
   * @returns {boolean}
   */
  function hasPortBoundProduct(item) {
    const showOnlyEffectiveFilters = ConfigProvider.getFrontendSettingByKey('SHOW_ONLY_EFFECTIVE_FILTERS')
    const hasIntersection = intersection(ctrl.data.portProducts, item.productIdList).length > 0
    const effective = !isEmpty(difference(ctrl.data.portProducts, item.productIdList)) || !showOnlyEffectiveFilters

    return hasIntersection && effective
  }

  /**
   * Check whatever the given filterGroup has any filter found by hasPortBoundProduct logic
   * @param filterGroup
   * @returns {boolean}
   */
  function productFilterGroupHasPortBoundProduct(filterGroup) {
    let boundProds = false
    forEach(filterGroup.filters, function(filter) {
      if (hasPortBoundProduct(filter)) {
        boundProds = true
      }
    })
    return boundProds
  }

  function activateLifestyle(featureGroup) {
    ProductFilterService.resetActiveFilters()
    ctrl.data.activeLifestyleIcon = featureGroup.displayName

    let filtersToActivate = []

    forEach(featureGroup.filters, function(filter) {
      if (hasPortBoundProduct(filter)) {
        filtersToActivate.push(filter)
      }
    })

    businessLogService.generate({
      event: businessLogService.events.LIFESTYLE_FILTER_SELECT,
      info: featureGroup.getUsageInfo()
    })

    ProductFilterService.activateFilters(filtersToActivate)
    broadcastFilterListChangeEvent()
  }

  // ~~~~~~~~~~~~~~
  // Private functions
  // ~~~~~~~~~~~~~~
  function initializeComponent() {
    console.groupCollapsed('%c productFilterController ', 'background: #000; color: yellow;')
    ctrl.data = getLocalData()
    ctrl.properties = getLocalProperties()
    ctrl.data = extend(ctrl.data, ProductFilterService.getServiceData())
    ctrl.data.featuresWithNoNamedGroups = getFeatureNamedGroupsWithNoName(ctrl.data.featureGroups)
    // By default allow all product
    ctrl.data.portProducts = DataProvider.getActiveProductIds()

    console.info(
      'productFilterController :: Product filters loaded from server data, total number of filters: %s',
      size(ctrl.data.filters)
    )
    console.groupEnd()
  }

  function broadcastFilterListChangeEvent() {
    $rootScope.$broadcast(EVENT_KEYS.LOGIC.ACTIVE_FILTER_LIST_CHANGED)
  }

  function getLocalData() {
    return {
      portProducts: {},
      activeLifestyleIcon: null
    }
  }

  function getFeatureNamedGroupsWithNoName(featureGroups) {
    let filters = []
    featureGroups = filter(featureGroups, { displayName: '' })
    each(featureGroups, function(group) {
      filters = union(filters, group.filters)
    })
    return filters
  }

  function getLocalProperties() {
    // const dynamicFiltersWithText = true
    const dynamicFiltersWithText = ConfigProvider.getFrontendSettingByKey('DYNAMIC_FILTER_TEXT_INSTEAD_OF_IMG')
    const dynamicTemplate = dynamicFiltersWithText ? dynamicTextPopoverTemplate : dynamicPopoverTemplate
    return {
      popoverViewUrl: popoverTemplate,
      dynamicPopoverViewUrl: dynamicTemplate,
      popoverDirection: displayService.isHorizontal() ? 'bottom' : 'top',
      popupDirection: displayService.isHorizontal() ? 'left' : 'top'
    }
  }

  ctrl.controlFunctions = {
    getActiveFilterNumber,
    getActiveFeatureGroupFilterNumber,
    getActiveFeatureNamedGroupFilterNumber,
    toggleFeature,
    activateLifestyle,
    resetFilterGroup,
    resetFeatureNamedGroups,
    resetPriceFilter,
    resetFeatureGroups,
    resetProductFilters,
    hasPortBoundProduct,
    handleFilterGroupTrigger,
    productFilterGroupHasPortBoundProduct,
    loadStaticImage
  }
}
