import { size, map, keyBy, flow, flatten, each } from 'lodash/fp'

/* @ngInject */
export default function ItemGroupListService(
  ItemGroup,
  ArticleListService,
  ProductListService,
  CombinationListService
) {
  let localData

  return {
    init,
    getItemGroupById,
    getItemGroups
  }

  function getItemGroupById(id) {
    return localData.itemGroups[id]
  }

  function getItemGroups() {
    return localData.itemGroups
  }

  function init(itemGroupConfig) {
    console.groupCollapsed('%c ItemGroupListService :: initializing ', 'background: #000; color: orange;')
    console.log('itemGroupConfig', itemGroupConfig)
    localData = {
      itemGroups: {}
    }

    localData.itemGroups = flow(
      map.convert({ cap: false })((config, itemGroupId) => {
        const product = ProductListService.getProductById(config.pim)
        const articles = flow(
          map(an => ArticleListService.getArticleById(an)),
          each(article => article.setItemGroupId(itemGroupId)),
          keyBy('an')
        )(config.articles)
        const combinations = flow(
          map(an => CombinationListService.getCombinationsByAn(an)),
          flatten
        )(config.articles)

        return ItemGroup.build({ itemGroupId, product, articles, combinations })
      }),
      keyBy('id')
    )(itemGroupConfig)

    console.info('ItemGroupListService :: initialized SUCCESSFULLY with %s item groups', size(localData.itemGroups))
    console.log('ItemGroupListService', localData.itemGroups)
    console.groupEnd()
  }
}
