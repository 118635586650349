import template from './breadcrumb.html'
import controller from './breadcrumb.controller'

export default {
  bindings: {
    categories: '<'
  },
  template,
  controller
}
