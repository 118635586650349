import template from './findbox.html'
import controller from './findbox.controller'

export default {
  bindings: {
    item: '<'
  },
  template,
  controller
}
