import template from './group-selector.html'
import controller from './group-selector.controller'

export default {
  bindings: {
    productGroups: '<',
    flow: '<'
  },
  template,
  controller
}
