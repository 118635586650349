import { map, min, max, filter, uniq, size } from 'lodash'

/*
  ItemGroups (IG) are the basic components of the MPA data structure.
  An IG contains all product related data, including:
  - product(Product): the PIM representation of the product. Only one per IG.
  - article(Article): the product from the customer's view point. One or more per IG
  - combinations(Combination): different variations of the articles, e.g with tariffs. One or more.
*/

/* @ngInject */
export default function ItemGroupFactory(ConfigProvider) {
  const localConfig = {
    simLockFreeOperatorImage: `${ConfigProvider.getFrontendSettingByKey('OPERATOR_IMG_DIR')}sim-lock-free.png`
  }

  // ~~~~~~~~~~~~~~
  // Constructor with default object structure
  // ~~~~~~~~~~~~~~
  function ItemGroup({ itemGroupId, product, articles, combinations }) {
    this.id = itemGroupId
    this.product = product
    this.articles = articles
    this.combinations = combinations

    const combiPrices = combinations.map(combi => combi.getPrice())
    const priceVariants = [].concat(combiPrices).filter(price => price !== null)

    this.price = {
      min: min(priceVariants),
      max: max(priceVariants),
      variants: priceVariants
    }
    this.price.isRange = priceVariants.length > 1

    this.operatorImages = getItemGroupOperatorImages(this.articles)
  }

  // generic info for business-log
  ItemGroup.prototype.getUsageInfo = function() {
    // todo: handle multi item group!
    const pa = this.getPrimerArticle()
    return {
      ...(pa ? pa.getUsageInfo() : {}),
      ...this.product.getUsageInfo()
    }
  }

  ItemGroup.prototype.getProduct = function() {
    return this.product
  }

  /*
    When an IG has only one article, this is the primer article.
    This is useful when article-related data should be displayed (e.g. identifiers like an, ean)
  */
  ItemGroup.prototype.getPrimerArticle = function() {
    return Object.keys(this.articles).length === 1 ? this.articles[Object.keys(this.articles)[0]] : null
  }

  /*
    When an IG has only one combination AND it is a primer combination, it is the IG's primer combination
    It is useful when displaying combination-related data, e.g. price
  */
  ItemGroup.prototype.getPrimerCombination = function() {
    return size(this.combinations) === 1 && this.combinations[0].type === 'primer' ? this.combinations[0] : null
  }

  ItemGroup.prototype.getCombinations = function() {
    return this.combinations
  }

  ItemGroup.prototype.getArticles = function() {
    return this.articles
  }

  /**
   * collects the IG related operator images. Handles the case when there is a sim-lock-free option.
   * @param articles
   * @returns {*}
   */
  function getItemGroupOperatorImages(articles) {
    const filteredArticles = filter(articles, article =>
      article.operator === null ? article.sold_alone !== false : true
    )
    let images = map(filteredArticles, article => article.getOperatorImageUrl())

    // when there the only operator is the sim-lock-free, don't show images
    if (images.length === 1 && images[0] === localConfig.simLockFreeOperatorImage) {
      images = []
    }

    images = images.filter(item => ![null].includes(item))
    return uniq(images)
  }

  // ~~~~~~~~~~~~~~
  // Builder
  // ~~~~~~~~~~~~~~
  ItemGroup.build = function({ itemGroupId, product, articles, combinations }) {
    let newItemGroup = null
    if (itemGroupId && product && articles) {
      newItemGroup = new ItemGroup({ itemGroupId, product, articles, combinations })
    } else {
      console.warn('ItemGroup :: could not build item group, missing data for %s', itemGroupId)
    }
    return newItemGroup
  }

  /**
   * Return the constructor function
   */
  return ItemGroup
}
