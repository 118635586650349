import controller from './combination-display.controller'
import template from './combination-display.template.html'

export default {
  bindings: {
    combination: '<',
    showProductPrice: '<',
    selected: '<'
  },
  template,
  controller
}
