import { find } from 'lodash'

/* @ngInject */
export default function accessorySelectorConfig($stateProvider, MPA_PAGES) {
  $stateProvider
    .state(MPA_PAGES.DETAILS_ACCESSORIES_ACCESSORY_SELECTOR.name, {
      url: MPA_PAGES.DETAILS_ACCESSORIES_ACCESSORY_SELECTOR.url,
      abstract: true,
      template: '<ui-view>',
      resolve: {
        /* @ngInject */
        category: (categories, $stateParams) => find(categories, { id: $stateParams.categoryId }),
        /* @ngInject */
        breadcrumb: (breadcrumbService, category) =>
          breadcrumbService.set('category', {
            label: category.label,
            value: category.id
          })
      }
    })
    .state(MPA_PAGES.DETAILS_ACCESSORIES_ACCESSORY_SELECTOR_LIST.name, {
      url: MPA_PAGES.DETAILS_ACCESSORIES_ACCESSORY_SELECTOR_LIST.url,
      // custom props
      component: 'accessorySelector'
    })
}
