/* @ngInject */

export default function detailsSpecsPageBootstrap($stateProvider, MPA_PAGES) {
  $stateProvider.state(MPA_PAGES.DETAILS_SPECS.name, {
    url: MPA_PAGES.DETAILS_SPECS.url,
    // custom props
    title: 'MENU_SPECS',
    weight: 1,
    visible: true,
    componentAllowanceId: 'detailsSpecificationsTab',
    // custom props end
    component: 'detailsSpecsPageComponent',
    resolve: {
      /* @ngInject */
      productDetails: ($stateParams, itemGroup, ProductDetailsService) =>
        ProductDetailsService($stateParams, itemGroup.product),
      /* @ngInject */
      interaction: (businessLogService, itemGroup) =>
        businessLogService.generate({
          event: businessLogService.events.DETAILS_SPECIFICATION,
          data: {
            product: itemGroup.getUsageInfo()
          }
        })
    }
  })
}
