/* @ngInject */
export default function preloadRegistry(preloadRegistryService, ngIfDirective) {
  let ngIf = ngIfDirective[0]
  return {
    priority: ngIf.priority + 1, // Execute it before ng if and component if
    link: function($scope, $element, $attr) {
      let prelaodRegistryName = $attr['preloadRegistry']
      preloadRegistryService.resolveDirectiveLoad(prelaodRegistryName)
    }
  }
}
