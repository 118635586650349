import appControlComponent from './app-control-component/app-control.component'
import audioPlayer from './audio-player-component/audio-player.module'
import cartModule from './cart/cart.module'
import outOfStockIndicatorModule from './out-of-stock-indicator/out-of-stock-indicator.module'
import checkoutButton from './checkout-button/component'
import combinationDisplay from './combination-display/combination-display.component'
import featureFilter from './feature-filter-component/feature-filter.module'
import featureIconList from './feature-icon-list/feature-icon-list.module'
import footerComponent from './footer-component/footer.component'
import headerComponent from './header-component/header.component'
import languageSelector from './language-selector/language-selector.module'
import findbox from './findbox/findbox.component'
import layoutComponent from './layout-component/layout.component'
import loadingBar from './loading-bar/loading-bar.component'
import mediaPlayer from './media-player/media-player.component'
import pickup from './pick-up-notification/pick-up-notification.module'
import preloadregistry from './preload-registry/preload-registry.module'
import priceDisplay from './price-display/price-display.module'
import productDisplayComponent from './product-display/product-display.component'
import productFilter from './product-filter-component/product-filter.module'
import productFilterDescriptionComponent from './product-filter-description-component/product-filter-description.component'
import productSpecificationTableComponent from './product-specification-table/product-specification-table.component'
import qrcodeviewer from './qr-code-viewer/qr-code-viewer.module'
import rangeSlider from './range-slider/range-slider.component'
import socketComm from './socket-comm/socket.comm.module'
import swiper from './swiper/swiper.module'
import userInteraction from './business-log/business-log.module'

const module = angular.module('mpa.components', [
  audioPlayer,
  cartModule,
  outOfStockIndicatorModule,
  featureFilter,
  featureIconList,
  languageSelector,
  pickup,
  preloadregistry,
  priceDisplay,
  productFilter,
  qrcodeviewer,
  socketComm,
  swiper,
  userInteraction
])

module
  .component('appControl', appControlComponent)
  .component('combinationDisplay', combinationDisplay)
  .component('findbox', findbox)
  .component('checkoutButton', checkoutButton)
  .component('mediaPlayer', mediaPlayer)
  .component('footerComponent', footerComponent)
  .component('headerComponent', headerComponent)
  .component('layoutComponent', layoutComponent)
  .component('loadingBar', loadingBar)
  .component('productDisplay', productDisplayComponent)
  .component('productFilterDescription', productFilterDescriptionComponent)
  .component('productSpecificationTable', productSpecificationTableComponent)
  .component('rangeSlider', rangeSlider)

export default module.name
