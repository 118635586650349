import config from './config'
import categoryItem from './category-item/component'

import categorySelector from './component'

const module = angular.module('category-selector', [])

module
  .config(config)
  .component('categorySelector', categorySelector)
  .component('categoryItem', categoryItem)

export default module.name
