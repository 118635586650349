import { get, has, intersection, union, each, countBy, sortBy } from 'lodash'

/* @ngInject */
export default function ProductFilterGroupFactory() {
  // ~~~~~~~~~~~~~~
  // Constructor with default object structure
  // ~~~~~~~~~~~~~~
  function ProductFilterGroup(config, filters) {
    this.displayName = config.display_name || config.name || config.group_label || ''
    this.filters = filters
    this.groupOperator = config.group_operator || 'AND'
    let productIdList = get(filters, '[0].productIdList', [])
    let unionProductIdList = []

    if (has(filters, '[0].productIdList')) {
      _(filters)
        .map('productIdList')
        .each(function(list) {
          productIdList = intersection(productIdList, list)
          unionProductIdList = union(unionProductIdList, list)
        })
    }

    this.productIdList = productIdList
    this.unionProductIdList = unionProductIdList
  }

  ProductFilterGroup.prototype.reset = function() {
    each(this.filters, function(filter) {
      filter.reset()
    })
  }

  ProductFilterGroup.prototype.getUsageInfo = function() {
    return {
      name: this.displayName
    }
  }

  ProductFilterGroup.prototype.getActiveFilterNumber = function() {
    return countBy(this.filters, function(filter) {
      return filter.active ? 'actives' : 'inactives'
    }).actives
  }

  ProductFilterGroup.prototype.orderFiltersByIdArray = function(filterIdOrderArray) {
    this.filters = sortBy(this.filters, function(filter) {
      return filterIdOrderArray.indexOf(filter.id)
    })
  }

  // ~~~~~~~~~~~~~~
  // Builder
  // ~~~~~~~~~~~~~~
  ProductFilterGroup.build = function(config, filters) {
    let newProductFilterGroup = null
    if (filters && angular.isArray(filters)) {
      newProductFilterGroup = new ProductFilterGroup(config, filters)
    }
    return newProductFilterGroup
  }

  // Return the constructor function
  return ProductFilterGroup
}
