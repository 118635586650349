import { flow, orderBy, filter, map, includes } from 'lodash/fp'

/*
  ItemGroupFilteredListService maintains the currently filtered IGs
*/

/* @ngInject */
export default function ItemGroupFilteredListService(ItemGroupListService, $q, $rootScope, EVENT_KEYS) {
  $rootScope.$on(EVENT_KEYS.LOGIC.FILTERED_PRODUCT_LIST_CHANGED, filteredProductListChangeHandler)

  let localData

  const init = () => {
    localData = {
      filteredItemGroups: []
    }
  }

  const getServiceData = () => localData

  return {
    init,
    getServiceData
  }

  function filteredProductListChangeHandler(event, productIds) {
    localData.filteredItemGroups = flow(
      filter(itemGroup => includes(itemGroup.product.id)(productIds)),
      orderBy('product.manufacturer')('asc')
    )(ItemGroupListService.getItemGroups())

    const lastFilteredItemGroupIds = map('id')(localData.filteredItemGroups)
    $rootScope.$broadcast(EVENT_KEYS.LOGIC.FILTERED_ITEM_GROUP_LIST_CHANGED, lastFilteredItemGroupIds)
  }
}
