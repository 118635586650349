import template from './template.html'
import controller from './controller'

export default {
  bindings: {
    itemGroup: '<',
    combination: '<'
  },
  template,
  controller
}
