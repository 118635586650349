import template from './media-player.html'
import controller from './media-player.controller'

export default {
  bindings: {
    videoUrl: '<'
  },
  controller,
  template
}
