import constant from './audio-player.constant'
import component from './audio-player.component'
import audioService from './audio-player.service'

const module = angular.module('mpa.components.audio-player', [])

module
  .constant('CONFIG', constant)
  .service('audioService', audioService)
  .component('audioPlayer', component)

export default module.name
