import component from './group-selector.component'
import config from './group-selector.config'
import groupSelectorService from './group-selector.service'
import groupItem from './components/group-item/component'

const module = angular.module('mpa.pages.group-selector', [])

module
  .config(config)
  .component('groupSelectorPageComponent', component)
  .component('groupItem', groupItem)
  .factory('groupSelectorService', groupSelectorService)

export default module.name
