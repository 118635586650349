/* @ngInject */
export default function checkoutButtonController(navigationService, combinationComparerService, businessLogService) {
  const ctrl = this

  ctrl.handleCheckout = () => {
    ctrl.combination ? combinationComparerService.select(ctrl.combination) : combinationComparerService.select(null)
    businessLogService.generate({
      event: businessLogService.events.CHECKOUT,
      data: ctrl.combination.getUsageInfo()
    })

    navigationService.goToCheckoutPage(ctrl.itemGroup.id)
  }
}
