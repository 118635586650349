import ItemGroup from './item-group.factory'
import ItemGroupFilteredListService from './item-group-filtered-list.service'
import ItemGroupListService from './item-group-list.service'

const module = angular.module('mpa.repositories.item-group', [])

module
  .factory('ItemGroup', ItemGroup)
  .service('ItemGroupFilteredListService', ItemGroupFilteredListService)
  .service('ItemGroupListService', ItemGroupListService)

export default module.name
