import { pickBy } from 'lodash'
import { isPlainObject, isArray, map, isNumber, isString } from 'lodash/fp'
import EVENTS from './events'
import { role } from '../../commons/services/identity'

const cmap = map.convert({ cap: false })

/* @ngInject */
export default function businessLogService(ConfigProvider, groupSelectorService, $state, socketChannelLogService) {
  let commonLogData = {}

  const init = () => {
    const data = ConfigProvider.getLogData()

    // all log lines will contain this data. todo: add facerec data
    commonLogData = {
      session_id: null,
      role,
      ...data
    }
  }

  const send = (data, name) => {
    const jsonData = JSON.stringify(data)
    const eventData = {
      name,
      message: `${jsonData}`
    }

    console.groupCollapsed(
      `%c businessLogService :: send: ${data.event || data.info} `,
      'background: #f4f4f4; color: green;'
    )

    socketChannelLogService.emit('business', eventData)

    console.log('data:', eventData)
    console.groupEnd()
  }

  const generateScreenInteraction = (event, info) => {
    generate({ event, info })
  }

  const isArrayOfStrings = array =>
    isArray(array) ? array.filter(item => isString(item)).length === array.length : false

  /**
   * info values should be stored under one of the following keys:
   - nvalue for numbers
   - svalue for strings
   - ovalue for objects
   * @param value
   * @returns {{nvalue: null, svalue: null, ovalue: null}}
   */
  const determinateInfo = value => ({
    nvalue: isNumber(value) ? value : null,
    svalue: isString(value) || isArrayOfStrings(value) ? value : null,
    ovalue: isPlainObject(value) ? value : null
  })

  /**
   * generates the info structure
   * @param value
   * @param key
   * @returns {*}
   */
  const generateInfo = (value, key) => {
    return pickBy(
      {
        key,
        ...determinateInfo(value)
      },
      a => a !== null
    )
  }

  const generateDynamicData = () => {
    let dynamicData = {}
    const groupId = groupSelectorService.getActiveProductGroup()
    if (groupId) {
      dynamicData.pg = groupId
      dynamicData.page = $state.$current.name
    }

    return dynamicData
  }

  /**
   * Generates the user interaction event log line
   * @param event: object: user-interaction meta object from events.js
   * @param data: object: keys will be merged
   * @param info: object: represented as key:value pairs in logs
   * @param cntbl: whether or not add cntlb flag
   */
  const generate = ({ event, data = {}, info, cntbl = true }) => {
    if (event && event.name) {
      const dynamicData = generateDynamicData() // can be different from line to line

      let events = []

      if (info) {
        events = cmap((value, key) => {
          const info = generateInfo(value, key)
          return {
            event: event.name,
            ...commonLogData,
            ...data,
            ...dynamicData,
            ...info
          }
        })(info)
      } else {
        events = [
          {
            event: event.name,
            ...commonLogData,
            ...data,
            ...dynamicData
          }
        ]
      }

      // add cntbl flag to the first line
      if (events.length && cntbl) {
        events[0].cntbl = true
      }

      events.map(event => send(event, 'mpa-application-business'))
    } else {
      console.warn(`businessLogService::generate: no proper event: %j`, event)
    }
  }

  const setSessionId = id => (commonLogData.session_id = id)

  return {
    init,
    screen: generateScreenInteraction,
    generate,
    setSessionId,
    events: EVENTS
  }
}
