import acClickOutside from './ac-click-outside'
import componentIf from './component-if.directive'
import componentSelection from './component-selection.directive'
import disableContextmenu from './disable-contextmenu.directive'
import inputHelper from './input-helper.directive'

const module = angular.module('mpa.commons.directives', [])

module
  .directive('acClickOutside', acClickOutside)
  .directive('componentIf', componentIf)
  .directive('componentSelection', componentSelection)
  .directive('disableContextmenu', disableContextmenu)
  .directive('inputHelper', inputHelper)

export default module.name
