import { isObject } from 'lodash'
/* @ngInject */
export default function audioPlayerController($scope, CONFIG, audioService, businessLogService) {
  let ctrl = this
  let playerStates = CONFIG.PLAYER_STATES
  let lastPlayed = null

  ctrl.initialized = false

  ctrl.$onInit = function audioPlayerCompInit() {
    audioService
      .initialize()
      .then(function() {
        console.debug('audioPlayerController :: initializing ')
        ctrl.initialized = true
        ctrl.data = initData()
        $scope.$on('on-audio-player-changed', function(e, data) {
          ctrl.data.state = data && data.state ? data.state : ''
          $scope.$evalAsync()
        })

        ctrl.data.isLineIn = audioService.isLineIn()
        ctrl.data.playlist = audioService.getPlaylist()
        ctrl.data.selected = ctrl.data.playlist[0]
        ctrl.data.volCtrlOpened = false
        ctrl.data.playlistOpened = false

        stop()
        audioService.setVolume(CONFIG.DEFAULT_VOL)
        // audioService.requestPlayState()
      })
      .catch(console.error.bind(console))
  }

  ctrl.controlFunctions = {
    play,
    playPause,
    stop,
    setVolume,
    isSelected,
    toggleVolCtrl,
    togglePlayList,
    closePlayList,
    closeVolCtrl
  }

  function initData() {
    return {
      state: CONFIG.DEFAULT_PLAYER_STATE,
      isPlaying: false,
      isLineIn: false,
      playlist: [],
      selected: null,
      volume: CONFIG.DEFAULT_VOL,
      source: CONFIG.DEFAULT_SOURCE,
      sliderOps: {
        vertical: true,
        floor: CONFIG.VOLUME_MIN,
        ceil: CONFIG.VOLUME_MAX,
        hideLimitLabels: true,
        hidePointerLabels: true,
        onChange: setVolume
      }
    }
  }

  function play(selectedAudio) {
    console.debug('audioPlayerController :: play', selectedAudio)
    audioService.play(isObject(selectedAudio) ? selectedAudio.id : selectedAudio)
    if (isObject(selectedAudio)) {
      ctrl.data.selected = selectedAudio
    }
    ctrl.data.isPlaying = true
    ctrl.data.isLineIn = selectedAudio === CONFIG.PLAYER_SOURCES.lineIn
    ctrl.data.source =
      selectedAudio === CONFIG.PLAYER_SOURCES.lineIn ? CONFIG.PLAYER_SOURCES.lineIn : CONFIG.PLAYER_SOURCES.file

    const nowPlayed = ctrl.data.isLineIn ? CONFIG.PLAYER_SOURCES.lineIn : selectedAudio.id

    if (lastPlayed !== nowPlayed) {
      let info = { source: ctrl.data.source }
      if (isObject(selectedAudio)) {
        info = { ...info, song: `${selectedAudio.artist} - ${selectedAudio.title}` }
      }
      businessLogService.generate({
        event: businessLogService.events.AUDIO_PLAY,
        info
      })
    }

    lastPlayed = nowPlayed
  }

  function playPause(selectedAudio) {
    console.debug('audioPlayerController :: playPause')

    if (ctrl.data.state === playerStates.playing && !ctrl.data.isLineIn) {
      console.debug('audio player: pause')
      audioService.pause()
      ctrl.data.isPlaying = false
    } else if (ctrl.data.state === playerStates.paused) {
      audioService.resume()
      ctrl.data.isPlaying = true
    } else {
      play(selectedAudio)
    }
  }

  function stop() {
    console.debug('audioPlayerController :: stop')
    audioService.stop()
    ctrl.data.isPlaying = false
    ctrl.data.isLineIn = false
  }

  function setVolume(sliderId, volume) {
    console.debug('audioPlayerController :: setVolume')
    audioService.setVolume(volume)
  }

  function isSelected(audio) {
    return ctrl.data.selected && ctrl.data.selected.id === audio.id
  }

  function toggleVolCtrl() {
    businessLogService.generate({
      event: businessLogService.events.AUDIO_TOGGLE_VOLUME_CONTROL,
      info: { action: ctrl.data.volCtrlOpened ? 'close' : 'open' }
    })

    ctrl.data.volCtrlOpened = !ctrl.data.volCtrlOpened
  }

  function closeVolCtrl() {
    ctrl.data.volCtrlOpened = false
  }

  function togglePlayList() {
    console.log('togglePlayList')
    businessLogService.generate({
      event: businessLogService.events.AUDIO_TOGGLE_PLAYLIST,
      info: { action: ctrl.data.playlistOpened ? 'close' : 'open' }
    })

    ctrl.data.playlistOpened = !ctrl.data.playlistOpened
  }

  function closePlayList() {
    console.log('closePlayList')
    ctrl.data.playlistOpened = false
  }
}
