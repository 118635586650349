import { get, map, zipObject } from 'lodash'

import popoverTemplate from './templates/language-selector-popover.template.html'
import FLAG_OVERRIDES from './language-selector.constants'
/* @ngInject */
export default function languageSelectorController(businessLogService, ConfigProvider, $translate, EVENT_KEYS, $state) {
  let ctrl = this

  ctrl.controlFunctions = {
    languageSelected
  }

  ctrl.$onInit = initComponent

  function initComponent() {
    console.groupCollapsed('%c languageSelectorController', 'background: #000; color: yellow;')
    ctrl.state = {
      selectedLanguage: {},
      flagConfig: getFlagConfig()
    }

    if ($translate.use()) {
      ctrl.state.selectedLanguage = getLanguageByLangCode($translate.use().toLowerCase())
    }

    ctrl.properties = {
      popoverViewUrl: popoverTemplate
    }

    console.info(
      'languageSelectorController :: ready: selectedLanguage: %j, flagConfig: %j',
      ctrl.state.selectedLanguage,
      ctrl.state.flagConfig
    )
    console.groupEnd()
  }

  function languageSelected(language) {
    if (language !== ctrl.state.selectedLanguage.lang) {
      ctrl.state.selectedLanguage = getLanguageByLangCode(language)
      $translate.use(language.toUpperCase())

      businessLogService.generate({
        event: businessLogService.events.LANGUAGE_CHANGE,
        info: { lang: language }
      })

      $state.transitionTo($state.current, $state.params, {
        reload: $state.current,
        inherit: false,
        notify: true
      })
    }
  }

  /**
   * generates the flag config. If there is a flag override for a language, the override value will be used.
   Overrides come from the node_settings
   */
  function getFlagConfig() {
    let nodeSettings = ConfigProvider.getNodeSettings()
    let languages = nodeSettings
      ? get(nodeSettings, 'available_languages', '')
          .join(',')
          .toLowerCase()
          .split(',')
      : []
    let country = nodeSettings ? get(nodeSettings, 'country', '').toLowerCase() : []
    return zipObject(
      languages,
      map(languages, languageCode => {
        let flagConfig = {
          lang: languageCode,
          flag: languageCode
        }
        if (FLAG_OVERRIDES) {
          if (get(FLAG_OVERRIDES, [languageCode, '*'])) {
            flagConfig.flag = get(FLAG_OVERRIDES, [languageCode, '*'])
          }
          if (get(FLAG_OVERRIDES, [languageCode, country])) {
            flagConfig.flag = get(FLAG_OVERRIDES, [languageCode, country])
          }
        }
        return flagConfig
      })
    )
  }

  function getLanguageByLangCode(countryCode) {
    let language = {}

    if (ctrl.state.flagConfig.hasOwnProperty(countryCode)) {
      language = ctrl.state.flagConfig[countryCode]
    } else {
      console.error('languageSelectorController :: getLanguageByLangCode: no language found: ', countryCode)
    }

    return language
  }
}
