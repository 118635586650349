import { socketChannel as logSocketChannel } from '../../components/socket-comm/channels/socket.channel.log'

let debug = true
const levels = ['debug', 'info', 'warn', 'error']

levels.forEach(level => {
  const original = console[level]
  console[level] = (...args) => {
    args[0] = args[0].toString()
    const message = args.shift().replace(/%([jsd])/g, x => {
      let replacement = ''
      if (x === '%j') {
        try {
          replacement = JSON.stringify(args.shift())
        } catch (e) {
          replacement += ' JSON.stringify error'
        }
      } else {
        replacement = args.shift()
      }
      return replacement
    })
    if (level !== 'debug' || (level === 'debug' && debug)) {
      original.call(console, message)
      logSocketChannel.emit('remote', { level, message })
    }
  }
})

window.onerror = (...args) => {
  logSocketChannel.emit('remote', {
    level: 'error',
    message: `Window.onerror :: [${JSON.stringify(args)}]`
  })
}

export default isDebugEnabled => {
  debug = isDebugEnabled
}
