/* @ngInject */
export default function faceRecService($rootScope, EVENT_KEYS) {
  let self = this

  function initialize() {
    $rootScope.$on(EVENT_KEYS.LOGIC.FACE_RECOGNITION_NEW_FACE, onNewFaceHandler)
    $rootScope.$on(EVENT_KEYS.LOGIC.FACE_RECOGNITION_LEAVE_FACE, onLeaveFaceHandler)
  }

  initialize()

  /**
   * Event handlers
   * @param event - event object
   * @param obj - sent object
   */
  function onNewFaceHandler(event, obj) {
    if (!obj) {
      return
    }

    putItem(obj)
  }

  function onLeaveFaceHandler(event, obj) {
    invalidateItem(obj)
  }

  /**
   * Updates the public current item
   * @param item - broadcasted raw object
   */
  function putItem(item) {
    self.current.isActive = true
    self.current.item = item.data
    self.current.createdOn = item.created
  }

  /**
   * Invalidates the public current item
   * Isn't removes the last recognized face item
   */
  function invalidateItem() {
    self.current.isActive = false
  }

  // ----------
  // Public API
  // ----------
  /**
   * Current recognized face item
   * isActive - If true the face is front of the cameraclient
   * createdOn - New face event fired
   * item: - Last recognized face item
   */
  self.current = {
    isActive: false,
    createdOn: null,
    item: null
  }

  return self
}
